import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { filter } from 'lodash-es';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AuthService } from 'src/app/core/auth.service';
import { TokenStorage } from 'src/app/core/token-storage';
import { Pagination } from 'src/app/models/pagination';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { ScreenAccessService } from 'src/app/service/screen-access.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { DateFilterPipe } from '../pipes/dateFormat.pipe';
import { AlertDialogComponent } from '../Shared/alert-dialog/alert-dialog.component';
import { YesNoAlertDialogComponent } from '../Shared/yes-no-alert-dialog/yes-no-alert-dialog.component';
import { TransactionDetailComponent } from '../transaction-detail/transaction-detail.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  groupsList: Array<any> = new Array<any>();
  currentPage: number = 1;
  row_count: number = 0;
  page_count: number = 10;
  totalCount: number=0;
  resultPerpage=new FormControl(10);
  DashBoardFilter!: FormGroup;
  transactions : Array<any> = new Array<any>();
  transactionTemp: Array<any> = new Array<any>();
  categories: Array<any> = [];
  Status:Array<any> =[];
  changeText!: boolean;
  businessUnits: Array<any> = [];
  configActivityList: Array<any> = [];
  // @ViewChild('blPagination', { static: true })paginationComp!: PaginationComponent;
  //pagination: Pagination = new Pagination(); 
 // @ViewChild('appPagination', { static: false }) appPagination!: PaginationComponent;
  pageNo!:number
  public settings:IDropdownSettings= {};
  constructor(
    private restService: RestService,
    public MsalauthService: MsalService,
    public dialog: MatDialog, 
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    public authService: AuthService,
    private token: TokenStorage,
    private router: Router,
    public screenAccessService: ScreenAccessService,
    private datePipe: DateFilterPipe) { }

  ngOnInit(): void {    
    this.DashBoardFilter = this.formBuilder.group({
      searchInput: [''],
      fromDate: [''],
      toDate: [''],
      StatusIDFilter: [''],
      buFilter: [''],
      categoryFilter: [''],
      userId: [''],
      buFilterToGetGroups: [''],
      groups: [''],
    });
   // setInterval(() => {
      //this.getConfigList();
      this.getTransactions();
      this.getAllBusinessUnits();
      //this.getTransactions();
      this.categories = [
        {category: 'All', categoryID: 0},
        {category: 'CAPEX', categoryID: 1},
        {category: 'OPEX', categoryID: 2},
        {category: 'Non Financial', categoryID:3},
        {category:  'Other Financial', categoryID:4}
      ]
      
      this.Status =[
        {status: 'All',statusID:0},
         {status: 'Awaiting Approval',statusID:1001},
         {status: 'Approved',statusID:1002},
         {status: 'Rejected',statusID:1003},
         {status: 'On Hold',statusID:1004},
         {status: 'Sent Back',statusID:1005},
         {status: 'Cancelled',statusID:1006},
         {status: 'Not Applicable',statusID:1007},
         {status: 'Sent Back To Initiator',statusID:1008}
        
      ]
          // this.DashBoardFilter.valueChanges.subscribe(data=>{
      //   this.row_count= 0;
      //   this.currentPage=1;
      //   this.getTransactions();
      // });

      this.DashBoardFilter.get("buFilterToGetGroups")?.valueChanges.subscribe(value=>{
        this.DashBoardFilter.get('groups')?.patchValue('');
        this.getApprovalGroups(value);
      })
      this.resultPerpage.valueChanges.subscribe(value=>{
        this.row_count= 0;
        this.page_count=parseFloat(this.resultPerpage.value);
        this.currentPage=1;
        this.getTransactions();
      });
      this.settings = {
        singleSelection: false,
        idField: 'id',
        textField: 'fullName',
        enableCheckAll: false,
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        allowSearchFilter: true,
        limitSelection: -1,
        clearSearchFilter: true,
        maxHeight: 197,
        // itemsShowLimit: 3,
        searchPlaceholderText: 'Search',
        noDataAvailablePlaceholderText: 'Select Business Unit',
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
        defaultOpen: false
      };
    //  }, 5000);
  }

  // OnPageChange(event: any) {
  //   this.getTransactions();
    
  // }


  //Method to get config list
  getConfigList() {
      let configparams={
        SearchText: '',
        PageNo:0,
        PageSize:1000
      }
      this.restService.postData(configparams ,'/api/ActivityConfig/ActivityConfigFetchList')
      .subscribe(
        (data) => {
          if(data.rcode==1) {
            if(data.rObj.ActivityConfig!=null){
              this.configActivityList=data.rObj.ActivityConfig
            }
           } 
        },
        (err) => {
          console.log(err);
          if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
            localStorage.clear();
            this.router.navigate([''])
            .then(() => {
              window.location.reload();
            });
          }
        }
      );
      this.getTransactions();
    }

  //Method to get list of transactions
  getTransactions() {
    let newResult:any = [];
    //let rowRange = this.paginationComp.GetRowRange();
    let endDate = new Date(this.DashBoardFilter.get("toDate")?.value);
    if(this.DashBoardFilter.get("toDate")?.value != '') {
      let ed : Date= new Date(this.DashBoardFilter.get("toDate")?.value);
      endDate.setDate(ed.getDate() + 1)
    }
    //let categoryId = this.categoryFilter == 'CAPEX' ? 1 : this.categoryFilter == 'OPEX' ? 2 : this.categoryFilter == 'Non Financial' ? 3 : 0;
    // if(this.searchInput.trim()!=''){
    //   rowRange.firstResult=0;
    //  }

    let params =  { 
      SearchText: this.DashBoardFilter.get("searchInput")?.value?this.DashBoardFilter.get("searchInput")?.value.trim():"",
      BusinessUnit: this.DashBoardFilter.get("buFilter")?.value?this.DashBoardFilter.get("buFilter")?.value:"",
      CategoryID: this.DashBoardFilter.get("categoryFilter")?.value ? parseFloat(this.DashBoardFilter.get("categoryFilter")?.value):0,
      StatusID:this.DashBoardFilter.get("StatusIDFilter")?.value ? parseInt(this.DashBoardFilter.get("StatusIDFilter")?.value):0,
      StartTime: this.DashBoardFilter.get("fromDate")?.value ?this.datePipe.transform(this.DashBoardFilter.get("fromDate")?.value,"yyyy-MM-dd'T'HH:mm:ss'.135'"):"",
      EndTime: this.DashBoardFilter.get("toDate")?.value == "" && this.DashBoardFilter.get("fromDate")?.value  == "" ? '' :  this.DashBoardFilter.get("toDate")?.value == "" && this.DashBoardFilter.get("fromDate")?.value != ""? this.datePipe.transform(new Date(),"yyyy-MM-dd'T'HH:mm:ss'.135'") : this.datePipe.transform(endDate,"yyyy-MM-dd'T'HH:mm:ss'.135'"),
      GroupIds: this.DashBoardFilter.get('groups')?.value?this.DashBoardFilter.get('groups')?.value.map((u:any) =>"'"+ u.id+"'").join(', '):"",
      UserId: this.DashBoardFilter.get('userId')?.value?this.DashBoardFilter.get('userId')?.value:"",
      PageNo:this.row_count,
      PageSize:this.page_count,
    };
    this.loader.show();
    this.restService.postData(params, '/api/Transaction/AdminDashBoard').subscribe((res) => {
      if(res.rcode == 1) {
        this.totalCount=res.rObj.Count.Count;
        this.transactions=[]
        this.transactionTemp=[]
        if(res.rObj.Transaction!=null){
          this.transactions = res.rObj.Transaction;
          this.transactionTemp = res.rObj.Transaction;
          
        }
        // if(res.rObj.Count!=undefined && res.rObj.Count!=null){
        //   this.showContainerSearchResultTab(res.rObj.Count.Count);

        // }
      } else {
        console.log(res);
      }
      this.loader.hide();

    }, (err) => {
      this.loader.hide();
      console.log(err)
      if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
        localStorage.clear();
        this.router.navigate([''])
        .then(() => {
          window.location.reload();
        });
      }
    });
    /* setTimeout(() => {
      this.transactions.forEach(transaction => {
        let id = this.configActivityList.find(item => item.id == transaction.activityConfigID);
        //console.log(id)
        if(id) {
          transaction.workflowTitle = id.Title
          newResult.push(transaction);
        }
      });
     // console.log(newResult)
    }, 2000); */
    //this.transactions = newResult;
    //this.transactionTemp = newResult;
  }

  //Method to set from date and call API
  // onFromDate(event: any) {
  //   this.fromDate = event;
  //   this.getTransactions();
  // }

  //Method to set to date and call API
  // onToDate(event: any) {
  //   this.toDate = event;
  //   this.getTransactions();
  // }

  //Method to get all business units
  getAllBusinessUnits() {
    this.authService.getBusinessUnits()
      .subscribe((data: any) => {
        let _temp=[];
        for(var j=0; j<data.value.length;j++){
          if(data.value[j].displayName!='BU-L1-JCG'){
            _temp.push(data.value[j]);
          }
        }
        this.businessUnits=_temp
      },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
            localStorage.clear();
            this.router.navigate([''])
            .then(() => {
              window.location.reload();
            });
          }
        })
  }

  //Method to call the API for every pagination change
// async showContainerSearchResultTab(data:any){
//       if(this.searchInput.trim()!=''){
//         this.paginationComp.SetPage(1)
//         // let rowRange = this.paginationComp.GetRowRange();
//         // rowRange.firstResult=0;
//        }
//       this.paginationComp.SetTotalRecords(data);
//       this.paginationComp.CalculatePaginationPages();
     
     
    
//   }

  //Method to open transaction details pop-up to view transaction details
  viewDetails(transaction: any) : void {
    let data = {
      transaction : transaction
    }
    const dialogRef = this.dialog.open(TransactionDetailComponent, {
      width: '60vw',
      height: '70vh',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe();
  }

  //Method to delete transaction
  openDocument(transaction:any): void {
    if(transaction.DocumentURL != null) {
      window.open(transaction.DocumentURL,'_blank');
    }
  }

 /*  //Method to filter table by business units
  filterData() {
    this.transactions = this.transactionTemp;
    if(this.categoryFilter !='') {
      this.transactions = this.transactions.filter(item => item.category == this.categoryFilter);
    } else {
      this.transactions = this.transactionTemp;
    }
    if(this.buFilter != '') {
      this.transactions = this.transactions.filter(item => item.businessUnit.toLowerCase().includes(this.buFilter.toLowerCase()));
    }
    if(this.fromDate != '') {
      console.log(this.fromDate)
      let filterFrom = new Date(this.fromDate);
      console.log(filterFrom)
    }
  } */

  
  //Method to clear the filters
  clearFilter() {
    this.DashBoardFilter.patchValue({
      searchInput: "",
      fromDate: "",
      toDate: "",
      StatusIDFilter:"",
      buFilter:"",
      categoryFilter:"",
      userId:"",
      buFilterToGetGroups:"",
      groups:"",
    });
    this.page_count=10;
    this.resultPerpage.patchValue(10);
  }

  //Method to export the data to excel
  exportToExcel(){
    let endDate = new Date(this.DashBoardFilter.get("toDate")?.value);
    if(this.DashBoardFilter.get("toDate")?.value!= '') {
      let ed : Date= new Date(this.DashBoardFilter.get("toDate")?.value);
      endDate.setDate(ed.getDate() + 1)
    }
    let params =  { 
      SearchText: this.DashBoardFilter.get("searchInput")?.value?this.DashBoardFilter.get("searchInput")?.value.trim():"",
      BusinessUnit: this.DashBoardFilter.get("buFilter")?.value?this.DashBoardFilter.get("buFilter")?.value:"",
      CategoryID: this.DashBoardFilter.get("categoryFilter")?.value ? parseFloat(this.DashBoardFilter.get("categoryFilter")?.value):0,
      StatusID:this.DashBoardFilter.get("StatusIDFilter")?.value ? parseInt(this.DashBoardFilter.get("StatusIDFilter")?.value):0,
      StartTime: this.DashBoardFilter.get("fromDate")?.value ?this.datePipe.transform(this.DashBoardFilter.get("fromDate")?.value,"yyyy-MM-dd'T'HH:mm:ss'.135'"):"",
      EndTime: this.DashBoardFilter.get("toDate")?.value == "" && this.DashBoardFilter.get("fromDate")?.value  == "" ? '' :  this.DashBoardFilter.get("toDate")?.value == "" && this.DashBoardFilter.get("fromDate")?.value != ""? this.datePipe.transform(new Date(),"yyyy-MM-dd'T'HH:mm:ss'.135'") : this.datePipe.transform(endDate,"yyyy-MM-dd'T'HH:mm:ss'.135'"),
      PageNo:this.row_count,
      PageSize:this.page_count,
    };
    this.loader.show();
    this.restService.postData(params, '/api/Transaction/AdminDashBoardExport').subscribe((res) => {
      if(res.rcode == 1) {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          // width: '30vw',
          // height: '25vh',
          disableClose: true,
          data:{data: "Data is being exported and will be sent as an email" ,  type:"SUCCESS"}
        });
        dialogRef.afterClosed().subscribe();
      } else {
        console.log(res);
      }
      this.loader.hide();

    }, (err) => {
      this.loader.hide();
      console.log(err)
    });
  }

 
  //GENERATE PDF
    //Method to get config list
    generatePDF(id:string) {
      let configparams={
        TransactionID: id,
       
      }
      this.restService.postData(configparams ,'/api/Transaction/TransactionPDF')
      .subscribe(
        (data) => {
          if(data.rcode==1) {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              disableClose: true,
              data:{data: "PDF is generating, please wait for 15 mins to download" ,  type:"SUCCESS"}
            });
            dialogRef.afterClosed().subscribe();
           } 
        },
        (err) => {
          console.log(err);
          if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
            localStorage.clear();
            this.router.navigate([''])
            .then(() => {
              window.location.reload();
            });
          }
        }
      );
     
    }
    onPageChange(page: number) {
      this.row_count = this.page_count * (page - 1);
      this.getTransactions();
      window.scrollTo(0, 0);
    }
    dashboardFilter(){
      this.row_count= 0;
      this.currentPage=1;
      this.getTransactions();
    }

  getApprovalGroups(buId: string) {
    this.groupsList=[];
    this.authService.getApprovalGroups(buId)
      .subscribe((data: any) => {
        let approvalGroupsList=data?.value;
          approvalGroupsList=filter(approvalGroupsList, ["@odata.type", "#microsoft.graph.group"]);
          approvalGroupsList=approvalGroupsList.map((group:any)=>{
            if (group.description != null) {
              group.fullName = group.displayName + '-' + group.description
          } else {
            group.fullName = group.displayName
          }
          return group;
          })
          this.groupsList=[...approvalGroupsList];
      },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.status == 401) {
            const headers = new HttpHeaders({
              'Authorization': 'Bearer ' + this.token.MSALget()
            });
            this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
              this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
              this.getApprovalGroups(buId);
            }, (errVal) => {
              console.log(err);
              if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
              }
            });
          }
        })
    this.authService.getApprovalParentGroup(buId).subscribe((data: any) => {
      let approvalGroupsList=data?.value;
      approvalGroupsList=filter(approvalGroupsList, ["@odata.type", "#microsoft.graph.group"]);
      approvalGroupsList=approvalGroupsList.map((group:any)=>{
        if (group.description != null) {
          group.fullName = group.displayName + '-' + group.description
      } else {
        group.fullName = group.displayName
      }
      return group;
      })
      this.groupsList=[...approvalGroupsList];
    },
      (err) => {
        this.loader.hide();
        console.log(err);
        if (err.status == 401) {
          const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.token.MSALget()
          });
          this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
            this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
            this.getApprovalGroups(buId);
          }, (errVal) => {
            console.log(err);
            if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
              localStorage.clear();
              this.router.navigate([''])
                .then(() => {
                  window.location.reload();
                });
            }
          });
        }
      })
      
  }

  cancelTransaction(transactionId:any) {
    const dialogRef = this.dialog.open(YesNoAlertDialogComponent, {
      data: `Are you sure you need to cancel Transaction ${transactionId}?`,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let params =  { 
          "TransactionID": transactionId
        };
        this.loader.show();
        this.restService.postData(params, '/api/Transaction/TransactionCancelByAdmin').subscribe((res) => {
          this.loader.hide();
          if(res.rcode == 1) {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              disableClose: true,
              data:{data: res.rmsg ,  type:"SUCCESS"} 
            });
            dialogRef.afterClosed().subscribe(result=>{
              if(result){
                this.getTransactions();
              }
            });
          } else {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              disableClose: true,
              data:{data: res.rmsg ,  type:"WARNING"} 
            });
            dialogRef.afterClosed().subscribe(result=>{
            });
          }
        }, (err) => {
          this.loader.hide();
          console.log(err);
          if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
            localStorage.clear();
            this.router.navigate([''])
            .then(() => {
              window.location.reload();
            });
          }
        })
      }
    });

  }
}
