export class RowRange{

    firstResult: number;
    maxResult: number;
    pageIndex:number
  
    constructor(firstResult = 0, maxResult = 30,pageIndex=1){
      //this['@class'] = '../../model/RowRange';
      this.firstResult = firstResult;
      this.maxResult = maxResult;
      this.pageIndex=pageIndex
    }
  }
  