import { Injectable, Inject } from '@angular/core';

const TOKEN_KEY = 'JanusAuthToken';
//let myItem = localStorage.getItem('msal.idtoken');
const MSAL_TOKEN_KEY = 'JanusAuthToken';
const APP_TOKEN = 'JANUS-AuthToken';
//console.log(MSAL_TOKEN_KEY)
@Injectable()
export class TokenStorage {

  constructor() { }

  save(token:string) {
    window.localStorage[TOKEN_KEY] = token;
  }

  saveAppToken(token:string) {
    window.localStorage[APP_TOKEN] = token;
  }

  MSALget() {
      return window.localStorage[MSAL_TOKEN_KEY];
  }

  MSALAppget() {
    return window.localStorage[APP_TOKEN];
  }

  destroy() {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem('JanusAuthToken');
    window.localStorage.removeItem(APP_TOKEN);
    //window.localStorage.clear();
  }

}
