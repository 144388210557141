
<section>
    <h2 mat-dialog-title class="m-0 px-3 py-2">Action</h2>
    <mat-dialog-content class="mat-typography">
     <section class="p-4 delegateUser-body-content">
         <form [formGroup]="delegateUserForm">
    
          <div class="form-check mb-3" *ngIf="isLogInUserExistIndelegateUsers">
            <input class="form-check-input" type="radio" formControlName="actionBy" id="mySlef" value="1">
            <label class="form-check-label" for="mySlef">
              Action by <span class="orange">{{logInUser}}</span> 
            </label>
          </div>
          <div class="form-check mb-3" *ngIf="isLogInUserExistIndelegateUsers">
            <input class="form-check-input" type="radio" formControlName="actionBy" id="fromDelegateUsers" value="2">
            <label class="form-check-label" for="fromDelegateUsers">
              Would you like to choose from those who have authorised you?
            </label>
          </div>
    
            <div class="form-group row" *ngIf="!isLogInUserExistIndelegateUsers || delegateUserForm.get('actionBy')?.value=='2'">
                <label for="delegateUser" class="col-sm-5 col-form-label">Select user who authorized you:<span class="small text-danger">*</span></label>
                <div class="col-sm-7">
                    <select class="form-control" formControlName="delegateUser" id="delegateUser">
                        <option value="">Select the user</option>
                        <option *ngFor="let user of delegateUsers" [value]="user">{{user}}</option>
                      </select>
                      <div *ngIf="submitted && delegateUserForm.get('delegateUser')?.errors?.required" class="small text-danger">User is required</div>
                </div>
          
              </div>
         </form>
         <div class="text-right">
          <button mat-button mat-dialog-close class="btn btn-Action1 mr-2">Cancel</button>
          <button mat-button class="btn btn-Action" (click)="submit()">Submit</button>
         </div>
       
     </section>
    </mat-dialog-content>
  </section>