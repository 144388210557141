<!-- <div class="col-sm-12 col-md-12  content">
    <div class="col-sm-12 col-md-10 mt-3  content-align"> -->
<div class="card ">
    <div class="card-header display-flex">
        <div class=" col-md-12">
            <h2 class="m-0 text-left ">Delegate <span class=" pointer close-align " (click)="closeDialog()"><i
                        class="fa fa-times  text-right " aria-hidden="true"></i></span></h2>
        </div>
    </div>

    <div class="card-body">
        <form [formGroup]="scheduleForm">
            <div class="text-center">
                Delegate Off
                <mat-slide-toggle color="primary" formControlName="checkbox">
                    Delegate On
                  </mat-slide-toggle>
            </div>
            <br />
            <!-- <div class="toggleLable">

                <div class=" col-sm-12 col-md-10  custom-control custom-switch ">
                    <label class="assigneelable">Delegate Off</label>
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="checkbox">
                    <label class="custom-control-label" for="customSwitch1"></label>
                    <label class="assigneelableon">Delegate On</label>
                </div>
            </div> -->
           
            <div *ngIf="f.checkbox.value">
                <!-- <div  formArrayName="items" *ngFor="let item of scheduleForm.get('items').controls; let i = index">  -->
                <div formArrayName="items" *ngFor="let level of approvalLevelForm(scheduleForm); let i = index">
                    <div [formGroupName]="i" class="col-sm-12 col-md-12 mt-3 row">
                        <label class="col-sm-12 col-md-4 eaddress">Assignee's Email Address {{i+1}}
                        </label>

                        <div class="col-sm-12 col-md-6 form-group">
                            <div class="dsp-flx ">
                                <input type="text" class="form-control" name="mail" formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && level.controls.email.errors }">

                                <button *ngIf="i>0" class="fa fa-times remove" style="font-size: 20px;"
                                    title="Remove Email {{i+1}}" (click)="removeApprovalLevel(i)"></button>

                            </div>
                            <div *ngIf="submitted && level.controls.email.errors"
                                class="text-danger errorMsg"
                                [ngClass]="{ 'is-invalid': submitted && level.controls.email.errors }">
                                <div *ngIf="level.controls.email.errors.required"> Assignee's Email Address {{i+1}} is required</div>
                                <div *ngIf="level.controls.email.errors.email">Please enter valid email address</div>
                                <!-- <div *ngIf="level.controls.email.errors.pattern">Please enter with valid pattern</div> -->

                            </div>
                            <div class="text-danger errorMsg" *ngIf="level.controls.email.errors?.emailRequired">Assignee's Email Address {{i+1}} is required</div>


                        </div>
                    </div>
                </div>

            </div>
            <div class="add-align" *ngIf="f.checkbox.value">
                <button class="addEmail" (click)="addEmails()">add</button>
            </div>


            <!-- <div class="col-sm-12 col-md-12 mt-3 row">
                        <div class="col-sm-12 col-md-2 " >
                        <label class="">Schedule From</label>
                    </div>
                        <div class="col-sm-12 col-md-10 form-group ">
                       
                            <input matInput [matDatepicker]="picker" class="form-control" formControlName="schedulefrom" [ngClass]="{ 'is-invalid': submitted && f.schedulefrom.errors }">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker ></mat-datepicker>
                          
                       
                        <div *ngIf="submitted && f.schedulefrom.errors" class="invalid-feedback text-danger errorMsg"
                        [ngClass]="{ 'is-invalid': submitted && f.schedulefrom.errors }">
                        <div *ngIf="f.schedulefrom.errors.required"> schedule from date is required</div>
                    </div>
                    </div> 
                    </div>  -->
            <!-- <div class="delegateuntil" *ngIf="f.checkbox.value"> 
                    <div  class="col-sm-12 col-md-12 mt-3 row" >
                        <label class="col-sm-12 col-md-4 eaddress">Delegate until</label>
                        
                        <div class="col-sm-12 col-md-8 form-group display-inline ">
                             
                                <input type="text" class="form-control"  [matDatepicker]="picker" [min]="min" placeholder="Choose a date" readonly
                                    formControlName="scheduleto" data-date-format='YYYY-MM-DD'>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                        
                        <div *ngIf="submitted && f.scheduleto.errors" class="invalid-feedback text-danger errorMsg"
                        [ngClass]="{ 'is-invalid': submitted && f.scheduleto.errors }">
                        <div *ngIf="f.scheduleto.errors.required"> schedule to date is required</div>
                    </div>
                    </div> 
                    </div> 
                </div>

                 <div class="message" *ngIf="f.checkbox.value">
                    <div  class="col-sm-12 col-md-12 mt-3 row" >
                        
                        <label class="col-sm-12 col-md-4 eaddress">Message</label>
                        
                        <div class="col-sm-12 col-md-8 form-group">
                            <input type="text" class="form-control" name="message" formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                        
                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback text-danger errorMsg"
                        [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                        <div *ngIf="f.message.errors.required"> message is required</div>
                    </div>
                    </div> 
                    </div> 
                </div> -->

            <div class="mx-auto col-md-3 mt-3 row">

                <div class="col-md-6"  *ngIf="f.checkbox.value">
                    <button class="btn btn-Action" (click)="reset()">Cancel</button>
                  
                </div>
                <div class="col-md-6">
                    
                    <button class="btn btn-Action" (click)="OnSubmit()">Save</button>
                </div>
            </div>

        </form>
    </div>

</div>
<!-- </div>
</div> -->