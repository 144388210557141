import {Injectable} from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse, HttpUserEvent, HttpEvent, HttpErrorResponse, HttpClient
} from '@angular/common/http';
import {TokenStorage} from './token-storage';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

const TOKEN_HEADER_KEY = 'X-AUTH-TOKEN';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private token: TokenStorage, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable< HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    return next.handle(req).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.callBackObj != null) {
            let token = event.body.callBackObj.token;
            if (token) {
              if(!window.localStorage["JanusAuthToken"]){
               // window.localStorage.clear();  
                localStorage.clear();

              }
              this.token.save(token);
             
            }
          }
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log(err);
          if (
            !req.url.endsWith('/') && (err.status === 401 || err.status === 403 || err.status === 0)) {
             /*  localStorage.clear();
              this.router.navigate([''])
              .then(() => {
                window.location.reload();
              }); */
              
          }
        }
      }
    ));
  }

}
