//const  tenentID='195fa309-c578-46d5-82c4-177036ce5560';
const  tenentID='046e8e41-64c2-4cfa-a654-2d7179a40f20';

export const environment = {
  production: true,
  //baseApiUrl: 'https://janusworkflowapi.azurewebsites.net/',
  baseApiUrl: 'https://jcg-api-appservice.azurewebsites.net/',
  customUrl: 'https://bcg-api.swiftant.com/api/',
  //clientId:'2f2e9157-150f-4534-b42c-caceecf50c9d',
  clientId:'e06ee7d1-89ba-4650-b0f6-3ff155dd6f92',
  //refreshSecretKey:'~ZehN~hqrsR.cxjW--3j.r9sR9_K3pTTTF',
  refreshSecretKey:'6Pp8Q~cRgQXrOnOBPcGnBYShDUzVcrpsDgB.acwP',
  getAccessTokeUrl:'https://login.microsoftonline.com/'+`${tenentID}`+'/oauth2/v2.0/token',
  //loginRedirect:'https://janusworkflowweb.azurewebsites.net/my-approvals'
  //loginRedirect:'https://jcg-web-app.azurewebsites.net/my-approvals'
  loginRedirect:'https://bongo.januscontinental.com/my-approvals'

};
