<nav aria-label="Page navigation ">
  <div class="disabled text-right paginationMargin">
    <!-- <span class="results text-light h6">Showing results {{(pagination.totalRecords > 0)?pagination.rowRange.firstResult+1:0}} - {{pagination.totalRecords > (pagination.rowRange.firstResult + pagination.rowRange.maxResult)?(pagination.rowRange.firstResult + pagination.rowRange.maxResult):pagination.totalRecords}} of {{pagination.totalRecords}}</span> -->
  </div>  
    <ul class="pagination pagination-sm">
      <li [ngClass]="pagination.currentPage == 1?'disabled':''">
        <a aria-label="Previous" (click)="pagination.currentPage == 1?'':SetPage(pagination.currentPage-1)">
          <span aria-hidden="true" class="previousAlign">&laquo;</span>
        </a>
      </li>
      <li *ngFor="let page of pagination.paginationPageIndexes" class="pointer"
          [ngClass]="(page+1) == pagination.currentPage?'active':''"><a class="presentPageAlign"
        [hidden]="page != 0 && page != pagination.totalPage-1 && !(page >= pagination.currentPage - pagination.maxPageVisible && page <= pagination.currentPage + pagination.maxPageVisible)"
        (click)="(page+1) == pagination.currentPage?return():SetPage(page+1)">{{page+1}}</a>
      </li>

      <li [ngClass]="pagination.currentPage == pagination.totalPage?'disabled':''">
        <a aria-label="Next"
           (click)="pagination.currentPage == pagination.totalPage?'':SetPage(pagination.currentPage+1)">
          <span aria-hidden="true" class="forwardAlign">&raquo;</span>
        </a>
      </li>
       <li class="disabled">
        <span class="forwardAlign">Results per page</span>
      </li> 
      <li class="">
        <span  class="paginationDropDown">
          <select class="" (change)="PageSizeChanged($event)">
            <option [attr.selected]="pagination.rowRange.maxResult == 10?true:null" value="10">10</option>
            <option [attr.selected]="pagination.rowRange.maxResult == 50?true:null" value="50">50</option>
            <option [attr.selected]="pagination.rowRange.maxResult == 100?true:null" value="100">100</option>
            <option [attr.selected]="pagination.rowRange.maxResult == 150?true:null" value="150">150</option>
            <option [attr.selected]="pagination.rowRange.maxResult == 200?true:null" value="200">200</option>
          </select>
        </span>
      </li>
    </ul>
  </nav>
  