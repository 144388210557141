import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestService } from 'src/app/service/rest.service';
import { AlertDialogComponent } from '../component/Shared/alert-dialog/alert-dialog.component';
import { LoaderService } from '../service/loader.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  scheduleForm!: FormGroup;
  submitted: boolean = false;
  checkbox!: boolean;
  groups = new FormControl();
  emailValidator: boolean = false;
  myApprovalList: Array<any> = new Array<any>();
  min = new Date();
  existingDelegateUser: any;
isEmailAdd:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<ScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, 
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private restservice: RestService, private loader :LoaderService) { }

  ngOnInit(): void {
    this.scheduleForm = this.fb.group({

      //  email:['',[Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      email: [''],
      checkbox: [''],
      scheduleto: ['',],
      message: [''],
      items: this.formBuilder.array([this.createItem()])

    });
    this.submitted = false;
    this.fetchUserDeligation()
  }

  get f() {
    return this.scheduleForm.controls;
  }

  OnSubmit() {

    this.submitted = true

    if (!this.f.checkbox.value) {

      (<FormArray>this.scheduleForm.get('items')).clear();
      //this.createItem();
      this.existingDelegateUser.forEach((element: any) => {
        (<FormArray>this.scheduleForm.get('items')).push(this.fb.group({
          email: element,
        }));
      });



    }
    if (!this.scheduleForm.invalid) {
      for (let i = 0; i < this.scheduleForm.value.items.length; i++) {
        this.myApprovalList.push(this.scheduleForm.value.items[i].email)
      }
      this.setUser()
    }


    if (this.scheduleForm.invalid) {
      return;
    }
    this.closeDialog()

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],

    })
  }

  get items() {
    return this.scheduleForm.get('items') as FormArray;
  }

  approvalLevelForm(form: any) {
    return form.controls.items.controls;
  }

  addEmails() {
    //  let current = this.scheduleForm.get('items') as FormArray;
    //  //current.controls[index].get('upperLimit')?.value;
    //  current.value.forEach((element: any) => {
    //    if(element.email==''){

    //    }
    //    else{
    //     (this.scheduleForm.get('items') as FormArray).push(
    //       this.createItem());
    //   }
    //  });

    // this.submitted = true;
    // if (this.scheduleForm.invalid) {
    //   return;
    // }
    // else {
      this.isEmailAdd=false
     const arr=<FormArray>this.scheduleForm.get('items') as FormArray;

arr.controls.forEach((element: any) => {
  if(element.controls.email.value==''){
    element.controls.email.setErrors({emailRequired:true});
    this.isEmailAdd=true
  }
});
if(!this.isEmailAdd){
  (<FormArray>this.scheduleForm.controls['items']).get('email')?.setErrors(null);
  (this.scheduleForm.get('items') as FormArray).push(
    this.createItem());
}

    //}


  }

  removeApprovalLevel(id: number) {
    const control = (<FormArray>this.scheduleForm.controls['items'])
    control.removeAt(id);
  }


  setUser() {
this.loader.show()
    let params = {
      "IsDelegationOn": this.f.checkbox.value,
      "DelegateUsers": this.myApprovalList,
    }
    this.restservice.postData(params, '/api/User/SetUserDelegation')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '32vw',
              height: '20vh',
              disableClose: true,
              data:{data:data.rmsg,  type:"SUCCESS"}
            });
          
           // dialogRef.afterClosed().subscribe();
            this.dialogRef.close();
          }
        this.loader.hide();
        })
    if (this.submitted = true) {
      this.checkbox = true
    }
  }

  fetchUserDeligation() {
    this.loader.show();
    let params = {
      "IsDelegationOn": false,

      "DelegateUsers": this.myApprovalList,
    }
    this.restservice.postData(params, '/api/User/FetchUserDelegation')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            if(data.rObj.user!=null){
            this.f.checkbox.setValue(data.rObj.user.IsDelegationOn)
            this.existingDelegateUser = data.rObj.user.DelegateUsers;
            if (data.rObj.user.IsDelegationOn) {
              (<FormArray>this.scheduleForm.get('items')).clear();
              this.existingDelegateUser.forEach((element: any) => {
                (<FormArray>this.scheduleForm.get('items')).push(this.fb.group({
                  email: element,

                }));
              })

            }
            }
       
          }
          this.loader.hide();
        })
  }
  reset(){
    //(<FormArray>this.scheduleForm.get('items')).clear();
    this.fetchUserDeligation()
  }


}

