


<div class="login-body" *ngIf="loginDisplay==false">

    <div class="container">
    
        <div class="d-flex justify-content-center h-100">
    <div class="login-wrapper">
        <div class="text-center mb-3">
    
        </div>
            <div class="card">
                <div class="card-header text-center">
                    <img src="assets/images/logo3.png">

                   
                </div>
                <div class="card-body">
                    <div class="col-md-12 text-center">
                        <button class="btn float-right login_btn" (click)="login()"> Login with Microsoft Account</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    
    </div>

