import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/auth.service';
import { TokenStorage } from 'src/app/core/token-storage';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { AwitingApprovalDialogComponent } from '../Shared/awiting-approval-dialog/awiting-approval-dialog.component';
import * as microsoftTeams from '@microsoft/teams-js';
import { some, sortBy } from 'lodash-es';
import { ScreenAccessService } from 'src/app/service/screen-access.service';
@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit, AfterViewInit {
  @ViewChild('blPagination', { static: true }) paginationComp!: PaginationComponent;
  myApprovalList: Array<any> = new Array<any>();
  myApprovalHistoryList: Array<any> = new Array<any>();
  configActivityList: Array<any> = new Array<any>();
  searchInput: string = '';
  groupIds: [] = [];
  sGroupids: string = '';
  ApproveId = 1002;
  RejecteId = 1003;
  OnHoldId = 1004;
  changeText!: boolean;
  SentBackToPreviousLevelID = 1005;
  isCurrentLevelSendback: boolean = false;
  constructor(
    private restservice: RestService,
    private loader: LoaderService,
    private token: TokenStorage,
    private router: Router,
    private dialog: MatDialog,
    public screenAccessService: ScreenAccessService,
    private MsalauthService: MsalService,
    public authservice: AuthService) { }

  ngOnInit(): void {
    // setInterval(() => {
    // this.getConfigList();
    microsoftTeams.initialize();
    this.getMembers();
    // this.getAllApprovals();
    // }, 5000);
  }

  ngAfterViewInit() {
    // console.log('Initializing ms teams');

    // microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
    //   microsoftTeams.settings.setSettings({
    //     entityId: '',
    //     contentUrl: 'https://jcg-web-app.azurewebsites.net/',
    //     suggestedDisplayName: '',
    //     websiteUrl: 'https://jcg-web-app.azurewebsites.net/',
    //   });
    //   saveEvent.notifySuccess();
    // });

    // console.log('Register on save');
    // microsoftTeams.settings.setValidityState(true);
  }

  //Method to get config list
  getConfigList() {
    let configparams = {
      SearchText: '',
      PageNo: 0,
      PageSize: 1000
    }
    this.restservice.postData(configparams, '/api/ActivityConfig/ActivityConfigFetchList')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            if (data.rObj.ActivityConfig != null) {
              this.configActivityList = data.rObj.ActivityConfig
            }
          }
        },
        (err) => {
          console.log(err);
          if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        }
      );
    // this.getTransactions();
  }

  // OnPageChange(event: any) {
  //   this.getAllApprovals();
  // }


  getMembers() {
    this.authservice.getMyGroups()
      .subscribe((data: any) => {
        this.groupIds = [];
        this.groupIds = data.value;
        this.getAllApprovals()
      },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.Status == 401) {
            const headers = new HttpHeaders({
              'Authorization': 'Bearer ' + this.token.MSALget()
            });
            this.restservice.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
              this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
              this.authservice.getMyGroups()
                .subscribe((data: any) => {
                  this.groupIds = [];
                  this.groupIds = data.value;
                  this.getAllApprovals()
                })
            }, (errVal) => {
              console.log(errVal);
              if (errVal.Status === 401 || errVal.Status === 403 || errVal.Status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
              }
            });
          }
        })
  }

  getAllApprovals() {
    this.loader.show();
    this.sGroupids = ""
    this.groupIds.forEach((item: any) => {
      if (item.securityEnabled) {
        this.sGroupids = this.sGroupids + "'" + item.id + "',"
      }
    });
    this.sGroupids = this.sGroupids.substr(0, this.sGroupids.length - 1);

    //  let rowRange = this.paginationComp.GetRowRange();
    let params = {
      SearchText: '',
      PageNo: 0,
      PageSize: 10000,
      GroupID: this.sGroupids
    }
    this.restservice.postData(params, '/api/Transaction/TransactionFetchAwaitingMyApproval')
      .subscribe(
        (data) => {

          if (data.rcode == 1) {

            if (data.rObj.Transaction != null) {
              let _temp: any = [];
              for (let i = 0; i < data.rObj.Transaction.length; i++) {
                if (data.rObj.Transaction[i] != null) {
                  let isInvolved: boolean = false;
                  /*  data.rObj.Transaction[i].ApprovalLevels.forEach((group:any) => {
                    if(group.approvedUser == this.MsalauthService.instance.getAllAccounts()[0].username) {
                      if(group.statusID != 1005 && ) {
                        isInvolved = true;
                      } else {
                        isInvolved = false;
                      }
                    }
                  })  */
                  if (data.rObj.Transaction[i].ApprovalLevels != null) {
                    for (let j = 0; j < data.rObj.Transaction[i].ApprovalLevels.length; j++) {
                      // if(data.rObj.Transaction[i].ApprovalLevels[j].ApprovedUser == this.MsalauthService.instance.getAllAccounts()[0].username) {
                      //   if(j > 1 && j < (data.rObj.Transaction[i].ApprovalLevels.length - 1)) {
                      //     if(data.rObj.Transaction[i].ApprovalLevels[j].StatusID != 1005 && data.rObj.Transaction[i].ApprovalLevels[j + 1].StatusID != 1005) {
                      //       isInvolved = true;
                      //     } else if (data.rObj.Transaction[i].ApprovalLevels[j].StatusID != 1005 && data.rObj.Transaction[i].ApprovalLevels[j+ 1].StatusID == 1005) {
                      //       isInvolved = false;
                      //     } else {
                      //       isInvolved = false;
                      //     }
                      //   }
                      // }

                      //new
                      
                      // if (data.rObj.Transaction[i].ApprovalLevels[j].Members.length != 0) {
                      //   data.rObj.Transaction[i].ApprovalLevels[j].Members.forEach((element: string) => {
                      //     if (element == this.MsalauthService.instance.getAllAccounts()[0].username && (data.rObj.Transaction[i].ApprovalLevels[j].StatusID == 1001 || data.rObj.Transaction[i].ApprovalLevels[j].StatusID == 1004)) {
                      //       isInvolved = false;

                      //     }
                      //     else {
                      //       isInvolved = true
                      //     }
                      //     if (!isInvolved) {
                      //       //let _lowestEstimatedAmt = Math.min.apply(Math, data.rObj.Transaction[i].ApprovalLevels.map(function (o: { FinancialsLowerLimit: any; }) { return o.FinancialsLowerLimit; }))
                      //       //let _highestEstimatedAmt = Math.max.apply(Math, data.rObj.Transaction[i].ApprovalLevels.map(function (o: { FinancialsUpperLimit: any; }) { return o.FinancialsUpperLimit; }))

                      //       let _previousLevels = data.rObj.Transaction[i].ApprovalLevels.filter((x: any) => x.StatusID != 1007 && x.Level < data.rObj.Transaction[i].CurrentLevel && data.rObj.Transaction[i].CurrentLevel != 1)


                      //       if (_previousLevels.length <= 0 && data.rObj.Transaction[i].CurrentLevel != 1 && (data.rObj.Transaction[i].FinanceAmount >= data.rObj.Transaction[i].ApprovalLevels[j].FinancialsLowerLimit && data.rObj.Transaction[i].FinanceAmount <= data.rObj.Transaction[i].ApprovalLevels[j].FinancialsUpperLimit)) {
                      //         data.rObj.Transaction[i].isCurrentLevelSendback = false;
                      //       }
                      //       else {
                      //         data.rObj.Transaction[i].isCurrentLevelSendback = true;
                      //       }
                      //       _temp.push(data.rObj.Transaction[i]);
                      //       // this.showContainerSearchResultTab(data.rObj.Count.count);
                      //     }

                      //   });
                      // }
                      // else 
                      // if (data.rObj.Transaction[i].ApprovalLevels[j].Members.length == 0) {
                        //data.rObj.Transaction[i].ApprovalLevels[j].forEach((element: string) => {
                        let groupMem:boolean=false;
                        for (let groups of data.rObj.Transaction[i].ApprovalLevels[j].ApprovalGroups) {
                           if(some(this.groupIds, ['id', groups.GroupID])){
                            groupMem=true;
                           }
                          }
                        
                        // let groupMem=this.groupIds.find((x:any)=> x.id==data.rObj.Transaction[i].ApprovalLevels[j].ApprovalGroups[0].GroupID)
                          if (groupMem && (data.rObj.Transaction[i].ApprovalLevels[j].StatusID == 1001 || data.rObj.Transaction[i].ApprovalLevels[j].StatusID == 1004|| data.rObj.Transaction[i].ApprovalLevels[j].StatusID == 1010)) {
                            isInvolved = false;
                          }
                          else {
                            isInvolved = true
                          }
                          if (!isInvolved) {
                            let _previousLevels = data.rObj.Transaction[i].ApprovalLevels.filter((x: any) => x.StatusID != 1007 && x.Level < data.rObj.Transaction[i].CurrentLevel && data.rObj.Transaction[i].CurrentLevel != 1)
                            if (_previousLevels.length <= 0 && data.rObj.Transaction[i].CurrentLevel != 1 && (data.rObj.Transaction[i].FinanceAmount >= data.rObj.Transaction[i].ApprovalLevels[j].FinancialsLowerLimit && data.rObj.Transaction[i].FinanceAmount <= data.rObj.Transaction[i].ApprovalLevels[j].FinancialsUpperLimit)) {
                              data.rObj.Transaction[i].isCurrentLevelSendback = false;
                            }
                            else {
                              data.rObj.Transaction[i].isCurrentLevelSendback = true;
                            }
                            if(!some(_temp, ['id', data.rObj.Transaction[i].id])){
                              _temp.push(data.rObj.Transaction[i]);
                            }
                          }

                        //});
                      // }

                      this.showContainerSearchResultTab(data.rObj.Count.count);
                    }
                  }
                  /* if(data.rObj.Transaction[i].ApprovalLevels.length >= 2) {
                    if(data.rObj.Transaction[i].ApprovalLevels[data.rObj.Transaction[i].currentLevel].sta)
                  } */
                  // if (!isInvolved) {
                  //   _temp.push(data.rObj.Transaction[i]);
                  //   this.showContainerSearchResultTab(data.rObj.Count.count);
                  // }
                }
              }
              
              this.myApprovalList = _temp.map((record:any)=>{
                record.isSendBackToInitiator=false;
                if(record.CategoryID==3 && record.CurrentLevel==1){
                    record.isSendBackToInitiator=true;
                }else if(record.CategoryID!=3){
                  let levelsBetweenThePriceLimit=record.ApprovalLevels.filter((al:any)=>{
                    return al.FinancialsLowerLimit <= record.FinanceAmount && al.FinancialsUpperLimit >= record.FinanceAmount;
                  });
                  levelsBetweenThePriceLimit= sortBy(levelsBetweenThePriceLimit, [function(o) { return o.Level; }])
                  if(record.CurrentLevel==levelsBetweenThePriceLimit[0].Level){
                    record.isSendBackToInitiator=true;
                  }
                }
                return record;
              });
            }



          }
          this.loader.hide();
        },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        }
      );
    /* let newResult:any = [];
    setTimeout(() => {
      this.myApprovalList.forEach(transaction => {
        let id = this.configActivityList.find(item => item.id == transaction.activityConfigID);
        //console.log(id)
        if(id) {
          transaction.workflowTitle = id.Title
          newResult.push(transaction);
        }
      });
     // console.log(newResult)
    }, 2000);
    this.myApprovalList = newResult; */
  }
  getAllApprovalsSearch(searchInput: any): any {
    if (this.searchInput != '') {

      this.myApprovalList = this.myApprovalList.filter(function (tag) {
        return tag.Title.toLowerCase().indexOf(searchInput.toLowerCase()) || tag.BusinessUnit.toLowerCase().indexOf(searchInput.toLowerCase())
          || tag.ActivityConfigTitle.toLowerCase().indexOf(searchInput.toLowerCase()) || tag.id.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0;
      });


      //  this.myApprovalList.filter(function(item: any){
      //     return JSON.stringify(item).toLowerCase().includes(searchInput.trim());
      //  });
      //this.myApprovalList.filter(x => x.id == this.searchInput)

    }
    else {
      this.getAllApprovals()
    }
  }


  showContainerSearchResultTab(data: any) {
    if (data) {
      this.paginationComp.SetTotalRecords(data);
      this.paginationComp.CalculatePaginationPages();
    }
  }

  //Method to open transaction details pop-up to view transaction details
  viewDetails(transaction: any): void {
    let data = {
      transaction: transaction
    }
    /*  const dialogRef = this.dialog.open(TransactionDetailComponent, {
       width: '60vw',
       height: '70vh',
       data: data,
       disableClose: true
     });
     dialogRef.afterClosed().subscribe(); */
    this.router.navigate(['/transactions-detail', transaction.id, 'Approval'])
  }


  approveLevel(dataApproval: any, type: number) {
    const dialogRef = this.dialog.open(AwitingApprovalDialogComponent, {
      width: '60vw',
      height: '70vh',
      data: { dataObj: dataApproval, type: type },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getMembers()
    });

  }

  getAllApprovalspage() {
    this.router.navigate(['/my-approvals'])
  }
  approvalHistory() {
    this.router.navigate(['/approval-history'])
  }


}
