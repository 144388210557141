import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-workflow-history',
  templateUrl: './workflow-history.component.html',
  styleUrls: ['./workflow-history.component.css']
})
export class WorkflowHistoryComponent implements OnInit {

  configData: any;
  proposalData: any;
  approvalData: any;
  iterables: any = [];
   //@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
   constructor(
      public dialogRef: MatDialogRef<WorkflowHistoryComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private loader: LoaderService,
      private restservice: RestService,
      private router: Router
    ) {}

  ngOnInit() {
    for(let i = 1; i < this.data.LastVersion + 1; i++) {
      this.iterables.push(i);
    }
    /* let params={
      ActivityConfigID: this.data.id,
      Version: 1
    }
    this.loader.show();
    this.restservice.postData(params ,'api/ActivityConfig/FetchWorkflowVersion')
    .subscribe(
      (data) => {
        this.loader.hide();
        if(data.rcode==1) {
          console.log(data);
          this.configData = data.rObj.ActivityConfig;
          this.approvalData = this.configData.ApprovalLevels;
          this.approvalData.forEach((group:any) => {
            let groups:any = [];
            group.approvalGroups.forEach((names:any) => {
              groups.push(names.Group);
            })
            this.approvalData.groupNames = groups.join(',')
          })
          let proposals: any = [];
          this.configData.ProposerGroups.forEach((item:any) => {
            proposals.push(item.Group)
          });
          this.proposalData = proposals.join(',')
        } 
      },
      (err) => {
        this.loader.hide();
        console.log(err);
      }
    ); */
    this.viewVersion(1)
  }

  //Method to view different versions
  viewVersion(version:any) {
    let params={
      ActivityConfigID: this.data.id,
      Version: version
    }
    this.restservice.postData(params ,'/api/ActivityConfig/FetchWorkflowVersion')
    .subscribe(
      (data) => {
        let proposals: any = [];
        let groups:any = [];
        if(data.rcode==1) {
          this.configData = data.rObj.ActivityConfig;
          this.approvalData = data.rObj.ActivityConfig.ApprovalLevels;
          this.approvalData.forEach((group:any) => {
            group.ApprovalGroups.forEach((names:any) => {
              groups.push(names.Group);
            })
            group.groupNames = groups
          });
          data.rObj.ActivityConfig.ProposerGroups.forEach((item:any) => {
            proposals.push(item.Group)
          });
          this.proposalData = proposals
        } 
      },
      (err) => {
        console.log(err);
        if (err.status === 401 || err.status === 403 || err.status === 0) {
          localStorage.clear();
          this.router.navigate([''])
          .then(() => {
            window.location.reload();
          });
        }
      }
    );
  }

  /*Ok to proceed with the action*/
  acceptError(): void {
    this.dialogRef.close(true);
    }

  /* Decline the action */
  decline(): void {
    this.dialogRef.close(false);
  }

}
