import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination } from 'src/app/models/pagination';
import { RowRange } from 'src/app/models/RowRange';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() pagination: Pagination = new Pagination();
 // @Input() pageNo!:number;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onPageChange = new EventEmitter<Pagination>();

  constructor() {

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
console.log(this.pagination.paginationPageIndexes);
// if(this.pageNo!=undefined){
//   this.pagination.currentPage=0
//   this.pagination.currentPage = this.pageNo;
// }
  }

  ngAfterViewInit() {

  }

  SetPage(page:any) {

    this.pagination.currentPage = page;
    this.pagination.CalculateRowRange();
    // if(this.pageNo!=undefined){
    //   this.pagination.currentPage=0
    //   this.pagination.currentPage = this.pageNo;
    // }
    this.onPageChange.emit(this.pagination);

   
  }

  GetRowRange(): RowRange {
    return this.pagination.rowRange;
  }

  CalculatePaginationPages() {
    this.pagination.CalculatePaginationPages();
  }

  SetTotalRecords(total: number) {
    this.pagination.totalRecords = total;
  }

  Reset() {
    this.pagination.Reset();
  }

  GetPagination(): Pagination {
    return this.pagination;
  }

  PageSizeChanged(event:any) {
    console.log('Page Size changed: ' + event.target.value);
    this.pagination.rowRange.maxResult = Number(event.target.value);
    //reset page to 1
    this.SetPage(1);
  }

  return() {
    return;
  }
pageChnage(){
  //(page+1) == pagination.currentPage?return():SetPage(page+1)
  // if(this.page+1) == this.pagination){
  //   (this.page+1) == pagination.currentPage?return():SetPage(page+1)
}

}
