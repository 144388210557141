import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { AuthService } from 'src/app/core/auth.service';
import { TokenStorage } from 'src/app/core/token-storage';
import { ScheduleComponent } from 'src/app/schedule/schedule.component';
import { RestService } from 'src/app/service/rest.service';
import { ScreenAccessService } from 'src/app/service/screen-access.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  loggedInUser: string | undefined = '';
  isConfigAllowed: boolean = false;
  groups: any;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private token: TokenStorage, private router: Router,
    private http: HttpClient,
    private restservice: RestService,
    public MsalauthService: MsalService,
    private authservice: AuthService,
    public screenAccessService: ScreenAccessService,
    private dialog: MatDialog) {
    $(document).ready(() => {

      $('.navbar .dropdown-item').on('click', (e: any) => {
        var $el = $(this).children('.dropdown-toggle');
        var $parent = $el.offsetParent(".dropdown-menu");
        $(this).parent("li").toggleClass('open');

        if (!$parent.parent().hasClass('navbar-nav')) {
          if ($parent.hasClass('show')) {
            $parent.removeClass('show');
            $el.next().removeClass('show');
            $el.next().css({ "top": -999, "left": -999 });
          } else {
            $parent.parent().find('.show').removeClass('show');
            $parent.addClass('show');
            $el.next().addClass('show');
            // $el.next().css({"top": $el[0].offsetTop, "left": $parent.outerWidth() - 4});
          }
          e.preventDefault();
          e.stopPropagation();
        }
      });

      $('.navbar .dropdown').on('hidden.bs.dropdown', () => {
        $(this).find('li.dropdown').removeClass('show open');
        $(this).find('ul.dropdown-menu').removeClass('show open');
      });

    });
  }

  ngOnInit() {
    //console.log("loggedInUser " +JSON.stringify(this.MsalauthService.instance.getAllAccounts()[0].name) ) 
    /*   if(window.location.pathname != this.cookieService.get('path')) {
        this.router.navigate([this.cookieService.get('path')])
      } */
    setTimeout(() => { this.refreshData(); }, 1000 * 5);
    this.authservice.getMembers().subscribe((data: any) => {
      this.groups = data.value;
    }, errVal => {
      if (errVal.status == 401) {
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.token.MSALget()
        });
        this.restservice.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
          this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
          this.authservice.getMembers().subscribe((dataVal: any) => {
            this.groups = dataVal.value;
          })
        }, (err) => {
          console.log(err);
          if (err.status === 401 || err.status === 403 || err.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        });
      }
    })
    //console.log(this.MsalauthService.instance.getAllAccounts()[0])
    this.loggedInUser = this.MsalauthService.instance.getAllAccounts()[0].name?.toString();
    //this.appConfig();
    // setTimeout(() => { this.configCreation(); }, 1000);
    // setTimeout(() => { this.configCreation(); }, 2000);
    setTimeout(() => { this.refreshToken() }, 1000 * 2)
    $('.navbar-light .dmenu').hover(() => {
      $(this).find('.sm-menu').first().stop(true, true).slideDown(150);
    }, () => {
      $(this).find('.sm-menu').first().stop(true, true).slideUp(105)
    });
  }
  logout() {
    this.token.destroy();
    window.localStorage.clear();
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.MsalauthService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else {
      this.MsalauthService.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }

  configCreation() {
    this.restservice.postData(null, '/api/ActivityConfig/WorkflowAdminFetch')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            let group = this.groups?.find((item: any) => item === data.rObj.workflowAdmin[0].GroupID);
            this.isConfigAllowed = group ? true : false;
          }
        },
        (err) => {
          console.log(err);
          if (err.status === 401 || err.status === 403 || err.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        }
      );
  }

  refreshToken() {
    setInterval(() => {
      const account = this.MsalauthService.instance.getAllAccounts()[0];
      const accessTokenRequest = {
        scopes: ['api://e06ee7d1-89ba-4650-b0f6-3ff155dd6f92/access_as_user', "openid", "profile", 'user.read'],
        account: account
      }
      this.MsalauthService.instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
        let accessToken = accessTokenResponse.accessToken;
        window.localStorage['JanusAuthToken'] = accessToken;
        this.appConfig();
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          this.MsalauthService.instance.acquireTokenPopup(accessTokenRequest).then(function (accessTokenResponse: { accessToken: any; }) {
            let accessToken = accessTokenResponse.accessToken;
          }).catch(function (error: any) {
            console.log(error);
          });
        }
        console.log(error);
      });
    }, 1000 * 60 * 40);
  }

  appConfig() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token.MSALget()
    });
    this.restservice.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
      this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
    }, (err) => {
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        localStorage.clear();
        this.router.navigate([''])
          .then(() => {
            window.location.reload();
          });
      }
    });
  }

  //Method to get menu changes at regular time intervals
  refreshData() {
    setInterval(() => {
      // this.cookieService.set( 'path', this.router.url );
      localStorage.setItem('path', this.router.url);
    }, 500);
  }
  schedule() {
    const dialogRef = this.dialog.open(ScheduleComponent, {
      width: '60vw',
      height: '70vh',
      disableClose: true
    });

  }
  /* 
    appConfig() {
     /*  let body = new URLSearchParams();
      let getAccessTokeUrl = environment.getAccessTokeUrl
      let clientID = environment.clientId;
      let ADsecretKey = environment.refreshSecretKey;
      body.set('Content-Type', "application/x-www-form-urlencoded");
      body.set("grant_type", "client_credentials");
      body.set("client_id",clientID );
      body.set("scope", "api://e06ee7d1-89ba-4650-b0f6-3ff155dd6f92/access_as_user openid profile user.read");
      body.set("client_secret",ADsecretKey);
  /*     body.set('Content-Type', "application/x-www-form-urlencoded");
      body.set("grant_type", "client_credentials");
      body.set("client_id",clientID );
      body.set("scope", "api://e06ee7d1-89ba-4650-b0f6-3ff155dd6f92/access_as_user openid profile user.read");
      body.set("client_assertion",this.token.MSALget());
      body.set("client_assertion_type", "urn:ietf:params:oauth:client-assertion-type:jwt-bearer");
      const options = {
        headers: new HttpHeaders({ 
        'Content-Type': 'application/x-www-form-urlencoded',
        "Cookie": "fpc=AthXMyGmhy5HovkgBAzdvD3PR1h9AQAAAN1jWdgOAAAA; stsservicecookie=estsfd; x-ms-gateway-slice=estsfd",
        'Access-Control-Allow-Methods':'POST',
        'Access-Control-Allow-Credentials': 'true'
      }),
      };
      this.http.post(getAccessTokeUrl, body.toString(), options)
        .subscribe(data => {
          console.log('do::', data);
          localStorage.setItem('JANUS-AuthToken',JSON.stringify(data));
          //localStorage.setItem('CRAAuthRefreshToken', data.refresh_token);
        }, (err: any) => {
          console.log(err)
        });
    } 
      const headers = new HttpHeaders({ 
      /* 'Content-Type': 'application/x-www-form-urlencoded',
      "Cookie": "fpc=AthXMyGmhy5HovkgBAzdvD3PR1h9AQAAAN1jWdgOAAAA; stsservicecookie=estsfd; x-ms-gateway-slice=estsfd",
      'Access-Control-Allow-Methods':'POST',
      'Access-Control-Allow-Credentials': 'true' 
      'Authorization' : 'Bearer ' + this.token.MSALget()
    });
    this.restservice.postData(null,'/api/Account/GetApplicationToken', headers).subscribe((data) => {
      console.log(data);
      this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
    }, (err) => {
      console.log(err);
    });
    } */
}
// $(document).ready( () => {
//   $('.navbar-light .dmenu').hover( () => {
//           $(this).find('.sm-menu').first().stop(true, true).slideDown(150);
//       }, ( ()=> {
//           $(this).find('.sm-menu').first().stop(true, true).slideUp(105)
//       }));
//   })