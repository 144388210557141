import { Inject, Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
//import {User} from '../model/user.model';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenStorage} from './token-storage';
import {Router} from '@angular/router';
import {LoaderService} from '../service/loader.service';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { DatePipe } from '@angular/common';
declare var $:any
//const apiUrl = environment.baseApiUrl;
const clientID = environment.clientId;
const ADsecretKey = environment.refreshSecretKey;
const getAccessTokeUrl=environment.getAccessTokeUrl;

interface State {
  Name: any;
  authenticated: boolean;
  mail:string;
  token:any;

}

const defaultState: State = {
    Name: null,
    authenticated: false,
    mail:'',
    token:null
  }
;
const store = new BehaviorSubject<State>(defaultState);

class Store {
  private store = store;
  changes = this.store.asObservable().pipe(distinctUntilChanged());

  setState(state: State) {
    this.store.next(state);
  }

  getState(): State {
    return this.store.value;
  }

  updateState(data: State) {
    this.store.next(Object.assign({}, this.getState(), data));
  }

  purge() {
    this.store.next(defaultState);
  }
}
const requestObj = {
  scopes: ["user.read"]
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private store: Store = new Store();
  public authenticated: boolean = false;

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  userID: string = '';

constructor(public MsalauthService: MsalService,private http: HttpClient, private jwt: TokenStorage, private router: Router, public loaderService: LoaderService,
  @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
  private msalBroadcastService: MsalBroadcastService) { 
  }

  public getToken(): any {
    return localStorage.getItem('JanusAuthToken');
  }

  isAuthenticated() {
      return this.verifyAuth().pipe(map(auth => {
        return Boolean(auth);
      }));
    
  }
  verifyAuth(): Observable<any> {
    if (!this.jwt.MSALget()) {
      return of(null);
    }
   return  this.http.get<any>('https://graph.microsoft.com/v1.0/me')
    .pipe(map(data => {
      this.store.setState({
        Name: data.displayName,
        authenticated: Boolean(data),
        mail: data.mail,
        token:this.jwt.MSALAppget()
        
      });
      return data;
    }
  ));
   // return this.getToken()
         
  }

  getBusinessUnits(){
    const httpOptions = {
      headers: new HttpHeaders({
          'ConsistencyLevel': 'eventual',
          'Authorization' : 'Bearer ' + this.jwt.MSALAppget()
        })
    }; 
      return this.http.get("https://graph.microsoft.com/v1.0/groups?$filter=startswith(displayName, 'BU')",httpOptions)
        .pipe(tap(data => {
         //console.log(data)
        })
        );
  }

  getProposerRoles(id:string){
    const httpOptions = {
      headers: new HttpHeaders({
          'ConsistencyLevel': 'eventual',
          'Authorization' : 'Bearer ' + this.jwt.MSALAppget()
        })
    };    return this.http.get("https://graph.microsoft.com/v1.0/groups/"+`${id}`+"/members",httpOptions)
        .pipe(tap(data => {
        // console.log(data)
        })
        );
  }

  //FETCH TRANSITIVE MEMBERS
  getApprovalGroups(id:string){
    const httpOptions = {
      headers: new HttpHeaders({
          'ConsistencyLevel': 'eventual',
          'Authorization' : 'Bearer ' + this.jwt.MSALAppget()
        })
    };    return this.http.get("https://graph.microsoft.com/v1.0/groups/"+`${id}`+"/transitiveMembers?$top=999",httpOptions)
        .pipe(tap(data => {
         //console.log(data)
        })
        );
  }
  
//FETCH TRANSITIVE MEMBEROF
  getApprovalParentGroup(id:string){
    const httpOptions = {
      headers: new HttpHeaders({
          'ConsistencyLevel': 'eventual',
          'Authorization' : 'Bearer ' + this.jwt.MSALAppget()
        })
    };    return this.http.get("https://graph.microsoft.com/v1.0/groups/"+`${id}`+"/transitiveMemberOf",httpOptions)
        .pipe(tap(data => {
         //console.log(data)
        })
        );
  }



//Method to get member groups for logged in user
getMembers(){
  if(this.MsalauthService.instance.getAllAccounts()[0].idTokenClaims) {
    let user : any = this.MsalauthService.instance.getAllAccounts()[0].idTokenClaims
    this.userID = user.oid
  }
  const httpOptions = {
    headers: new HttpHeaders({
        'ConsistencyLevel': 'eventual',
        'Authorization' : 'Bearer ' + this.jwt.MSALAppget()
      })
  }; 
  return this.http.post('https://graph.microsoft.com/v1.0/users/' + `${this.userID}` +'/getMemberGroups', { "securityEnabledOnly": true }, httpOptions)
      .pipe(tap(data => {
      // console.log(data)
      })
      );
}


//FETCH ALL GROUPS
getGrops(){
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization' : 'Bearer ' + this.jwt.MSALAppget()
      })
  }; 
  return this.http.get('https://graph.microsoft.com/v1.0/groups?$filter=securityEnabled+eq+true', httpOptions)
      .pipe(tap(data => {
       //console.log(data)
      })
      );
}

//FETCH GROUP MEMBERS
getGroupMembers(id:string){
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization' : 'Bearer ' + this.jwt.MSALAppget()
      })
  }; 
  return this.http.get('https://graph.microsoft.com/v1.0/groups/'+id+'/members', httpOptions)
      .pipe(tap(data => {
       //console.log(data)
      })
      );
}


//FETCH LOGGEDIN USER GROUPS
getMyGroups() {
  if(this.MsalauthService.instance.getAllAccounts()[0].idTokenClaims) {
    let user : any = this.MsalauthService.instance.getAllAccounts()[0].idTokenClaims
    this.userID = user.oid
  }
  const httpOptions = {
    headers: new HttpHeaders({
        'ConsistencyLevel': 'eventual',
        'Authorization' : 'Bearer ' + this.jwt.MSALAppget()
      })
  };
  return this.http.get('  https://graph.microsoft.com/v1.0/users/' + `${this.userID}` +'/memberOf', httpOptions)
      .pipe(tap(data => {
       // console.log("My Groups")
       //console.log(data)
      })
  );
}

  ADAttemptlogin1(code:string): Observable<any> {
    //const loginUrl = 'https://crauatv2.b2clogin.com/crauatv2.onmicrosoft.com/B2C_1_signin/oauth2/v2.0/token';

    let body = new URLSearchParams();
    body.set('Content-Type', "application/x-www-form-urlencoded");
    body.set("grant_type", "authorization_code");
    body.set("client_id",clientID );
    body.set("scope", "api://e06ee7d1-89ba-4650-b0f6-3ff155dd6f92/access_as_user");
    body.set("code", code);
    body.set("redirect_uri", "http://localhost:55157/my-approvals");
    body.set("client_secret",ADsecretKey);
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded',
      "Cookie": "fpc=Ai7N3aQdZ0VFplfGIwpMXGzd0b2kAQAAACaALNgOAAAA; stsservicecookie=estsfd; x-ms-gateway-slice=estsfd",
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods':'POST',
      'Access-Control-Allow-Credentials': 'true'
    }),
    };
    return this.http.post(getAccessTokeUrl, body.toString(), options)
      .pipe(tap(data => {
        console.log('do::');
        // this.store.setState({
        //   data: data, authenticated: Boolean(data), menu: null, email: "",
        //   fullName: "",
        //   entityName: "",
        //   roleName: "",
        //   LogInUserID:'',
        //   notificationUrl:""
        // });
        localStorage.setItem('JANUS-AuthToken',JSON.stringify(data));
        //localStorage.setItem('CRAAuthRefreshToken', data.refresh_token);
      })
      );
  }

  // setLoginDisplay() {
  //    this.authService.instance.getAllAccounts().length > 0;
    
  // }

  // isADAuthenticate(){
  //   return this.isIframe = false;

  // }
  // ADAttemptlogin(){
  //   if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
  //     if (this.msalGuardConfig.authRequest) {
  //       this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
  //         .subscribe((response: AuthenticationResult) => {
  //           this.authService.instance.setActiveAccount(response.account);
  //         });
  //     } else {
  //       this.authService.loginPopup()
  //         .subscribe((response: AuthenticationResult) => {
  //           this.authService.instance.setActiveAccount(response.account);
  //         });
  //     }
  //   } else {
  //     if (this.msalGuardConfig.authRequest) {
  //       this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
  //     } else {
  //       this.authService.loginRedirect();
  //     }
  //   }
  // }
  

  getFullName() {
    return this.store.getState().Name
  }
  

  formatAMPM(date:any) {
    //date=null
    if(date!=null){
      return  new DatePipe('en-Us').transform(date, 'dd/MM/YYYY hh:mm a', 'GMT');

    }
    else{
      return null
    }
  }


}
