import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/auth.service';
import { TokenStorage } from 'src/app/core/token-storage';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { AwitingApprovalDialogComponent } from '../Shared/awiting-approval-dialog/awiting-approval-dialog.component';
import { DateFilterPipe } from '../pipes/dateFormat.pipe';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
 
@Component({
  selector: 'app-approval-history',
  templateUrl: './approval-history.component.html',
  styleUrls: ['./approval-history.component.css']
})
export class ApprovalHistoryComponent implements OnInit {
  currentPage: number = 1;
  row_count: number = 0;
  page_count: number = 10;
  totalCount: number=0;
  resultPerpage=new FormControl(10);
  ApprovalsHistoryPageFilter!: FormGroup;
  //@ViewChild('blPagination', { static: true }) paginationComp!: PaginationComponent;
  transactions : Array<any> = new Array<any>();
  transactionTemp: Array<any> = new Array<any>();
  // searchInput:string='';
  // buFilter: string = '';
  categories: Array<any> = [];
  // categoryFilter: string = '';
  // StatusIDFilter: string ='';
  Status:Array<any> =[];
  fromDate: string = '';
  toDate: string = '';
  businessUnits: Array<any> = [];
  changeText!: boolean;
  myApprovalList: Array<any> = new Array<any>();
  myApprovalHistoryList: Array<any> = new Array<any>();
  configActivityList: Array<any> = new Array<any>();
  groupIds: [] = [];
  sGroupids: string = '';
  ApproveId = 1002;
  RejecteId = 1003;
  OnHoldId = 1004;
  SentBackToPreviousLevelID = 1005
  constructor(
    private formBuilder: FormBuilder,
    private restservice: RestService, 
    private loader: LoaderService, 
    private token: TokenStorage, 
    private router: Router, 
    private dialog: MatDialog,
    private MsalauthService: MsalService, 
    public authservice: AuthService,
    private datePipe: DateFilterPipe) { }

  ngOnInit(): void {
    this.ApprovalsHistoryPageFilter = this.formBuilder.group({
      searchInput: [''],
      categoryFilter: [''],
      buFilter: [''],
      StatusIDFilter: [''],
    });
    // setInterval(() => {
   // this.getConfigList();
   this.getAllApprovalHistory()
   this.getAllBusinessUnits()
    // this.getMembers();
   // this.getAllApprovals();
    // }, 5000);
    this.categories = [
      {category: 'All', categoryID: 0},
      {category: 'CAPEX', categoryID: 1},
      {category: 'OPEX', categoryID: 2},
      {category: 'Non Financial', categoryID: 3},
      {category:  'Other Financial', categoryID:4}
    ]
    
    this.Status =[
      {status: 'All',StatusID: 0},
       {status: 'Awaiting Approval',StatusID:1001},
       {status: 'Approved',StatusID:1002},
       {status: 'Rejected',StatusID:1003},
       {status: 'On Hold',StatusID:1004},
       {status: 'Sent Back',StatusID:1005},
       {status: 'Cancelled',StatusID:1006},
       {status: 'Not Applicable',StatusID:1007},
       {status: 'Sent Back To Initiator',StatusID:1008}
      
    ]
    this.ApprovalsHistoryPageFilter.valueChanges.subscribe(data=>{
      this.row_count= 0;
      this.currentPage=1;
      this.getAllApprovalHistory();
    });
    this.resultPerpage.valueChanges.subscribe(value=>{
      this.row_count= 0;
      this.page_count=parseFloat(this.resultPerpage.value);
      this.currentPage=1;
      this.getAllApprovalHistory();
    });
  }

      //Method to get config list
      getConfigList() {
        let configparams={
          SearchText: '',
          PageNo:0,
          PageSize:1000
        }
        this.restservice.postData(configparams ,'/api/ActivityConfig/ActivityConfigFetchList')
        .subscribe(
          (data) => {
            if(data.rcode==1) {
              if(data.rObj.ActivityConfig!=null){
                this.configActivityList=data.rObj.ActivityConfig
              }
             } 
          },
          (err) => {
            console.log(err);
            if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
              localStorage.clear();
              this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
            }
          }
        );
       // this.getTransactions();
      }

  // OnPageChange(event: any) {
  //   this.getAllApprovalHistory();
  // }





  //Method to open transaction details pop-up to view transaction details
  viewDetails(transaction: any): void {
    let data = {
      transaction: transaction
    }
   /*  const dialogRef = this.dialog.open(TransactionDetailComponent, {
      width: '60vw',
      height: '70vh',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(); */
    this.router.navigate(['/transactions-detail', transaction.id,'Approval'])
  }




  getAllApprovalHistory(){
    // let rowRange = this.paginationComp.GetRowRange();
    // if( this.searchInput.trim()!=''){
    //   rowRange.firstResult=0;
    //  }
    let params={
      "UserEmail":this.MsalauthService.instance.getAllAccounts()[0].username ,
      SearchText:  this.ApprovalsHistoryPageFilter.get("searchInput")?.value?this.ApprovalsHistoryPageFilter.get("searchInput")?.value.trim():"",
      BusinessUnit: this.ApprovalsHistoryPageFilter.get("buFilter")?.value?this.ApprovalsHistoryPageFilter.get("buFilter")?.value:"", 
      CategoryID:this.ApprovalsHistoryPageFilter.get("categoryFilter")?.value ? parseFloat(this.ApprovalsHistoryPageFilter.get("categoryFilter")?.value):0,
      StatusID:this.ApprovalsHistoryPageFilter.get("StatusIDFilter")?.value ? parseInt(this.ApprovalsHistoryPageFilter.get("StatusIDFilter")?.value):0,
      "StartTime": "",
      "EndTime": "",
      PageNo:this.row_count,
      PageSize:this.page_count,
    }
    this.restservice.postData(params, '/api/Transaction/ApprovalHistory')
     .subscribe(
        (data) => {
          
          if (data.rcode == 1) {
            this.totalCount=data.rObj.Count.Count;
            if (data.rObj.Transaction != null) {
              let _temp=[];
              for (let i = 0; i < data.rObj.Transaction.length; i++) {
                if (data.rObj.Transaction[i] != null) {                  
                    _temp.push(data.rObj.Transaction[i]);
                    //this.showContainerSearchResultTab(data.rObj.Count.Count);
                 
                }
              }
              //this.myApprovalHistoryList=_temp.filter(x=>x.StatusID!=1001);
              this.myApprovalHistoryList=_temp;
              //this.showContainerSearchResultTab(res.rObj.Count.Count);
              
            }

            // if(data.rObj.Count!=undefined && data.rObj.Count!=null){
            //   this.showContainerSearchResultTab(data.rObj.Count.Count);
    
            // }
          }
          this.loader.hide();
        },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
            localStorage.clear();
            this.router.navigate([''])
            .then(() => {
              window.location.reload();
            });
          }
        }
      );
  
    }


    // showContainerSearchResultTab(data: any) {
    //   if(this.searchInput.trim()!=''){
    //     this.paginationComp.SetPage(1)
    //     // let rowRange = this.paginationComp.GetRowRange();
    //     // rowRange.firstResult=0;
       
    //    }
    //     this.paginationComp.SetTotalRecords(data);
    //     this.paginationComp.CalculatePaginationPages();
    // }

    getAllBusinessUnits() {
      this.authservice.getBusinessUnits()
        .subscribe((data: any) => {
          let _temp=[];
          for(var j=0; j<data.value.length;j++){
            if(data.value[j].displayName!='BU-L1-JCG'){
              _temp.push(data.value[j]);
            }
          }
          this.businessUnits=_temp
        },
          (err) => {
            this.loader.hide();
            console.log(err);
            if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
              localStorage.clear();
              this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
            }
          })
    }
    getAllApprovalspage(){
      this.router.navigate(['/my-approvals'])    
    }
    approvalHistory(){
      this.router.navigate(['/approval-history']) 
    }


    clearFilter(){
      this.ApprovalsHistoryPageFilter.patchValue({
        searchInput: "",
        categoryFilter: "",
        buFilter:"",
        StatusIDFilter: "",
      });
      this.page_count=10;
      this.resultPerpage.patchValue(10);
    }
    onPageChange(page: number) {
      this.row_count = this.page_count * (page - 1);
      this.getAllApprovalHistory();
      window.scrollTo(0, 0);
    }
}
