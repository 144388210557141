<!-- 


<div *ngIf="loggedInUser!=''" class="navbar navbar-expand-md nav-bgcolor menu-content navbar-dark purple-gradient" role="navigation">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <i class="menu fa fa-bars fa-lg" aria-hidden="true"></i>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">

      <ul class="navbar-nav mr-auto custom-nav" >
          <img class="mr-2" src="https://www.swiftant.com/images/logo.png" width="50%" height="50%">
      </ul>
     
      <div class="form-inline mt-2 mt-md-0">
        <div class="dropdown row">
           
            <span class="usericon" data-toggle="dropdown" aria-hidden="true">
              Hello {{loggedInUser}}! <i class="fa fa fa-user fa-lg pointer mt-3 p-2"></i>
            </span>&nbsp; &nbsp; &nbsp;
            <span class="usericon" >
              <i class="fa fa-cog fa-lg pointer mt-3 p-2" [routerLink]="['settings']" title="Configuration" aria-hidden="true"></i>
             </span>&nbsp; &nbsp; &nbsp;
            <span class="usericon">
              <i class="fa fa fa-sign-out fa-lg pointer mt-3 p-2" title="Logout" (click)="logout()"></i>
            </span>
         
        </div>
      </div>
  </div>
</div>

 -->
 <nav class="navbar navbar-expand-md fixed-top navbar-dark bg-dark-nav" style="width: 100%;">
  <div class="container-fluid p-0">
      <a class="navbar-brand text-white logo"> <img class="logo mr-2" src="assets/images/logo2.png" width="35%" height="50%"></a>
      <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarsExampleDefault" data-toggle="collapse" data-target=".navbar-collapse" >
          <ul class="navbar-nav ml-auto">
            <li class="nav-item " *ngIf = "screenAccessService.screenAccess.isAdmin || screenAccessService.screenAccess.readOnlyAccessForDashboard">
              <a class="nav-link " [routerLink]="['dashboard']"><span class="icon-16 icon-requests_white"></span>&nbsp; Dashboard</a>
          </li>
          <li class="nav-item dropdown">

            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="icon-16 icon-approvals_white"></span>  Approvals
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" [routerLink]="['my-approvals']">My Approvals</a>
              <a class="dropdown-item" [routerLink]="['approval-history']">Approvals History</a>
             
            </div>
          </li>
              <li class="nav-item">
                  <a class="nav-link " [routerLink]="['transactions']"><span class="icon-16 icon-requests_white"></span> &nbsp; Transactions</a>
              </li>
              <li class="nav-item" *ngIf = "screenAccessService.screenAccess.isAdmin || screenAccessService.screenAccess.readOnlyAccessForWorkflow">
                  <a class="nav-link " [routerLink]="['settings']"><span class="icon-16 icon-settings"></span> &nbsp;Workflow Configuration</a>
              </li> 
             
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle"  id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                    <i class="fa fa-user"></i>&nbsp;Profile
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" >
                    <a class="dropdown-item">  {{loggedInUser}}</a>
                    <a class="dropdown-item" (click)="schedule()">Delegate</a>  
                    <a class="dropdown-item" (click)="logout()">  Logout</a>  
                  </div>
              </li>
          </ul>
      </div>
  </div>
</nav>

<!-- <div class="tab">
  <button  class="tablinks" (click)="getAllApprovalspage()">MY APPROVALS</button> 
  <button   class="tablinks" (click)="approvalHistory()"> APPROVALS HISTORY</button>
</div>   -->
