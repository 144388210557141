import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
const apiUrl = environment.baseApiUrl;
const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
      'key': 'x-api-key',
      'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',
     
    })
};
@Injectable({
  providedIn: 'root'
})
export class RestService {
  customUrl = environment.customUrl;
  constructor(public http: HttpClient) { }

  postData<T>(data: T, url: string, headers?:any): Observable<any> {
    if(headers) {
      return this.http.post<any>(`${apiUrl}` + url, data, {headers: headers})
      .pipe( map(response => {
          return response;
        }
      ));
    } else {
      return this.http.post<any>(`${apiUrl}` + url, data)
      .pipe( map(response => {
          return response;
        }
      ));
    }
  }

  getData<T>(params: HttpParams, url: string): Observable<any> {
    return this.http.get<any>(`${apiUrl}` + url, {params})
      .pipe(map(response => {
          return response;
        }
      ));
  }
//   downloadFile<T>(data: T, url: string): Observable<HttpResponse<Blob>> {
//     return this.http.post(`${apiUrl}` + url, data, {
//       observe: 'response',
//       responseType: 'blob'
//     }).pipe(map(response => {

//       if (navigator.appVersion.toString().indexOf('.NET') > 0) {
//         window.navigator.msSaveBlob(response.body, response.headers.get("content-disposition")); //Fix for IE for file download
//       } else {
//         let blob = new Blob([response.body], {type: 'text/html'});
//         let a = document.createElement('a');
//         a.href = URL.createObjectURL(blob);
//         a.download = response.headers.get("content-disposition");
//         document.body.appendChild(a);
//         a.click();
//         document.body.removeChild(a);
//       }

//       return response;
//     }));
//   }
  downloadFileFromUrl(url: string): void {
    window.open(url, '_blank');
  }
}
