<section>
  <div class="bgImage px-2 py-4">
    <div class="">
  
      <div class="text-center text-light">
        <h2>VIEW MY TRANSACTION</h2>
      </div>
  
      <div class="text-right">
        <button class="btn btn-Action btn-150 mb-2 " [routerLink]="['/manage-transactions']">New
          Transaction</button>
      </div>
      <form [formGroup]="TransactionPageFilter">
      <div class="display-flex col-12 justify-content-around mt-3 p-0">
  
        <div class="col-md-2 pl-sm-0 form-group">
          <input type="text" class="form-control col-sm-12 col-12" formControlName="searchInput"
            placeholder="Transaction ID, Nature of Transaction" title="Search by Transaction ID or Nature of Transaction">
        </div>
  
        <div class="col-md-2 date row form-group">
          <input type="text" class="form-control col-7 small p-2" formControlName="fromDate" placeholder="From Date "
             [matDatepicker]="fromDatePicker" readonly>
          <mat-datepicker-toggle matSuffix [for]="fromDatePicker" class="text-light"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker class="text-light"></mat-datepicker>
        </div>
  
        <div class="col-md-2 date row form-group">
          <input type="text" class="form-control col-7 small p-2" formControlName="toDate" placeholder="To Date "
            [matDatepicker]="toDatePicker" (change)="getTransactions()" readonly>
          <mat-datepicker-toggle matSuffix [for]="toDatePicker" class="text-light"></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker class="text-light"></mat-datepicker>
        </div>
  
        <div class="col-md-2 pl-sm-0 form-group">
          <select formControlName="StatusIDFilter" class="form-control col-md-12 col-sm-12">
            <option value='' disabled selected>Filter By Status</option>
            <option *ngFor="let status of Status" [value]='status.statusID'>{{status.status}}</option>
          </select>
        </div>
        <div class="col-md-2 pl-sm-0 form-group">
          <select formControlName="buFilter" class="form-control col-md-12 col-sm-12">
            <option value='' disabled selected>Filter By Business Unit</option>
            <option value='' selected>All</option>
            <option *ngFor="let bu of businessUnits" [value]='bu.id'>{{bu.displayName}} - {{bu.description}}</option>
          </select>
        </div>
        <div class="col-md-2 pl-sm-0 form-group">
          <select formControlName="categoryFilter" class="form-control col-md-12 col-sm-12">
            <option value='' disabled selected>Filter By Categories</option>
            <option *ngFor="let category of categories" [value]='category.categoryID'>{{category.category}}</option>
          </select>
  
        </div>
  
  
        <div class="col-md-1 text-right p-0">
          <button class="btn btn-Action small" (click)="clearFilter()">Reset Filter</button>
        </div>
        
      </div>
    </form>
  <div class="table-responsive-md">
      <table class="table tabDesign table-hover table-striped  mt-2 bg-light">
        <tr class="col-sm-12 col-md-12">
          <th class="mx-1 tabhead">Transaction ID</th>
          <th class="mx-1 tabhead">Requested Date Time</th>
          <th class="mx-1 tabhead">Workflow Configuration</th>
          <th class="mx-1 tabhead">Nature of Transaction</th>
          <th class="mx-1 tabhead">Business Unit </th>
          <th class="mx-1 tabhead">Category</th>
          <th class="mx-1 tabhead">Estimated Finance Amount</th>
          <th class="mx-1 tabhead">Status</th>
          <th class="mx-1 tabhead"  id="action">Action</th>
        </tr>
        <tbody class="tabBody">
          <tr *ngFor="let transaction of transactions| paginate: { itemsPerPage: resultPerpage.value, currentPage: currentPage,totalItems: totalCount };let i=index">
            <td class=" mx-1 tabData text-center"><a title="Click here to view details"
                [routerLink]="['/transactions-detail', transaction.id,'Transaction']">{{transaction.id}}</a></td>
            <td class=" mx-1 tabData text-center">{{ authService.formatAMPM(transaction.CreatedDate) }}</td>
            <td class=" mx-1 tabData">{{transaction.ActivityConfigTitle}}</td>
            <td class=" mx-1 tabData">{{transaction.Title}}</td>
            <td class=" mx-1 tabData ">
              {{transaction.BusinessUnit}}
            </td>
            <td class=" mx-1 tabData ">
              {{transaction.Category}}
            </td>
            <td class=" mx-1 tabData text-right">
              <span *ngIf="transaction.financeAmount!=0">${{transaction.FinanceAmount | thousandSeparator}}</span>
              <div class="text-center" *ngIf="transaction.financeAmount==0">&ndash;</div>
            </td>
  
  
            <td class=" mx-1 tabData text-center" [ngClass]="{
                    'AwaitingDecision': transaction.StatusID == 1001,
                    'accepted': transaction.StatusID == 1002,
                      'rejected': transaction.StatusID == 1003,
                      'onHold': transaction.StatusID == 1004,
                      'sentBack': transaction.StatusID == 1005,
                      'cancelled': transaction.StatusID == 1006,
                    'notapplicable': transaction.StatusID == 1007,
                    'SentBackToInitiator':transaction.StatusID == 1008
                  }">{{transaction.Status}}</td>
            <td class=" mx-1 tabData text-center action  ">
              <i *ngIf="transaction.StatusID == 1001" class="fa fa-trash btn btn-delete "
                (click)="deleteTransaction(transaction)" title="Delete"></i>
              <i *ngIf="transaction.StatusID != 1001" class="fa fa-trash btn btn-delete  pointer-ban"
                title="Delete not allowed"></i>
              <i *ngIf="transaction.StatusID ==1008" class="fa fa-pencil-square-o btn btn-edit  m-2" title="Edit"
                aria-hidden="true" (click)="edit(transaction)"></i>
              <i *ngIf="transaction.StatusID !==1008" class="fa fa-pencil-square-o btn btn-edit pointer-ban  m-2"
                title="Edit not allowed"></i>
  
              <i *ngIf="transaction.StatusID ==1002" class="fa fa-file-pdf-o btn btn-pdf" title="Download"
                aria-hidden="true" (click)="openDocument(transaction)"></i>
              <i *ngIf="transaction.StatusID !==1002" class="fa fa-file-pdf-o btn btn-pdf pointer-ban"
                title="Download not allowed"></i>
                <i *ngIf="transaction.StatusID == 1008" class="fa fa-times btn btn-cancel m-2"
                (click)="cancelTransaction(transaction)" title="Cancel"></i>
              <i *ngIf="transaction.StatusID != 1008" class="fa fa-times btn btn-cancel  pointer-ban m-2"
                title="Cancel not allowed"></i>
            </td>
  
          </tr>
          <tr *ngIf="transactions.length==0">
            <td class=" mx-1 tabData" colspan="8">No record found</td>
          </tr>
        </tbody>
      </table>
    </div>
      <div class="display-flex justify-content-end" *ngIf="totalCount>0">
        <pagination-controls class="my-pagination"
        previousLabel=""
        nextLabel=""
        (pageChange)="currentPage = $event;onPageChange($event)"></pagination-controls>
        <div class="bg-white px-2 display-flex align-items-center" >
          <span>
            Results per page
          </span>
          <span class="px-2">
            <select  id="resultPerpage" [formControl]="resultPerpage">
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </span>
        </div>
      </div>
    </div>
  </div>
  </section>