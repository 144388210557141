<div class="col-md-12 bgImage">
  <div class="col-12 ">
    <div class="col-md-12 pl-0 pt-3 pr-0">
      <div class="row col-12 p-0 m-0">
        <div class="col-sm-12 col-md-10 pl-0 text-light">
          <p class="font-weight-bold m-0">Please Note:</p>
          <ol type="i" class="col-md-8 col-sm-12 ">
            <li>Document downloads for Transaction summary will be available only for Approved Transactions</li>
            <li>The document will be available for download only after 15 minutes of approval</li>        
            <li>Documents generated on-demand, will be available for download only after 15 minutes.</li>

          </ol>
        </div>
        <div class="col-sm-12 col-md-2 pr-0 text-right pb-5">
          <button class="btn btn-Action" (click)="exportToExcel()"><i class="fa fa-download mr-1 pt-2"></i>Export to
            Excel</button>
        </div>
      </div>
    </div>
    <hr>
    <form [formGroup]="DashBoardFilter">
      <div class="form-row"> 
        <div class="form-group  col-12 col-md-2">
          <input type="text" class="form-control" formControlName="searchInput"
          placeholder="Transaction ID, Nature of Transaction" title="Search by Transaction ID or Nature of Transaction">
        </div>
        <div class="form-group col">
          <select formControlName="buFilterToGetGroups" class="form-control">
            <option value='' selected>Select Business Unit</option>
            <option *ngFor="let bu of businessUnits" [value]='bu.id'>{{bu.displayName}} - {{bu.description}}</option>
          </select>
        </div>
        <div class="form-group col">
          <ng-multiselect-dropdown
          [placeholder]="'Filter by Group'"
          [settings]="settings"
          [data]="groupsList"
          formControlName="groups"
        >
        </ng-multiselect-dropdown>
        </div>
        <div class="form-group col-12 col-md-3">
          <input type="text" class="form-control" formControlName="userId"
          placeholder="Filter by User Id" title="Search by User Id">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <div class="datePickerBlock">
            <input type="text" class="col-10 form-control" formControlName="fromDate" placeholder="From Date"
            [matDatepicker]="fromDatePicker" readonly>
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker" class="datePickericon text-light"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker class="text-light"></mat-datepicker>
          </div>
        </div>
  
        <div class="form-group col">
          <div class="datePickerBlock">
            <input type="text" class="col-10 form-control" formControlName="toDate" placeholder="To Date"
            [matDatepicker]="toDatePicker" readonly>
            <mat-datepicker-toggle matSuffix [for]="toDatePicker" class="datePickericon text-light"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker class="text-light"></mat-datepicker>
          </div>
        </div>
  
        <div class="form-group col">
          <select formControlName="StatusIDFilter" class="form-control">
            <option value='' disabled>Filter By Status</option>
            <option *ngFor="let status of Status" [value]='status.statusID'>{{status.status}}</option>
          </select>
        </div>
  
        <div  class="form-group col">
          <select formControlName="buFilter" class="form-control">
            <option value='' disabled>Filter By Business Unit</option>
            <option value='' selected>All</option>
            <option *ngFor="let bu of businessUnits" [value]='bu.id'>{{bu.displayName}} - {{bu.description}}</option>
          </select>
        </div>
        <div  class="form-group col">
          <select formControlName="categoryFilter" class="form-control">
            <option value='' disabled>Filter By Categories</option>
            <option *ngFor="let category of categories" [value]='category.categoryID'>{{category.category}}</option>
          </select>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-Action small mr-2" (click)="dashboardFilter()">Filter</button>
        <button class="btn btn-Action small" (click)="clearFilter()">Reset Filter</button>
      </div>
  </form>
    
  <hr>

    <table class="table tabDesign table-hover table-striped table-responsive-sm table-responsive-md mt-2 bg-light">
      <tr class="col-sm-12 col-md-12">
        <th class="mx-1 tabhead">Transaction ID</th>
        <th class="mx-1 tabhead">Requested Date Time</th>
        <th class="mx-1 tabhead">Workflow Configuration</th>
        <th class="mx-1 tabhead">Nature of Transaction</th>
        <th class="mx-1 tabhead">Business Unit</th>
        <th class="mx-1 tabhead">Category</th>
        <th class="mx-1 tabhead">Estimated Finance Amount</th>
        <th class="mx-1 tabhead">Status</th>
        <th class="mx-1 tabhead">Action</th>
      </tr>
      <tbody class="tabBody">
        <tr *ngFor="let transaction of transactions | paginate: { itemsPerPage: resultPerpage.value, currentPage: currentPage,totalItems: totalCount };let i=index">
          <td class=" mx-1 tabData text-center"><a title="Click here to view details"
              [routerLink]="['/transactions-detail', transaction.id,'Dashboard']">{{transaction.id}}</a></td>
          <td class=" mx-1 tabData text-center">{{authService.formatAMPM(transaction.CreatedDate)}}</td>
          <td class=" mx-1 tabData">{{transaction.ActivityConfigTitle}}</td>
          <td class=" mx-1 tabData">{{transaction.Title}}</td>
          <td class=" mx-1 tabData ">
            {{transaction.BusinessUnit}}
          </td>
          <td class=" mx-1 tabData ">
            {{transaction.Category}}
          </td>
          <td class=" mx-1 tabData text-right">
            <span *ngIf="transaction.financeAmount!=0">${{transaction.FinanceAmount | thousandSeparator}}</span>
            <div class="text-center" *ngIf="transaction.financeAmount==0">&ndash;</div>
          </td>
          <td class=" mx-1 tabData text-center" [ngClass]="{
                'AwaitingDecision': transaction.Status == 'Awaiting Approval',
                'accepted': transaction.Status == 'Approved',
                  'rejected': transaction.Status == 'Rejected',
                  'onHold': transaction.Status == 'On Hold' || transaction.status == 'OnHold',
                  'sentBack': transaction.Status == 'Sent Back To Previous Level',
                  'SentBackToInitiator': transaction.Status == 'Sent Back To Initiator',
                  'cancelled': transaction.Status == 'Cancelled'}">{{transaction.Status}}</td>
          <td class=" mx-1 tabData text-center">
            <i *ngIf = "transaction.Status == 'Approved' && (screenAccessService.screenAccess.isAdmin || screenAccessService.screenAccess.readOnlyAccessForDashboard)" class="fa fa-file-pdf-o btn btn-pdf" (click)="openDocument(transaction)" title="Download PDF"></i>
            <button *ngIf = "transaction.Status == 'Approved' && (screenAccessService.screenAccess.isAdmin || screenAccessService.screenAccess.readOnlyAccessForDashboard)" class="gnrt-btn"  (click)="generatePDF(transaction.id)" title="Generate PDF">Generate</button>
                
            
            <i *ngIf="transaction.Status == 'Awaiting Approval' && screenAccessService.screenAccess.isAdmin" class="fa fa-times btn btn-cancel m-2"
              (click)="cancelTransaction(transaction.id)" title="Cancel"></i>
           
          </td>
        </tr>
        <tr *ngIf="transactions.length ==0 ">
          <td class=" mx-1 tabData" colspan="8">No record found</td>
        </tr>
      </tbody>
    </table>
    <div class="display-flex justify-content-end" *ngIf="totalCount>0">
      <pagination-controls class="my-pagination"
      previousLabel=""
      nextLabel=""
      (pageChange)="currentPage = $event;onPageChange($event)"></pagination-controls>
      <div class="bg-white px-2 display-flex align-items-center" >
        <span>
          Results per page
        </span>
        <span class="px-2">
          <select  id="resultPerpage" [formControl]="resultPerpage">
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
        </span>
      </div>
    </div>
  </div>
 
</div>
