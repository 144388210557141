import {RowRange} from './RowRange';

export class Pagination{
  currentPage = 1;
  maxPageVisible = 5;
  totalPage:number = 1;
  totalRecords = 0;
  rowRange: RowRange = new RowRange(0, 10, 1);
  paginationPageIndexes: Array<any> = new Array<any>();

  constructor(){
    this.Reset();
  }

  CalculateRowRange(){
    if (this.currentPage === 1)
    {
      this.rowRange.firstResult = 0;
      this.rowRange.pageIndex=this.currentPage
    }
    else {
      this.rowRange.firstResult = (this.currentPage - 1) * this.rowRange.maxResult;
      //this.rowRange.firstResult=this.currentPage;
      this.rowRange.pageIndex=this.currentPage;
    }
  }

  CalculatePaginationPages()
  {
    this.totalPage = Math.ceil(this.totalRecords / this.rowRange.maxResult);
    this.paginationPageIndexes =Array.apply(null,Array(this.totalPage)).map(Number.call,Number)
      
  }

  Reset(){
    this.currentPage = 1;
    this.totalPage = 1;
    this.totalRecords = 0;
    this.rowRange = new RowRange(0, 10,1);
  }

}
