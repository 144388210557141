import {Injectable} from '@angular/core';
import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import {TokenStorage} from './token-storage';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorage, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    // set X-Requested-With = XMLHttpRequest
    req.headers.set('X-Requested-With', 'XMLHttpRequest');  
    //console.log(req.url)
    if (this.token.MSALget() && !req.url.includes('https://graph.microsoft.com/v1.0')) {
      const authReq = req.clone({headers:  req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.token.MSALget())});
      return next.handle(authReq);
    }

    return next.handle(req);
  }
}
