import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './component/loader/loader.component';
import { LoginComponent } from './component/auth/login/login.component';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogLevel } from 'msal';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import { MenuComponent } from './component/menu/menu.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { TransactionsComponent } from './component/transactions/transactions.component';
import { AuthGuard } from './core/auth-guard';
import { AuthService } from './core/auth.service';
import { TokenStorage } from './core/token-storage';
import { RestService } from './service/rest.service';
import { TokenInterceptor } from './core/token-interceptor';
import { AuthInterceptor } from './core/auth-interceptor';
import { HomeComponent } from './component/home/home.component';
import { ConfigurationSettingsComponent } from './component/configuration-settings/configuration-settings.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { ManageConfigurationSettingsComponent } from './component/manage-configuration-settings/manage-configuration-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';


import { YesNoAlertDialogComponent } from './component/Shared/yes-no-alert-dialog/yes-no-alert-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertDialogComponent } from './component/Shared/alert-dialog/alert-dialog.component';
import { ApprovalsComponent } from './component/approvals/approvals.component';
import {MatRadioModule} from '@angular/material/radio';
import { CommonModule, DatePipe } from '@angular/common';
import { ManageTransactionsComponent } from './component/manage-transactions/manage-transactions.component';
import { TransactionDetailComponent } from './component/transaction-detail/transaction-detail.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AwitingApprovalDialogComponent } from './component/Shared/awiting-approval-dialog/awiting-approval-dialog.component';
import { DateFilterPipe } from './component/pipes/dateFormat.pipe';
import { OrderByPipe } from './component/pipes/orderByPipe';
import { environment } from 'src/environments/environment';
import { WorkflowHistoryComponent } from './component/workflow-history/workflow-history.component';
import { ThousandSeparatorPipe } from './component/pipes/thousandSeparator.pipe';
import { ScheduleComponent } from './schedule/schedule.component';
import { ApprovalHistoryComponent } from './component/approval-history/approval-history.component';
import { SendBackInitiatorEditComponent } from './send-back-initiator-edit/send-back-initiator-edit.component';
import { SearchPipe } from './component/pipes/search.pipe';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxPaginationModule } from 'ngx-pagination';
import { ListOfDelegateUsersComponent } from './component/Shared/list-of-delegate-users/list-of-delegate-users.component';
import { DocViewerComponent } from './component/doc-viewer/doc-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'e06ee7d1-89ba-4650-b0f6-3ff155dd6f92', // e06ee7d1-89ba-4650-b0f6-3ff155dd6f92
      authority: 'https://login.microsoftonline.com/046e8e41-64c2-4cfa-a654-2d7179a40f20', // 046e8e41-64c2-4cfa-a654-2d7179a40f20
      redirectUri: environment.loginRedirect,
     // scope: 'api://e06ee7d1-89ba-4650-b0f6-3ff155dd6f92/access_as_user'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['api://e06ee7d1-89ba-4650-b0f6-3ff155dd6f92/access_as_user', "openid", "profile",'user.read']
    }
  };
}



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoaderComponent,
    MenuComponent,
    DashboardComponent,
    TransactionsComponent,
    HomeComponent,
    ConfigurationSettingsComponent,
    PaginationComponent,
    ManageConfigurationSettingsComponent,
    YesNoAlertDialogComponent,
    AlertDialogComponent,
    ApprovalsComponent,
    ManageTransactionsComponent,
    TransactionDetailComponent,
    AwitingApprovalDialogComponent,
    DateFilterPipe,
    ThousandSeparatorPipe,
    OrderByPipe,
    SearchPipe,
    WorkflowHistoryComponent,
    ScheduleComponent,
    ApprovalHistoryComponent,
    SendBackInitiatorEditComponent,
    ListOfDelegateUsersComponent,
    DocViewerComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    FormsModule ,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    AutocompleteLibModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxPaginationModule,
    CommonModule,
    NgxDropzoneModule,
   MatSlideToggleModule,
   NgxDocViewerModule

  ],
  providers: [
    AuthGuard,
    AuthService,
    TokenStorage,
    RestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DateFilterPipe,
    DatePipe,
    ThousandSeparatorPipe,
    OrderByPipe,
    SearchPipe,
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  entryComponents:[
    YesNoAlertDialogComponent,
    TransactionDetailComponent,
    AwitingApprovalDialogComponent,
    WorkflowHistoryComponent,
    MenuComponent,
    SendBackInitiatorEditComponent,
    TransactionsComponent
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
