import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-alert-dialog',
  templateUrl: './yes-no-alert-dialog.component.html',
  styleUrls: ['./yes-no-alert-dialog.component.css']
})
export class YesNoAlertDialogComponent implements OnInit {

  //@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<YesNoAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: String
  ) {}
  ngOnInit() {
  }

  /*Ok to proceed with the action*/
  acceptError(): void {
    this.dialogRef.close(true);
    }

  /* Decline the action */
  decline(): void {
    this.dialogRef.close(false);
  }

}
