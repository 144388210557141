<div class="dialog-body" *ngIf="data.type=='WARNING'">
    <div class="dialog-header text-center" mat-dialog-title ><i class="fa fa-exclamation-triangle text-warning pt-2"></i></div>
    <div class="dialog-content p-2">{{data.data}}</div>
    <div class="dialogFooter text-right">
        <button class="mx-1 btn btn-Action" type="button" (click)="accept()">Ok</button>
    </div>
</div>
<div class="dialog-body" *ngIf="data.type=='SUCCESS'">
    <div class="dialog-header text-center" mat-dialog-title ><i class="fa fa-info-circle text-warning pt-2"></i></div>
    <div class="dialog-content p-2">{{data.data}}</div>
    <div class="dialogFooter text-right">
        <button class="mx-1 btn btn-Action" type="button" (click)="accept()">Ok</button>
    </div>
</div>
