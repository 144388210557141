import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { some } from 'lodash-es';
import { AuthService } from '../core/auth.service';
import { TokenStorage } from '../core/token-storage';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenAccessService {
  groups: any;
  screenAccess:any={
    isAdmin:false,
    readOnlyAccessForDashboard:false,
    readOnlyAccessForWorkflow:false
  }
  constructor(
    private authservice: AuthService,
    private token: TokenStorage,
    private restservice: RestService,
    private router: Router,
    ) { 
      this.checkAccessForScreens();
    }

  checkAccessForScreens(){
    this.authservice.getMembers().subscribe((data: any) => {
      this.groups = data.value;
      this.restservice.postData(null, '/api/ActivityConfig/GetMasterData')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            let getMasterData=data.rObj.GetMasterData;
            if(getMasterData && getMasterData?.length>0){
              for (let group of this.groups){
                  if(some(getMasterData, { 'DocType': 'WorkflowAdmin', 'GroupID':group})){
                    this.screenAccess.isAdmin = true;
                    break;
                }
              }
              for (let group of this.groups){
                  if(some(getMasterData, { 'DocType': 'Dashboard', 'GroupID':group})){
                    this.screenAccess.readOnlyAccessForDashboard=true;
                    break;
                }
              }
              for (let group of this.groups){
                  if(some(getMasterData, { 'DocType': 'WorkflowConfiguration', 'GroupID':group})){
                    this.screenAccess.readOnlyAccessForWorkflow=true;
                    break;
                }
              }
              // if(this.screenAccess.readOnlyAccessForDashboard){
              //   this.router.navigate(['/dashboard'])
              // }
            }
          }
        },
        (err) => {
          console.log(err);
          if (err.status === 401 || err.status === 403 || err.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        }
      );
    }, errVal => {
      if (errVal.status == 401) {
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.token.MSALget()
        });
        this.restservice.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
          this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
          this.authservice.getMembers().subscribe((dataVal: any) => {
            this.groups = dataVal.value;
            this.restservice.postData(null, '/api/ActivityConfig/GetMasterData')
            .subscribe(
              (data) => {
                if (data.rcode == 1) {
                  let getMasterData=data.rObj.GetMasterData;
                  if(getMasterData && getMasterData?.length>0){
                    for (let group of this.groups){
                      if(some(getMasterData, { 'DocType': 'WorkflowAdmin', 'GroupID':group})){
                        this.screenAccess.isAdmin = true;
                        break;
                    }
                  }
                    for (let group of this.groups){
                        if(some(getMasterData, { 'DocType': 'Dashboard', 'GroupID':group})){
                          this.screenAccess.readOnlyAccessForDashboard=true;
                          break;
                      }
                    }
                    for (let group of this.groups){
                        if(some(getMasterData, { 'DocType': 'WorkflowConfiguration', 'GroupID':group})){
                          this.screenAccess.readOnlyAccessForWorkflow=true;
                          break;
                      }
                    }
                  }
                }
              },
              (err) => {
                console.log(err);
                if (err.status === 401 || err.status === 403 || err.status === 0) {
                  localStorage.clear();
                  this.router.navigate([''])
                    .then(() => {
                      window.location.reload();
                    });
                }
              }
            );
          })
        }, (err) => {
          console.log(err);
          if (err.status === 401 || err.status === 403 || err.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        });
      }
    })
  }
}
