<!-- <div class="col-sm-12 col-md-12 ">
    <div class="col-sm-12 col-md-12 my-3 content-align">
        <div class="card">
            <div class="card-header row mx-0">
                <div class=" text-left col-md-6">
                    <h2 class="m-0">Edit Transaction</h2>
                </div>
                <div class=" text-right col-md-6">
                    <button class="btn btn-Back" (click)="closeDialog()">Back</button>
                 </div>
            </div>
            <div class="card-body">
                
            </div>

        </div>
    </div>

</div> -->


<div class="mat-dialog-title mb-0">
    <div class="d-flex justify-content-between align-items-center">
        <h2 class="m-0">Edit Transaction</h2>
        <span class="c-mat-dialog-closebtn pointer">
            <button class="btn btn-Back" (click)="closeDialog()">Back</button>
        </span>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="setBorder p-1 px-3">
        <form [formGroup]="transactionForm">
            <div class="col-sm-12 col-md-12 my-3 row">
                <label class="col-sm-12 col-md-2">Workflow<span class="text-danger">*</span></label>
                <div class="col-sm-12 col-md-10">
                   
                 <select class="form-control" name="category" formControlName="type"
                        [ngClass]="{ 'is-invalid': submitted && f.type.errors }" (change) = "selectCategory(f.type.value)" readOnly>
                        <option value ="" disabled>Please select the Workflow</option>

                        <option *ngFor = "let category of transactionTypes |orderBy :'Title'" [value] = "category.id">{{category.Title}}</option>
                    </select> 
                    <div *ngIf="submitted && f.type.errors" class="invalid-feedback text-danger errorMsg"
                        [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                        <div *ngIf="f.type.errors.required">Transaction Category is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 mt-3 row">
                <label class="col-sm-12 col-md-2">Title<span class="text-danger">*</span></label>
                <div class="col-sm-12 col-md-10 form-group">
                    <input type="text" class="form-control" name="title" formControlName="title"
                        [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Enter Title"
                        autocomplete="off">
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback text-danger errorMsg">
                        <div *ngIf="f.title.errors.required">Title is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 mb-3 row">
                <label class="col-sm-12 col-md-2">Description<span class="text-danger">*</span></label>
                <div class="col-sm-12 col-md-10">
                    <textarea cols="6" rows="5" class="form-control" name="description"
                        formControlName="description"
                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                        placeholder="Enter Description"></textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback text-danger errorMsg"
                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
                        <div *ngIf="f.description.errors.required">Description is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 my-3 row" *ngIf = "isCapex">
                <label for="capex" class="col-sm-12 col-md-2">CAPEX <span class="text-danger">*</span></label>
                <div class="col-sm-12 col-md-5">

                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input type="text" class="form-control" name="capex" formControlName="capex" autocomplete="off"  (keypress) = "decimalOnly($event,f.capex.value)">
                        </div>
                        <div  *ngIf = "f.capex.errors?.capexEstimatedAmtErr && f.capex.value != ''" class = "text-danger">Entered Financial Amount should be between {{lowestEstimatedAmt}} and {{highestEstimatedAmt}}</div>
                        <div class="col-sm-12 col-md-12 row" *ngIf = "(submitted && f.capex.value == '') && isCapex">
                            <div class="col-sm-12 col-md-2"></div>
                            <div  *ngIf = "submitted && f.capex.value == ''" class = "text-danger">Capex is required</div>
                        </div>
                </div>
            </div>
            
           
      
            <!-- <div class="col-sm-12 col-md-12 my-3 row">
                <label for="opex" class="col-sm-12 col-md-2">Category <span class="text-danger">*</span></label>
                <div class="col-sm-12 col-md-5">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input type="text" class="form-control" name="opex" formControlName="category" autocomplete="off" (keypress) = "decimalOnly($event,f.opex.value)">
                    </div>

                </div>
            </div> -->

            <div class="col-sm-12 col-md-12 my-3 row" *ngIf = "isOpex">
                <label for="opex" class="col-sm-12 col-md-2">OPEX <span class="text-danger">*</span></label>
                <div class="col-sm-12 col-md-5">
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input type="text" class="form-control" name="opex" formControlName="opex" autocomplete="off" (keypress) = "decimalOnly($event,f.opex.value)">
                    </div>
                    <div  *ngIf = "f.opex.errors?.opexEstimatedAmtErr && f.opex.value != ''" class = "text-danger">Entered Financial Amount should be between {{lowestEstimatedAmt}} and {{highestEstimatedAmt}}</div>
                    <div class="col-sm-12 col-md-12  row" *ngIf = "(submitted && f.opex.value == '') && isOpex">
                        <div class="col-sm-12 col-md-2"></div>
                        <div *ngIf = "submitted && f.opex.value == ''" class = "text-danger">OPEX is required</div>
                    </div>
                </div>
            </div>


          

            <div class="col-sm-12 col-md-12 my-3 row" *ngIf = "isOtherFinancial">
                <label for="opex" class="col-sm-12 col-md-2">Other Financial <span class="text-danger">*</span></label>
                <div class="col-sm-12 col-md-5">
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input type="text" class="form-control" name="otherFinancial" formControlName="OtherFinancial" autocomplete="off" (keypress) = "decimalOnly($event,f.OtherFinancial.value)">
                    </div>
                    <div  *ngIf = "f.OtherFinancial.errors?.OtherFinancialEstimatedAmtErr && f.OtherFinancial.value != ''" class = "text-danger">Entered Financial Amount should be between {{lowestEstimatedAmt}} and {{highestEstimatedAmt}} </div>
                    <div class="col-sm-12 col-md-12 row" *ngIf = "(submitted && f.OtherFinancial.value == '') && isOtherFinancial">
                        <div *ngIf = "submitted && f.OtherFinancial.value == ''" class = "text-danger">Other Financial  is required</div>
                    </div>
                </div>
            </div>


            


            <div class="col-sm-12 col-md-12 my-3 row">
                <label class="col-sm-12 col-md-2" for = "doc_upload">Documents</label>
                <div class="col-sm-12 col-md-5" name="doc_upload">
                    <div class="custom-dropzone" ngx-dropzone [accept]="'.pdf, .docx, .doc, .xlsx, .xls, .pptx, .ppt, .jpeg, .jpg, .png, .gif'" (change)="onSelectFile($event)">
                        <ngx-dropzone-label>
                            <div>
                                <div class="dropZoneContent">
                                    <img src="/assets/images/drop-down-icon.svg" alt="" >
                                    <div class="dragHint">Drag and drop your files</div>
                                </div>
                                <button type="button" class="btn btn-Action">Upload</button>
                            </div>
                        </ngx-dropzone-label>
                    </div>
                </div>
                 <!-- {{requiredDocument}} -->
                <!--{{requiredUrl}} -->
                <div class = "col-sm-12 col-md-5" *ngIf = "transactionDocument.length > 0">
                    <div class = "document-header h4 font-weight-bold">Documents Attached</div>
                    <div *ngFor="let doc of transactionDocument" class = "col-12">
                        <span>{{doc.FileName}}</span>
                        <i class="fa fa-times btn btn-default ml-3" style="font-size: 20px;" title="Remove Document" (click)="removeDocument(doc)"></i>
                    </div>
                </div>
            </div>
            <div class="text-center my-3">
                <!-- <button mat-raised-button color="warn" type="submit" class="button col-md-2 m-2" (click)="back()">Back</button> -->
                <button  type="submit" class="btn btn-Action col-md-2 m-2"
                    (click)="closeDialog()">Cancel</button>
                <button  type="submit" class="btn btn-Action col-md-3 pr-0 pl-0 m-2"
                    (click)="updateSendBack()">Update and send for approval</button>
                <div *ngIf = "errorMsg != ''" class = "text-danger">{{errorMsg}}</div>
            </div>
        </form>
    </div>
</mat-dialog-content>