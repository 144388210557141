import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/auth.service';
import { TokenStorage } from './core/token-storage';
import { RestService } from './service/rest.service';
import * as microsoftTeams from "@microsoft/teams-js";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  loginDisplay = false;
 // profile!: ProfileType;
 isIframe = false;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,public authservice:AuthService,
    private msalBroadcastService: MsalBroadcastService,private http: HttpClient,
    private restService: RestService, private token: TokenStorage,
    private router: Router
  ) { }

  ngOnInit(): void {
    //this.login()
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
      const payload = result.payload as AuthenticationResult;
      this.authService.instance.setActiveAccount(payload.account);
      window.localStorage['JanusAuthToken'] = payload.accessToken;
      this.appConfig();
    });
    setTimeout(() => {this.setLoginDisplay()}, 1000);
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  get isDocViewerRoute(): boolean {
    return this.router.url.startsWith('/doc-viewer');
  }
  
  appConfig() {
    /*  let body = new URLSearchParams();
     let getAccessTokeUrl = environment.getAccessTokeUrl
     let clientID = environment.clientId;
     let ADsecretKey = environment.refreshSecretKey;
     body.set('Content-Type', "application/x-www-form-urlencoded");
     body.set("grant_type", "client_credentials");
     body.set("client_id",clientID );
     body.set("scope", "api://e06ee7d1-89ba-4650-b0f6-3ff155dd6f92/access_as_user openid profile user.read");
     body.set("client_secret",ADsecretKey);
 /*     body.set('Content-Type', "application/x-www-form-urlencoded");
     body.set("grant_type", "client_credentials");
     body.set("client_id",clientID );
     body.set("scope", "api://e06ee7d1-89ba-4650-b0f6-3ff155dd6f92/access_as_user openid profile user.read");
     body.set("client_assertion",this.token.MSALget());
     body.set("client_assertion_type", "urn:ietf:params:oauth:client-assertion-type:jwt-bearer");
     const options = {
       headers: new HttpHeaders({ 
       'Content-Type': 'application/x-www-form-urlencoded',
       "Cookie": "fpc=AthXMyGmhy5HovkgBAzdvD3PR1h9AQAAAN1jWdgOAAAA; stsservicecookie=estsfd; x-ms-gateway-slice=estsfd",
       'Access-Control-Allow-Methods':'POST',
       'Access-Control-Allow-Credentials': 'true'
     }),
     };
     this.http.post(getAccessTokeUrl, body.toString(), options)
       .subscribe(data => {
         console.log('do::', data);
         localStorage.setItem('JANUS-AuthToken',JSON.stringify(data));
         //localStorage.setItem('CRAAuthRefreshToken', data.refresh_token);
       }, (err: any) => {
         console.log(err)
       });
   } */
   const headers = new HttpHeaders({ 
    'Authorization' : 'Bearer ' + this.token.MSALget()
   });
   this.restService.postData(null,'/api/Account/GetApplicationToken', headers).subscribe((data) => {
     this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token); 
    // this.router.navigate([this.cookieService.get('path')])
    if(localStorage.getItem('path')){
      this.router.navigate([localStorage.getItem('path')])
    }
   }, (err) => {
     console.log(err);
   });
   setInterval(() => {
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
      const payload = result.payload as AuthenticationResult;
      this.authService.instance.setActiveAccount(payload.account);
      window.localStorage['JanusAuthToken'] = payload.accessToken;
      const headers = new HttpHeaders({ 
        'Authorization' : 'Bearer ' + this.token.MSALget()
       });
       this.restService.postData(null,'/api/Account/GetApplicationToken', headers).subscribe((data) => {
         this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
       }, (err) => {
         console.log(err);
       });
    });
    }, 1000 * 60 * 40)
   }
 

}
