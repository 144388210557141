<div class="col-sm-12 col-md-12 ">
    <div class="col-sm-12 col-md-10 mt-3 content-align">
        <div class="card">
            <div class="card-header display-flex">
                <div class=" text-left col-md-10">
                    <h2 class="m-0">{{mode }} Workflow Configuration</h2>
                </div>
                <div class=" text-right col-md-2">
                    <button class="btn btn-Back" [routerLink]="['/settings']">Back</button>
                </div>
            </div>

            <div class="card-body">
                <form [formGroup]="configurationform">

                    <div class="col-sm-12 col-md-12 mt-3 row">
                        <label class="col-sm-12 col-md-2">Business Unit<span class="text-danger">*</span></label>
                        <div class="col-sm-12 col-md-10">
                            <select class="form-control" name="buUnits" formControlName="buUnits"
                                [ngClass]="{ 'is-invalid': submitted && f.buUnits.errors }"
                                (change)="getAllProposerRoles()">
                                <option value="">Please select</option>
                                <option *ngFor="let bu of businessUnitList |orderBy :'displayName'" [value]="bu.id">
                                    {{bu.displayName}} - {{bu.description}}</option>
                            </select>
                            <div *ngIf="submitted && f.buUnits.errors" class="invalid-feedback text-danger errorMsg"
                                [ngClass]="{ 'is-invalid': submitted && f.buUnits.errors }">
                                <div *ngIf="f.buUnits.errors.required">Business unit is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 mt-3 row">
                        <label class="col-sm-12 col-md-2">Title<span class="text-danger">*</span></label>
                        <div class="col-sm-12 col-md-10 form-group">
                            <input type="text" class="form-control" name="title" formControlName="title"
                                [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Enter Title"
                                autocomplete="off">
                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback text-danger errorMsg">
                                <div *ngIf="f.title.errors.required">Title is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 mt-3 row">
                        <label class="col-sm-12 col-md-2">Description<span class="text-danger">*</span></label>
                        <div class="col-sm-12 col-md-10">
                            <textarea cols="6" rows="5" class="form-control" name="description"
                                formControlName="description"
                                [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                                placeholder="Enter Description"></textarea>
                            <div *ngIf="submitted && f.description.errors" class="invalid-feedback text-danger errorMsg"
                                [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
                                <div *ngIf="f.description.errors.required">Description is required</div>
                            </div>
                        </div>
                    </div>


                    <div class="col-sm-12 col-md-12 mt-3 row">
                        <label for="tag" class="col-sm-12 col-md-2 ">Category</label>
                        <div class="col-sm-12 col-md-8">
                            <mat-radio-group formControlName="IsCapex" fxLayout="column" fxLayoutGap=".25rem">
                                <mat-radio-button value="1" id="Capex" name="IsCapex"> Capex</mat-radio-button> &nbsp;
                                <mat-radio-button value="2" id="Opex" name="IsCapex"> Opex </mat-radio-button> &nbsp;
                                <mat-radio-button value="4" id="OtherFinancial" name="OtherFinancial">Other Financial
                                </mat-radio-button> &nbsp;
                                <mat-radio-button value="3" id="nonFinancial" name="IsCapex" (change)="setNonFinance()">
                                    Non Financial </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 mt-3 row">
                        <label for="tag" class="col-sm-12 col-md-2 ">Proposer Roles<span
                                class="text-danger">*</span></label>
                        <div class="col-sm-12 col-md-10">
                            <ng-multiselect-dropdown #multiSelect [placeholder]="'Select Group'"
                                [data]="groupsList |orderBy :'fullName'" formControlName="groups" [settings]="settings"
                                [disabled]="mode=='View'" (onFilterChange)="onFilterChangePro($event)"
                                (onDropDownClose)="onDropDownClose($event)" (onSelect)="onItemSelectPro($event)"
                                (onDeSelect)="onDeSelectPro($event)" (onSelectAll)="onSelectAllPro($event)"
                                (onDeSelectAll)="onDeSelectAllPro($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.groups.errors }">
                            </ng-multiselect-dropdown>

                            <div *ngIf="submitted && f.groups.errors" class="invalid-feedback text-danger errorMsg"
                                [ngClass]="{ 'is-invalid': submitted && f.groups.errors }">
                                <div *ngIf="f.groups.errors.required">Proposer role is required</div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div formArrayName="items">
                            <div style="border: 2px solid #1b1a42;padding: 5px; margin: 5px;" class="pb-4"
                                *ngFor="let level of approvalLevelForm(configurationform); let i = index">

                                <div class="ml-2 text-right" *ngIf="i>0">
                                    <i class="fa fa-times btn btn-default" style="font-size: 20px;"
                                        title="Remove Level {{i+1}}" (click)="removeApprovalLevel(i)"></i>
                                </div>

                                <h2 class="text-center"> Level {{i+1}}</h2>
                                <div class="col-sm-12 col-md-12 mt-3 row" [formGroupName]="i">
                                    <label for="tag" class="col-sm-12 col-md-2 ">Approval Roles<span
                                            class="text-danger">*</span></label>
                                    <div class="col-sm-12 col-md-10 pl-0">
                                        <ng-multiselect-dropdown #multiSelect [placeholder]="'Select Group'"
                                            [settings]="settingsApp" [data]="ApprovalGroupsList |orderBy :'displayName'"
                                            formControlName="approvalLevel" [disabled]="mode=='View'"
                                            (onFilterChange)="onFilterChangeApp($event)"
                                            (onDropDownClose)="onDropDownCloseApp($event)"
                                            (onSelect)="onItemSelectApp($event,i+1)"
                                            (onDeSelect)="onDeSelectApp($event,i+1)"
                                            (onSelectAll)="onSelectAllApp($event)"
                                            (onDeSelectAll)="onDeSelectAllApp($event)"
                                            [ngClass]="{ 'is-invalid': submitted && level.controls.approvalLevel.errors }">
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="submitted && level.controls.approvalLevel.errors"
                                            class="invalid-feedback text-danger errorMsg"
                                            [ngClass]="{ 'is-invalid': submitted && level.controls.approvalLevel.errors }">
                                            <div *ngIf="level.controls.approvalLevel.errors.required">Approval group is
                                                required</div>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 mt-3 display-flex" [formGroupName]="i"
                                    *ngIf="f.IsCapex.value!='3'">
                                    <div class="col-sm-12 col-md-12 row pl-0">
                                        <label class="col-sm-12 col-md-2 pl-3">Financial Limit<span
                                                class="text-danger">*</span></label>
                                        <div class="col-sm-12 col-md-5 form-group mb-0 pl-0">
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input type="text" class="form-control col-md-12" name="lowerLimit"
                                                    formControlName="lowerLimit" placeholder="Enter Lower Limit"
                                                    (change)="validateLimit(i, $event, 'lowerLimit')"
                                                    (keypress)="numberOnly($event)" autocomplete="off">
                                            </div>
                                            <div *ngIf="submitted && level.controls.lowerLimit.errors"
                                                class="text-danger errorMsg"
                                                [ngClass]="{ 'is-invalid': submitted && level.controls.lowerLimit.errors }">
                                                <div *ngIf="level.controls.lowerLimit.errors.required">Lower limit is
                                                    required</div>
                                            </div>
                                            <div>

                                                <div class="text-danger"
                                                    *ngIf="level?.controls?.lowerLimit?.errors?.lowerLimitError">Value
                                                    should be either equal to previous lower limit or greater than
                                                    previous upper limit</div>
                                                <div class="text-danger"
                                                    *ngIf="level?.controls?.lowerLimit?.errors?.exceededLevel">You have
                                                    exceeded the max level of limit, please enter other limit range to
                                                    proceed</div>
                                                <div class="text-danger"
                                                    *ngIf="level?.controls?.lowerLimit?.errors?.lowerLimitRequired">
                                                    Financial Lower Limit is required</div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-5 form-group mb-0 pr-0 pl-0">
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input type="text" class="form-control" name="upperLimit"
                                                    formControlName="upperLimit" placeholder="Enter Upper Limit"
                                                    (change)="validateLimit(i, $event, 'upperLimit')"
                                                    (keypress)="numberOnly($event)" autocomplete="off">

                                            </div>
                                            <div *ngIf="submitted && level.controls.upperLimit.errors"
                                                class="text-danger errorMsg"
                                                [ngClass]="{ 'is-invalid': submitted && level.controls.upperLimit.errors }">
                                                <div *ngIf="level.controls.upperLimit.errors.required">Upper limit is
                                                    required</div>
                                            </div>
                                            <div>
                                                <div class="text-danger"
                                                    *ngIf="level?.controls?.upperLimit?.errors?.upperLimitError">Value
                                                    should be greater than lower limit</div>
                                                <div class="text-danger"
                                                    *ngIf="level?.controls?.upperLimit?.errors?.previousupperLimitError">
                                                    Value should be equal to previous upperlimit</div>
                                                <div class="text-danger"
                                                    *ngIf="level?.controls?.upperLimit?.errors?.UpperLimitRequired">
                                                    Financial Upper Limit is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 mt-3 display-flex" [formGroupName]="i">
                                    <div class="col-sm-12 col-md-12 row pl-0">
                                        <label class="col-sm-12 col-md-2 pl-3">SLA Unit</label>
                                        <div class="col-sm-12 col-md-5 mb-0 pl-0">
                                            <select class="form-control" name="slaUnit" formControlName="slaUnit">
                                                <option value="" selected disabled>Please select</option>
                                                <option value="Day">Day</option>
                                                <option value="Hour">Hour</option>
                                            </select>

                                        </div>
                                        <div class="col-sm-12 col-md-5 form-group mb-0 pr-0 pl-0">
                                            <input type="text" class="form-control" name="slaValue"
                                                (keypress)="numberOnly($event)" formControlName="slaValue"
                                                placeholder="Enter SLA Value" autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 mt-3 display-flex" [formGroupName]="i">
                                </div>
                            </div>

                            <div class="text-right flt-rht mt-2">
                                <button class="cursor-pointer btn btn-Action" [disabled]="isValidLevel"
                                    *ngIf="mode!='View' && ApprovalGroupsList.length != 0"
                                    (click)="addApprovalLevelForm()">Add another level</button>
                                <span class="text-primary small"
                                    *ngIf="mode!='View' && ApprovalGroupsList.length == 0 && ApprovalGroups.length > 0">No
                                    More approval groups are availabe to add</span>
                            </div>
                        </div>
                    </div>

                    <div class="text-center mt-5">
                        <!-- <button mat-raised-button color="warn" type="submit" class="button col-md-2 m-2" (click)="back()">Back</button> -->
                        <button type="submit" class="btn btn-Action col-md-2 m-2" (click)="cancel()"
                            *ngIf="mode!='View'">CANCEL</button>
                        <button type="submit" class="btn btn-Action col-md-2 m-2" (click)="submitConfiguration()"
                            *ngIf="mode!='View' && mode!='Edit'">SUBMIT</button>
                        <button type="submit" class="btn btn-Action col-md-2 m-2" (click)="updateConfiguration()"
                            *ngIf="mode=='Edit'">Update</button>

                    </div>
                </form>
            </div>

        </div>
    </div>

</div>