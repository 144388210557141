<!-- <div class="tab">
    <button  class="tablinks" (click)="getAllApprovalspage()">My Approvals</button> 
    <button   class="tablinks" (click)="approvalHistory()"> Approvals History</button>
  </div>    -->
  

  <div class="col-md-12 bgImage" >
    <div class="col-md-12 text-center text-light">
      <h2>Approvals History</h2>
    </div>
    <form [formGroup]="ApprovalsHistoryPageFilter">
      <div  class="col-md-12 col-sm-12 display-flex p-0 text-light " >
        <div class="col-md-3 pl-sm-0 form-group">
          <input type = "text" class = "form-control col-12" formControlName= "searchInput" placeholder="Search Text...">
        </div>

        <div class="col-md-3 form-group">
          <select formControlName= "categoryFilter" class = "form-control col-md-12">
            <option value = '' disabled selected>Filter By Categories</option>
            <option *ngFor = "let category of categories" [value] = 'category.categoryID'>{{category.category}}</option>
         </select>
        </div>
    
       <div class="col-md-3 form-group">
        <select formControlName= "buFilter" class = "form-control col-md-12">
          <option value = '' disabled selected>Filter By Business Unit</option>
          <option value = '' selected>All</option>
          <option *ngFor = "let bu of businessUnits" [value] = 'bu.id'>{{bu.displayName}} - {{bu.description}}</option>
        </select>
       </div>
       
    <div class="col-md-2 form-group">
      <select formControlName="StatusIDFilter" class = "form-control col-md-12">
        <option value = '' disabled selected>Filter By Status</option>
        <option *ngFor = "let status of Status" [value]='status.StatusID'>{{status.status}}</option>
    </select>
    </div>
       
    <div class="col-md-1 text-right p-0 " id=#clearfilter>
      <button class="btn btn-Action small" (click)="clearFilter()">Reset Filter</button>
    </div>

     
      </div>
    </form>
      <!-- <i class="fa fa-info-circle white" aria-hidden="true" (mouseover)="changeText=true" (mouseout)="changeText=false">
        <span *ngIf="!changeText"></span>
        <span class="text" *ngIf="changeText">You can type either  'Transaction ID'  or  'Nature of Transaction'</span>
      </i> -->
     
  
     <div class="tab-content"> 
        <table class = "table tabDesign table-hover table-striped table-responsive-sm table-responsive-md mt-2 bg-light" id="approvalhistory">
            <tr class = "col-sm-12 col-md-12">
              <th  class="mx-1 tabhead">Transaction ID</th>
              <th  class="mx-1 tabhead">Initiator</th>
              <th  class="mx-1 tabhead">Initiated Date Time</th>
              <th  class="mx-1 tabhead">Workflow Configuration</th>
              <th  class="mx-1 tabhead">Nature of Transaction</th>
              <th  class="mx-1 tabhead">Business Unit</th>
              <th  class="mx-1 tabhead">Category</th>
              <th  class="mx-1 tabhead">Estimated Finance Amount</th>
              <th  class="mx-1 tabhead">Status</th> 
              <!-- <th  class="mx-1 tabhead">History</th> -->
              <!-- <th colspan="4" class="mx-1 tabhead">Action</th> -->
      
            </tr>
          <tbody class="tabBody" >
              <tr *ngFor="let app of myApprovalHistoryList| paginate: { itemsPerPage: resultPerpage.value, currentPage: currentPage,totalItems: totalCount };let i=index"> 
                <td class=" mx-1 tabData pointer"><a (click)="viewDetails(app)" title = "Click here to view details"><u class = "text-primary">{{app.id}}</u></a></td>   
                <td class=" mx-1 tabData pointer"><a >{{app.CreatedUser}}</a></td>   
                <td class=" mx-1 tabData text-center">{{ authservice.formatAMPM(app.CreatedDate)}}</td>
                <td class=" mx-1 tabData">{{app.ActivityConfigTitle}}</td>
                <td class=" mx-1 tabData textBreak">{{app.Title}}</td>
                <td class=" mx-1 tabData ">
                  {{app.BusinessUnit}}
                </td>
                <td class=" mx-1 tabData ">
                  {{app.Category}}
                </td>
                <td class=" mx-1 tabData text-right">
                  <span *ngIf="app.financeAmount!=0">${{app.FinanceAmount | thousandSeparator}}</span>
                  <div class="text-center" *ngIf="app.financeAmount==0">&ndash;</div>
                </td>
                <td class=" mx-1 tabData text-center" [ngClass]="{
                  'AwaitingDecision': app.StatusID === 1001,
                  'accepted': app.StatusID === 1002,
                    'rejected': app.StatusID === 1003,
                    'onHold': app.StatusID === 1004,
                    'sentBack': app.StatusID === 1005,
                    'cancelled': app.StatusID === 1006}">
                    <span >{{app.Status}}</span>
                </td> 
                <!-- <td class=" mx-1 tabData text-center">
                  <span *ngIf="app.statusID!=1002 && app.statusID!=1003 && app.statusID!=1006" class="icon-25 icon-approve_green mr-1" title="Approve" (click)="approveLevel(app,1002)"></span> 
                    <span  *ngIf="app.statusID==1002 || app.statusID==1003 || app.statusID==1006" class="icon-25 icon-approve_green mr-1 pointer-ban"   title="Approve not allowed" ></span> 
                  </td>
                  <td class=" mx-1 tabData text-center">
                    <span  *ngIf="app.statusID!=1002 && app.statusID!=1003 && app.statusID!=1006" class="icon-25 icon-reject_red mr-1" title="Reject" (click)="approveLevel(app,1003)"></span>
                    <span *ngIf="app.statusID==1002 || app.statusID==1003 || app.statusID==1006"  class="icon-25 icon-reject_red mr-1 pointer-ban" title="Reject not allowed" ></span>
  
                  </td>
                  <td class=" mx-1 tabData text-center">
                    <span *ngIf = "app.statusID!=1002 && app.statusID!=1003 &&  app.statusID!=1006" class="icon-25 icon-hold_orange mr-1"   title="On Hold" (click)="approveLevel(app,1004)"></span>
                    <span *ngIf = "app.statusID ==1002 || app.statusID ==1003 ||  app.statusID==1006" class="icon-25 icon-hold_orange mr-1 pointer-ban"   title="On Hold not allowed"></span>
                  </td>
                  <td class=" mx-1 tabData text-center">
                    <span *ngIf="app.ApprovalLevels.length >= 2 && app.currentLevel > 1 && (app.statusID!=1002 && app.statusID!=1003 && app.statusID!=1006)" class="icon-25 icon-sendback_black mr-1" title="Send Previous Approval" (click)="approveLevel(app,1005)"></span>
                    <span *ngIf="(app.ApprovalLevels.length==1) || (app.statusID==1002 || app.statusID==1003 || app.statusID==1006) || (app.currentLevel == 1)" class="icon-25 icon-sendback_black mr-1  pointer-ban" title="Send Previous Approval not allowed"></span>
                  </td>  -->
              </tr>
              <tr *ngIf="myApprovalHistoryList.length==0">
                  <td class=" mx-1 tabData" colspan="10">no record found</td>
              </tr>
          </tbody>
        </table>
        </div>
        <div class="display-flex justify-content-end" *ngIf="totalCount>0">
          <pagination-controls class="my-pagination"
          previousLabel=""
          nextLabel=""
          (pageChange)="currentPage = $event;onPageChange($event)"></pagination-controls>
          <div class="bg-white px-2 display-flex align-items-center" >
            <span>
              Results per page
            </span>
            <span class="px-2">
              <select  id="resultPerpage" [formControl]="resultPerpage">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
            </span>
          </div>
        </div>
  </div>
