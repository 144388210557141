import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { includes, pull } from 'lodash-es';

@Component({
  selector: 'app-list-of-delegate-users',
  templateUrl: './list-of-delegate-users.component.html',
  styleUrls: ['./list-of-delegate-users.component.css']
})
export class ListOfDelegateUsersComponent implements OnInit {
  logInUser:string="";
  isLogInUserExistIndelegateUsers:boolean=false;
  submitted:boolean=false;
  delegateUsers:any=[];
  delegateUserForm!: FormGroup;
  constructor( public MsalauthService: MsalService,private fb: FormBuilder, public dialogRef: MatDialogRef<ListOfDelegateUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.MsalauthService);
    console.log(this.MsalauthService.instance.getAllAccounts()[0].username?.toString());
    this.logInUser=this.MsalauthService.instance.getAllAccounts()[0].username?.toString();
    this.delegateUsers=this.data.data;
    this.delegateUserForm = this.fb.group({
      delegateUser: ['', Validators.required]
    });
    if(includes(this.delegateUsers, this.logInUser)){
      pull(this.delegateUsers, this.logInUser);
      this.delegateUserForm.addControl('actionBy', this.fb.control('1'));
      this.isLogInUserExistIndelegateUsers=true;
    }
   
  }

  submit() {
    this.submitted=true;
    if(this.isLogInUserExistIndelegateUsers &&  this.delegateUserForm.get('actionBy')?.value=="1"){
      this.delegateUserForm.get('delegateUser')?.clearValidators();
      this.delegateUserForm.get('delegateUser')?.updateValueAndValidity();
    }else{
      this.delegateUserForm.get('delegateUser')?.setValidators(Validators.required);
      this.delegateUserForm.get('delegateUser')?.updateValueAndValidity();
    }
    if(this.delegateUserForm.invalid){
      return;
    }else{
      let info={
        delegateUser:this.isLogInUserExistIndelegateUsers &&  this.delegateUserForm.get('actionBy')?.value=="1" ? this.logInUser :this.delegateUserForm.get('delegateUser')?.value,
        isApprovedAsDelegateUser:true
      }
      this.dialogRef.close(info);

    }
  }

}
