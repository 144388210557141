import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { ScreenAccessService } from 'src/app/service/screen-access.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { AlertDialogComponent } from '../Shared/alert-dialog/alert-dialog.component';
import { YesNoAlertDialogComponent } from '../Shared/yes-no-alert-dialog/yes-no-alert-dialog.component';
import { WorkflowHistoryComponent } from '../workflow-history/workflow-history.component';

@Component({
  selector: 'app-configuration-settings',
  templateUrl: './configuration-settings.component.html',
  styleUrls: ['./configuration-settings.component.css']
})
export class ConfigurationSettingsComponent implements OnInit {
  currentPage: number = 1;
  row_count: number = 0;
  page_count: number = 10;
  totalCount: number=0;
  resultPerpage=new FormControl(10);
  searchInput=new FormControl("");
  // @ViewChild('blPagination', { static: true })paginationComp!: PaginationComponent;
  configActivityList:Array<any>=new Array<any>();
  changeText!: boolean;
  constructor(public screenAccessService: ScreenAccessService,public MsalauthService: MsalService,public authService:AuthService, private restservice:RestService, private loader:LoaderService, private router:Router,private dialog: MatDialog, ) { }

  ngOnInit(): void {
    this.getConfiguration();
    this.searchInput.valueChanges.pipe(
    debounceTime(200),
    distinctUntilChanged()).subscribe(value=>{
      this.row_count= 0;
      this.currentPage=1;
      this.getConfiguration();
    });
    this.resultPerpage.valueChanges.subscribe(value=>{
      this.row_count= 0;
      this.page_count=parseFloat(this.resultPerpage.value);
      this.currentPage=1;
      this.getConfiguration();
    });
  }

  // OnPageChange(event:any) {
  //   this.getConfiguration();
  // }
getConfiguration(){
  this.loader.show();
  // let rowRange =  this.paginationComp.GetRowRange();
  let params={
    SearchText:this.searchInput.value?this.searchInput.value.trim():"",
    PageNo:this.row_count,
    PageSize:this.page_count,
  }
  this.restservice.postData(params ,'/api/ActivityConfig/ActivityConfigFetchList')
  .subscribe(
    (data) => {
      if(data.rcode==1) {
        this.totalCount=data.rObj.Count.Count;
        if(data.rObj.ActivityConfig!=null){
          this.configActivityList=data.rObj.ActivityConfig
          // this.showContainerSearchResultTab(data.rObj.Count.Count);  
        }
        this.loader.hide();
       } 
    },
    (err) => {
      this.loader.hide();
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        localStorage.clear();
        this.router.navigate([''])
        .then(() => {
          window.location.reload();
        });
      }
    }
  );
}

// showContainerSearchResultTab(data:any){
//   if(data) {
//     this.paginationComp.SetTotalRecords(data);
//     this.paginationComp.CalculatePaginationPages();
//   }
// }


editActivity(id:string){
  this.loader.show();
  let params={
    ActivityConfigID:id
  }
  this.restservice.postData(params ,'/api/ActivityConfig/ActivityConfigTransactionFetch')
  .subscribe(
    (data) => {
      if(data.rcode==1) {
        if(data.rObj.Transactions!=null && data.rObj.Transactions.length!=0){
          let _transaction=[]
          for(let i=0;i< data.rObj.Transactions.length;i++){
            _transaction.push(data.rObj.Transactions[i].id)
          }

          const dialogRef = this.dialog.open(YesNoAlertDialogComponent, {
            width: '30vw',
            disableClose: true,
            data:"There is " +data.rObj.Transactions.length+ " transactions for this configuration, do you want to proceed to edit?"
          });
          dialogRef.afterClosed().subscribe(result=>{
            if(result){
              this.router.navigate(['/manage-settings/'+id+'/Edit'])
            }
          });
        }
        else{
          this.router.navigate(['/manage-settings/'+id+'/Edit'])
        }
        this.loader.hide()
       // alert(data)
       } 
    },
    (err) => {
      this.loader.hide();
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        localStorage.clear();
        this.router.navigate([''])
        .then(() => {
          window.location.reload();
        });
      }
    }
  );
}

deleteActivity(id:string){
  this.loader.show();
  let params={
    ActivityConfigID:id
  }
  this.restservice.postData(params ,'/api/ActivityConfig/ActivityConfigTransactionFetch')
  .subscribe(
    (data) => {
      if(data.rcode==1) {
        if(data.rObj.Transactions!=null && data.rObj.Transactions.length!=0){
          const dialogRef = this.dialog.open(YesNoAlertDialogComponent, {
            width: '30vw',
            disableClose: true,
            data: "There is " +data.rObj.Transactions.length+ " transactions for this configuration, do you want to proceed to delete?"
          });
          dialogRef.afterClosed().subscribe(result=>{
            if(result){
              this.deleteActivityConfirm(id)
            }
          });
        }
        else{
          const dialogRef = this.dialog.open(YesNoAlertDialogComponent, {
            width: '30vw',
            closeOnNavigation: false,
            disableClose: true,
            data: "Are you sure want to delete this activity?"
          });
          dialogRef.afterClosed().subscribe(result=>{
            if(result){
              this.deleteActivityConfirm(id)
            }
          });
        }
        this.loader.hide()
       // alert(data)
       } 
    },
    (err) => {
      this.loader.hide();
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        localStorage.clear();
        this.router.navigate([''])
        .then(() => {
          window.location.reload();
        });
      }
    }
  );
}

deleteActivityConfirm(id:string){
  this.loader.show();
  let params={
    ActivityConfigID:id
  }
  this.restservice.postData(params ,'/api/ActivityConfig/ActivityConfigDeleteConfirm')
  .subscribe(
    (data) => {
      this.loader.hide();
      if(data.rcode==1) {
       // alert(data)
       this.getConfiguration()
       //this.loader.hide();
       } 
    },
    (err) => {
      this.loader.hide();
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        localStorage.clear();
        this.router.navigate([''])
        .then(() => {
          window.location.reload();
        });
      }
    }
  );
}

  //Method to open history popup 
  openHistory(con: any) {
    const dialogRef = this.dialog.open(WorkflowHistoryComponent, {
      width: '80vw',
      height: '90vh',
      disableClose: true,
      //closeOnNavigation: false,
      data: con,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe();
  }
  exportToExcel(){
    // let rowRange =  this.paginationComp.GetRowRange();
      let params={
        SearchText:this.searchInput.value?this.searchInput.value.trim():"",
        Email: this.MsalauthService.instance.getAllAccounts()[0].username,
        PageNo:this.row_count,
        PageSize:this.page_count,
      }
      this.loader.show();
      this.restservice.postData(params, '/api/ActivityConfig/WorkflowExport').subscribe((res) => {
        if(res.rcode == 1) {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            // width: '30vw',
            // height: '25vh',
            disableClose: true,
            data:{data: "Data is being exported and will be sent as an email" ,  type:"SUCCESS"}
          });
          dialogRef.afterClosed().subscribe();
        } else {
          console.log(res);
        }
        this.loader.hide();
  
      }, (err) => {
        this.loader.hide();
        console.log(err)
      });
    }
    onPageChange(page: number) {
      this.row_count = this.page_count * (page - 1);
      this.getConfiguration();
      window.scrollTo(0, 0);
    }
}
