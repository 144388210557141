<section class="doc-view-container">
    <div class="">
        <ngx-doc-viewer *ngIf="['docx','doc','xls','xlsx','ppt','pptx'].includes(fileExtension)" [url]="fileURL"
            viewer="office" style="width:100%;height:100vh;"></ngx-doc-viewer>
    
        <ngx-doc-viewer *ngIf="fileExtension=='pdf'" [url]="fileURL" viewer="google"
            style="width:100%;height:100vh;"></ngx-doc-viewer>
    
        <div class="text-center" *ngIf="['jpeg','jpg','png','gif'].includes(fileExtension)">
            <div>
                <img [src]="fileURL" alt="">
            </div>
        </div>
    </div>
    <div class="download-button">
        <a class="" [href]="fileURL" target="_blank">Download File <i class="fa fa-download" aria-hidden="true"></i></a>
    </div>
</section>
