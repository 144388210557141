<div class="col-md-12 bgImage">
  <div class="col-md-12 text-center text-light">
    <h2>WORKFLOW CONFIGURATION</h2>
  </div>
  <div class="col-md-12 display-flex p-0 ">
    <div class="col-md-6 pl-0 display-flex text-light">
      <input type = "text" class = "form-control col-md-6 col-12" [formControl] = "searchInput" placeholder="Search Text...">
    </div>
    <div class="col-md-6 text-right p-0 work">
      <button class="btn btn-Action mr-2" (click)="exportToExcel()"><i class="fa fa-download mr-1"></i>Export to
        Excel</button>
      <button class="btn btn-Action " [routerLink]="['/manage-settings' ]" *ngIf="screenAccessService.screenAccess.isAdmin">Create Workflow</button>
    </div>
  </div>
 
    <table class = "table tabDesign table-responsive-sm table-responsive-md mt-2 bg-light">
        <tr class = "col-sm-12 col-md-12">
          <th  class="mx-1 tabhead">Activity ID</th>
          <th  class="mx-1 tabhead">Title</th>
          <th  class="mx-1 tabhead">Description</th>
          <th  class="mx-1 tabhead">Category</th>
          <th  class="mx-1 tabhead">Created On</th>
          <th  class="mx-1 tabhead">Created By</th>
          <th  class="mx-1 tabhead">History</th>
          <th  class="mx-1 tabhead">Action</th>
        </tr>
      <tbody class="tabBody" >
          <tr *ngFor="let con of configActivityList  | paginate: { itemsPerPage: resultPerpage.value, currentPage: currentPage,totalItems: totalCount };let i=index"> 
            <td class=" mx-1 tabData pointer">
              <a class="text-primary" title = "Click here to view details" [routerLink]="['/manage-settings', con.id, 'View']">{{con.id}}</a>&nbsp;(Version - {{con.LastVersion + 1}})
            </td>   
              <td class=" mx-1 tabData pointer"><a >{{con.Title}}</a></td>
              <td class=" mx-1 tabData"><a>{{con.Description}}</a></td>
              <td class=" mx-1 tabData">{{con.Category}}</td>
              <td class=" mx-1 tabData">{{authService.formatAMPM(con.CreatedDate)}}</td>
              <td class=" mx-1 tabData">{{con.CreatedUser}}</td>
              <td class=" mx-1 tabData text-center">
                <i *ngIf = "con.Version != con.LastVersion" class="fa fa-info btn btn btn-Action" (click) = "openHistory(con)"></i>
                <i *ngIf = "con.Version == con.LastVersion" title = "No previous version available" class="fa fa-info btn btn-Action pointer-ban"></i>
              </td>
              <td class=" mx-1 tabData text-center" width="12%">
                <i class="fa fa-trash btn btn-delete" *ngIf="screenAccessService.screenAccess.isAdmin" (click)="deleteActivity(con.id)" title="Delete"></i>&nbsp;
                <i class="fa fa-pencil-square-o btn btn-Action" *ngIf="screenAccessService.screenAccess.isAdmin" (click)="editActivity(con.id)" title="Edit" aria-hidden="true" ></i>&nbsp;
                <i class="fa fa-eye btn btn-Action"  [routerLink]="['/manage-settings', con.id, 'View']" title="View" aria-hidden="true" ></i>&nbsp;
              </td>
          </tr>
          <tr *ngIf="configActivityList.length==0">
              <td class=" mx-1 tabData" colspan="7">no record found</td>
          </tr>
      </tbody>
    </table>
  
    <div class="display-flex justify-content-end" *ngIf="totalCount>0">
      <pagination-controls class="my-pagination"
      previousLabel=""
      nextLabel=""
      (pageChange)="currentPage = $event;onPageChange($event)"></pagination-controls>
      <div class="bg-white px-2 display-flex align-items-center" >
        <span>
          Results per page
        </span>
        <span class="px-2">
          <select  id="resultPerpage" [formControl]="resultPerpage">
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
        </span>
      </div>
    </div>
  
</div>
