<div class="dialog-body mt-2">
    <div class="dialog-header text-center" mat-dialog-title >{{data.Title}} - Workflow Versions</div>
    <div class="dialog-content">
        <div class = "col-12 row">
            <div class = "col-2">
                <table class = "text-center" width = "100%">
                    <tr *ngFor = "let version of iterables">
                        <td>
                            <button class = "btn btn-Action" (click) = "viewVersion(version)">Version - {{version}}</button>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="top-div col-10">
                <div class="col-12 row my-1">
                    <div class = "col-4 text-left">
                        <span>Workflow ID</span>
                    </div>
                    <div class = "col-7 text-left">
                        <span class="text-dark content_font">{{configData?.id}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class = "col-4 text-left">
                        <span>Workflow Title</span>
                    </div>
                    <div class = "col-7 text-left">
                        <span class="text-dark content_font">{{configData?.Title}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class = "col-4 text-left">
                        <span>Workflow Description</span>
                    </div>
                    <div class = "col-7 text-left">
                        <span class="text-dark content_font">{{configData?.Description}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class = "col-4 text-left">
                        <span>Requested By</span>
                    </div>
                    <div class = "col-7 text-left">
                        <span class="text-dark content_font">{{configData?.CreatedUser}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class = "col-4 text-left">
                        <span>Requested On</span>
                    </div>
                    <div class = "col-7 text-left">
                        <span class="text-dark content_font">{{configData?.CreatedDate |date:'dd/MM/YYYY hh:mm'}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class = "col-4 text-left">
                        <span>Business Unit</span>
                    </div>
                    <div class = "col-7 text-left">
                        <span class="text-dark content_font">{{configData?.BusinessUnit}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class = "col-4 text-left">
                        <span>Category</span>
                    </div>
                    <div class = "col-7 text-left">
                        <span class="text-dark content_font">{{configData?.Category}}</span>
                    </div>
                </div>
<!--                 <div class="col-12 row my-1" *ngIf = "configData?.Category == 'CAPEX'">
                    <div class = "col-4 text-left">
                        <span>CAPEX Amount</span>
                    </div>
                    <div class = "col-7 text-left">
                        <span class="text-dark content_font">{{configData?.financeAmount}}</span>
                    </div>
                </div> -->
                <div class="col-12 row my-1">
                    <div class = "col-4 text-left">
                        <span>Proposal Groups</span>
                    </div>
                    <div class = "col-8 text-left">
                        <span *ngFor = "let proposals of proposalData">
                            <span class=" btn-outline-info mr-1 p-2 small textBreak">{{proposals}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class = "col-12 row">
        <div class = "col-2">&nbsp;</div>
        <div class="col-10 row mt-1 text-center mx-0 p-0">
            <div class = "col-4 text-left font-weight-bold">
                <span class = "h5">Approval Groups</span>
            </div>
            <div class = "col-12">
                <table class = "table table-responsive table-hover">
                    <thead>
                        <tr>
                            <th>Level</th>
                            <th>Approval Groups</th>
                            <th>SLA - SLA Unit</th>
                            <th>Lower Financial Limit(Range in USD)</th>
                            <th>Upper Financial Limit(Range in USD)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor = "let item of approvalData">
                            <td>{{item.Level}}</td>
                            <td>
                                <span *ngFor = "let names of item.groupNames">
                                    <span class=" btn-outline-info mr-1 p-2 small textBreak">{{names}}</span>
                                </span>
                            </td>
                            <td>{{item.SLAValue}} - {{item.SLAUnit}}</td>
                            <td class = "text-right">{{item.FinancialsLowerLimit}}</td>
                            <td class = "text-right">{{item.FinancialsUpperLimit}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div  class = "text-right">
        <button  class="mx-1 btn btn-Action" type="button" (click)="acceptError()">Ok</button>
    </div>
   <!--  <div class="dialogFooter text-right">
        <button class="mx-1 btn btn-Action" type="button" (click)="acceptError()">Ok</button>
    </div> -->
</div>
    