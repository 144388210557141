import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { TokenStorage } from 'src/app/core/token-storage';
import { ProposerGroups } from 'src/app/models/proposerGroups';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { DateFilterPipe } from '../pipes/dateFormat.pipe';

@Component({
  selector: 'app-manage-configuration-settings',
  templateUrl: './manage-configuration-settings.component.html',
  styleUrls: ['./manage-configuration-settings.component.css']
})
export class ManageConfigurationSettingsComponent implements OnInit {

  configurationform!: FormGroup;
  submitted: boolean = false;

  groupsList: Array<any> = new Array<any>()
  groupsListTemp: Array<any> = new Array<any>()
  @ViewChild('multiSelect') multiSelect!: any;
  public settings = {};
  public settingsApp = {};
  keyword = 'displayName';
  proposerGroups: any[] = [];
  groupMember: string[] = []
  ApprovalGroupsList: any[] = [];
  ApprovalGroupsListTemp: any[] = [];
  ApprovalGroups: any[] = [];
  appLevelGroupErr: string = "";
  activityID: string = '';
  mode: string = '';
  isIndex: number = 0;
  categoryList: any[] = [];
  CreatedDate: string = ''
  CreatedUser: string = '';
  CreatedUserName: string = '';
  lastVersion: number = 0;
  parentActivityConfigID: string = '';
  version: number = 0;
  businessUnitList: any[] = [];
  proposerGroupMemberList: any[] = [];
  editProposer: any[] = [];
  groups = new FormControl();

  isValidLevel: boolean = false;
  setUpperLimit: any;
  isLevelAdd:boolean=false;

  constructor(
    private datePipe: DateFilterPipe,
    private formBuilder: FormBuilder,
    private _ativeRouter: ActivatedRoute,
    private authservice: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private loader: LoaderService,
    private token: TokenStorage,
    public restService: RestService,
    public MsalauthService: MsalService) {
    this._ativeRouter.params.subscribe(params => {
      this.activityID = params['id'];
      this.mode = params['mode']
    });

  }

  ngOnInit() {
    if (this.mode == undefined) {
      this.mode = "Create"
    }
    this.proposerGroups = []
    this.configurationform = this.fb.group({
      buUnits: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      //category: ['', [Validators.required]],
      IsCapex: ['1'],
      groups: ['', [Validators.required]],
      items: this.activityID == undefined ? this.formBuilder.array([this.createItem()]) : this.formBuilder.array([])

    });
    this.getAllBusinessUnits();
    // this.getCategory();
    this.getAllGroups();
    this.getActivityConfiguration();

    this.submitted = false;

    this.settings = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      // itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No data available',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.settingsApp = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      // itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No data available',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };




    //this.Guidelineform();
  }

  public onFilterChangePro(item: any) {
  }
  public onDropDownClose(item: any) {
  }

  public onItemSelectPro(item: any) {
    //this.proposerGroups["Group"]=item
    this.getGeoupMembersProposer(item.id)
    //this.getApprovalGroups()

  }

  public onDeSelectPro(item: any) {
    let index = this.proposerGroups.findIndex(d => d.GroupID == item.id); //find index in your array
    this.proposerGroups.splice(index, 1);//remove element from array
    //this.getApprovalGroups()
  }

  public onSelectAllPro(items: any) {
    //console.log(items);
    for (let i = 0; i < items.length; i++) {
      this.getGeoupMembersProposer(items[i].id)
      let prop = this.groupsList.find(group => group.id === items[i].id);
      this.proposerGroups.push({ "Group": prop.displayName, "GroupID": prop.id, "Members": this.groupMember })
    }






    // this.getApprovalGroups()


  }

  getGeoupMembersProposer(id: string) {
    this.authservice.getGroupMembers(id)
      .subscribe((data: any) => {
        if (data.value != undefined) {
          //this.proposerGroupMemberList = [];
          for (let i = 0; i < data.value.length; i++) {
            if (data.value[i]['@odata.type'] == '#microsoft.graph.user') {
              this.proposerGroupMemberList.push(data.value[i].userPrincipalName)
            }
          }
          let prop = this.groupsList.find(group => group.id === id);
          this.proposerGroups.push({ Group: prop.description != null ? prop.displayName + " - " + prop.description : prop.displayName, GroupID: prop.id })
        }
      }, err => {
        if (err.status == 401) {
          const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.token.MSALget()
          });
          this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
            this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
            this.getGeoupMembersProposer(id);
          }, (errVal) => {
            console.log(errVal);
            if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
              localStorage.clear();
              this.router.navigate([''])
                .then(() => {
                  window.location.reload();
                });
            }
          });
        }
      })
  }



  public onDeSelectAllPro(items: any) {
    //console.log(items);
    this.proposerGroups = []
    //this.getApprovalGroups()

  }
  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ///Approval Groups
  public onFilterChangeApp(item: any) {
  }
  public onDropDownCloseApp(item: any) {
  }

  public onItemSelectApp(item: any, id: number) {
    // let _isGroup = this.ApprovalGroups.findIndex(x => x.GroupID == item.id)
    // if (_isGroup != -1) {
    //   let _temp = this.ApprovalGroups.find(d => d.GroupID == item.id); //find index in your array
    //   this.isIndex=id;
    //   this.IsLevel()
    //   //this.appLevelGroupErr = "The Group " + _temp.Group + " already Selected in Level" + _temp.Level;
    // }
    // else {
    let prop = this.ApprovalGroupsList.find(group => group.id === item.id);
    if (prop.description != null) {
      this.ApprovalGroups.push({ Group: prop.displayName + " - " + prop.description, GroupID: prop.id, Level: id });
    }
    else {
      this.ApprovalGroups.push({ Group: prop.displayName, GroupID: prop.id, Level: id });

    }


    //this.ApprovalGroupsList = JSON.parse(JSON.stringify(this.ApprovalGroupsListTemp));
    /*  for(let i = 0; i < this.ApprovalGroups.length; i++) {
       var index = this.ApprovalGroupsList.findIndex(x => x.id == this.ApprovalGroups[i].GroupID); 
       this.ApprovalGroupsList.splice(index,1);
     } */
    this.selectEvent(item, id);
    //}
  }

  public onDeSelectApp(item: any, id: number) {
    //////////////////
    for (let j = 0; j < this.ApprovalGroupsList.length; j++) {
      let _temp = this.ApprovalGroups.find(d => d.GroupID == this.ApprovalGroupsList[j].id && d.Level != id); //find index in your array
      if (_temp != undefined)
        this.appLevelGroupErr = "The Group " + _temp.Group + " already Selected in Level" + _temp.Level;
    }
    /////////////////////

    let index = this.ApprovalGroups.findIndex(d => d.GroupID == item.id && id == d.Level); //find index in your array
    if (index != -1) {
      this.ApprovalGroupsList.push(this.ApprovalGroups[index]);
      this.ApprovalGroups.splice(index, 1);//remove element from array
    }
    /* this.ApprovalGroupsList = this.ApprovalGroupsListTemp;
    for(let i = 0; i < this.ApprovalGroups.length; i++) {
      var groupindex = this.ApprovalGroupsList.findIndex(x => x.id == this.ApprovalGroups[i].GroupID); 
      this.ApprovalGroupsList.splice(groupindex,1);
    } */
    //this.ApprovalGroupsList = JSON.parse(JSON.stringify(this.ApprovalGroupsListTemp));
    //console.log(this.ApprovalGroupsListTemp.length)
    if (this.ApprovalGroups.length == 0) {
      this.appLevelGroupErr = '';
    }
  }

  IsLevel() {
    return this.isIndex
  }

  public onSelectAllApp(items: any,) {
    //console.log(items);
    // for(let i=0;i< items.length;i++){
    //   this.ApprovalGroupsList.push({"Group":items[i].displayName,"GroupID":items[i].id})
    // }

  }
  public onDeSelectAllApp(items: any) {
    //console.log(items);
    //this.ApprovalGroupsList=[]
  }


  // getNextLevelApprovers() {
  //   //this.ApprovalGroupsList=[]
  //   for (let f = 0; f < this.ApprovalGroupsList.length; f++) {
  //     let _proposer = this.ApprovalGroups.some(x => x.GroupID == this.ApprovalGroupsList[f].id)
  //     if (!_proposer) {
  //       this.ApprovalGroupsList.push(this.ApprovalGroupsList[f])
  //     }

  //   }
  // }



  //autocomplete
  selectEvent1(item: any) {
    // do something with selected item
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e: any) {
    // do something
  }

  createItem(): FormGroup {

    return this.formBuilder.group({
      approvalLevel: ['', [Validators.required]],
      lowerLimit: [null, [Validators.required]],
      upperLimit: [null, [Validators.required]],
      slaUnit: [''],
      slaValue: [null],
      levelLabel: [''],
      Members: this.fb.array([]),
    });
  }
  get approvalLevelForm1() {
    return this.configurationform.get('items') as FormArray;
  }


  approvalLevelForm(form: any) {
    return form.controls.items.controls;
  }

  addApprovalLevelForm(): void {
    //this.getNextLevelApprovers();
    //console.log(this.ApprovalGroups);
    //this.settingsApp = {}
    /* console.log("Group List");
    console.log(this.ApprovalGroupsList);
    console.log("Group");
    console.log(this.ApprovalGroups) */

    this.settingsApp = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      // itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No data available',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    //console.log(this.ApprovalGroupsList);
    //this.getApprovalGroups();
    this.isLevelAdd=false
   
   const arr=<FormArray>this.configurationform.get('items') as FormArray;

arr.controls.forEach((element: any) => {
if(element.controls.lowerLimit.value==null || element.controls.upperLimit.value==null && this.f.IsCapex.value!="3"){
  if(element.controls.lowerLimit.value==null && this.f.IsCapex.value!="3"){
    element.controls.lowerLimit.setErrors({lowerLimitRequired:true});

  }
  if(element.controls.upperLimit.value==null && this.f.IsCapex.value!="3"){
    element.controls.upperLimit.setErrors({UpperLimitRequired:true});

  }
  this.isLevelAdd=true
}
});
if(!this.isLevelAdd ||  this.f.IsCapex.value=="3"){
  (this.configurationform.get('items') as FormArray).push(

    this.createItem()
  );

}



  }

  setNonFinance(){
      
    const arr=<FormArray>this.configurationform.get('items') as FormArray;

    arr.controls.forEach((element: any) => {
        element.controls.lowerLimit.setErrors(null);
        element.controls.upperLimit.setErrors(null);  
    });
    this.isValidLevel = false;
  }

  removeApprovalLevel(id: number) {
    const control = (<FormArray>this.configurationform.controls['items'])
    control.removeAt(id);
  }



  get f() {
    return this.configurationform.controls;
  }

  getAllBusinessUnits() {
    this.authservice.getBusinessUnits()
      .subscribe((data: any) => {
        let _temp = [];
        for (var j = 0; j < data.value.length; j++) {
          if (data.value[j].displayName != 'BU-L1-JCG') {
            _temp.push(data.value[j]);
          }
        }
        this.businessUnitList = _temp
      },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.status == 401) {
            const headers = new HttpHeaders({
              'Authorization': 'Bearer ' + this.token.MSALget()
            });
            this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
              this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
              this.getAllBusinessUnits();
            }, (errVal) => {
              console.log(errVal);
              if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
              }
            });
          }
        })
  }

  getAllProposerRoles() {
    this.groupsList = [];
    this.groupsListTemp = [];
    this.f.groups.setValue('');
    // alert(this.f.buUnits.value)
    this.authservice.getProposerRoles(this.f.buUnits.value)
      .subscribe((data: any) => {
        let _temp = []
        for (let i = 0; i < data.value.length; i++) {
          if (data.value[i]['@odata.type'] == '#microsoft.graph.group') {
            if (data.value[i].description != null) {
              data.value[i].fullName = data.value[i].displayName + '-' + data.value[i].description
            } else {
              data.value[i].fullName = data.value[i].displayName
            }
            _temp.push(data.value[i])
          }
        }
        this.groupsListTemp = _temp;
        this.ApprovalGroupsListTemp = [];
        this.ApprovalGroupsList = [];
        this.getApprovalGroups(this.f.buUnits.value)
        /* this.groupsListTemp.forEach(group => {
          if(group.displayName.includes('BU')){
            //console.log(group.id)
            this.authservice.getProposerRoles(this.f.buUnits.value)
            .subscribe((data: any) => {
              let _temp=[]
           for(let i=0; i< data.value.length;i++){
            if( data.value[i]['@odata.type']=='#microsoft.graph.group'){
              if(data.value[i].description != null) {
                data.value[i].fullName = data.value[i].displayName + '-' + data.value[i].description
              } else {
                data.value[i].fullName = data.value[i].displayName
              }
              _temp.push(data.value[i]) 
             }
           }
           });
          }
        })
        this.groupsListTemp.push(..._temp);
        let newList = [...new Map(this.groupsListTemp.map(item =>[item.id, item])).values()];
        console.log(newList);
        this.groupsListTemp = newList;  */
        let buItem = this.businessUnitList.find(bu => bu.id === this.f.buUnits.value);
        if(buItem){
          buItem.fullName = buItem?.description != null ? buItem?.displayName + '-' + buItem?.description : buItem?.displayName
          this.groupsListTemp.unshift(buItem);
        }
      
      },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.status == 401) {
            const headers = new HttpHeaders({
              'Authorization': 'Bearer ' + this.token.MSALget()
            });
            this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
              this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
              this.getAllProposerRoles();
            }, (errVal) => {
              console.log(err);
              if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
              }
            });
          }
        })
  }

  getApprovalGroups(buId: string) {
    if (this.ApprovalGroupsList.length > 1) {
      for (let i = 1; i < this.ApprovalGroupsList.length; i++) {
        this.removeApprovalLevel(i);
      }
    }
    this.approvalLevelForm1.reset();
    //this.ApprovalGroupsList = [];
    //this.ApprovalGroupsListTemp = [];
    // for (let f = 0; f < this.groupsList.length; f++) {
    //   let _proposer = this.proposerGroups.some(x => x.GroupID == this.groupsList[f].id)
    //   if (!_proposer) {
    //     this.ApprovalGroupsList.push(this.groupsList[f])
    //   }

    // }
    //setTimeout(() => {
    this.authservice.getApprovalGroups(buId)
      .subscribe((data: any) => {
        let _temp = [];
        for (let i = 0; i < data.value.length; i++) {
          if (data.value[i]['@odata.type'] == '#microsoft.graph.group') {
            if (data.value[i].description != null) {
              data.value[i].fullName = data.value[i].displayName + '-' + data.value[i].description
            } else {
              data.value[i].fullName = data.value[i].displayName
            }
            data.value[i].isChild = false;
            this.ApprovalGroupsList.push(data.value[i]);
            this.ApprovalGroupsListTemp.push(data.value[i]);
            _temp.push(data.value[i])
            this.groupsList.push(data.value[i])
          }
        }
        //this.ApprovalGroupsListTemp = _temp;
        //this.ApprovalGroupsList= _temp;
        /* this.ApprovalGroupsList.forEach(item => {
          this.groupsList.push(item)
        }) */
        this.groupsList = [...this.groupsListTemp, ...this.ApprovalGroupsList];
        //this.ApprovalGroupsList = this.groupsList
        this.ApprovalGroupsList = [...this.ApprovalGroupsList, ... this.groupsListTemp];
        let newList = [...new Map(this.groupsList.map(item => [item.id, item])).values()];
        //console.log(newList);
        this.groupsList = newList;
        let newApprovalList = [...new Map(this.ApprovalGroupsList.map(item => [item.id, item])).values()];
        //console.log(newList);
        this.ApprovalGroupsList = newApprovalList;
        //console.log(this.groupsList)
      },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.status == 401) {
            const headers = new HttpHeaders({
              'Authorization': 'Bearer ' + this.token.MSALget()
            });
            this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
              this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
              this.getApprovalGroups(buId);
            }, (errVal) => {
              console.log(err);
              if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
              }
            });
          }
        })
    //},2000);
    this.authservice.getApprovalParentGroup(buId).subscribe((data: any) => {
      let _temp: any = [];
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i]['@odata.type'] == '#microsoft.graph.group') {
          if (data.value[i].description != null) {
            data.value[i].fullName = data.value[i].displayName + '-' + data.value[i].description
          } else {
            data.value[i].fullName = data.value[i].displayName
          }
          data.value[i].isChild = false;
          this.ApprovalGroupsList.push(data.value[i]);
          this.ApprovalGroupsListTemp.push(data.value[i]);
          _temp.push(data.value[i])
          this.groupsList.push(data.value[i])
        }
      }
      this.groupsList = [...this.groupsListTemp, ...this.ApprovalGroupsList];
      //this.ApprovalGroupsList = this.groupsList
      this.ApprovalGroupsList = [...this.ApprovalGroupsList, ... this.groupsListTemp];
      let newList = [...new Map(this.groupsList.map(item => [item.id, item])).values()];
      //console.log(newList);
      this.groupsList = newList;
      let newApprovalList = [...new Map(this.ApprovalGroupsList.map(item => [item.id, item])).values()];
      //console.log(newList);
      this.ApprovalGroupsList = newApprovalList;
    },
      (err) => {
        this.loader.hide();
        console.log(err);
        if (err.status == 401) {
          const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.token.MSALget()
          });
          this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
            this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
            this.getApprovalGroups(buId);
          }, (errVal) => {
            console.log(err);
            if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
              localStorage.clear();
              this.router.navigate([''])
                .then(() => {
                  window.location.reload();
                });
            }
          });
        }
      })

  }




  getAllGroups() {
    this.authservice.getGrops()
      .subscribe((data: any) => {

        // this.groupsList = data.value
      },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.status == 401) {
            const headers = new HttpHeaders({
              'Authorization': 'Bearer ' + this.token.MSALget()
            });
            this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
              this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
              this.getAllGroups();
            }, (errVal) => {
              console.log(errVal);
              if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
              }
            });
          }
        })
  }

  //getGroupMembers
  selectEvent(item: any, index: any) {
    this.authservice.getGroupMembers(item.id)
      .subscribe((data: any) => {
        this.groupMember = [];
        if (data.value != undefined && data.value.length > 0) {
          for (let i = 0; i < data.value.length; i++) {
            if (data.value[i]['@odata.type'] == '#microsoft.graph.user') {
              this.groupMember.push(data.value[i].userPrincipalName)
            }
          }
          //console.log(this.configurationform.value.items)
          for (let k = 0; k < this.ApprovalGroups.length; k++) {
            if (item.id == this.ApprovalGroups[k].GroupID) {
              this.ApprovalGroups[k].Members = this.groupMember;
            }
          }
        }

      },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.status == 401) {
            const headers = new HttpHeaders({
              'Authorization': 'Bearer ' + this.token.MSALget()
            });
            this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
              this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
              this.selectEvent(item, index)
            }, (errVal) => {
              console.log(err);
              if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
              }
            });
          }
        })
  }

  submitConfiguration() {
    let _approvalItems: any = [];
    let _groupLevels = []

    if(this.f.IsCapex.value=="3"){
      const arr=<FormArray>this.configurationform.get('items') as FormArray;
      arr.controls.forEach((element: any) => {
        element.controls.lowerLimit.setValue(0);
        element.controls.upperLimit.setValue(0);
            element.controls.lowerLimit.setErrors(null);
            element.controls.upperLimit.setErrors(null);
        });
    }

   
    for (let x = 0; x < this.configurationform.value.items.length; x++) {
      let _tempApprovalGroups = []
      let _tempApprovalGroupsMembers: any[] = [];

      //let _groupString=[];
      for (let y = 0; y < this.ApprovalGroups.length; y++) {
        if (this.ApprovalGroups[y].Level == x + 1) {
          _tempApprovalGroups.push({
            "Group": this.ApprovalGroups[y].Group,
            "GroupID": this.ApprovalGroups[y].GroupID,
            //"Members": this.ApprovalGroups[y].Members
          });
          this.ApprovalGroups[y].Members?.forEach((element: string) => {
            _tempApprovalGroupsMembers.push(element);
          });
        }
        //_tempApprovalGroupsMembers=_groupString
      }

      _approvalItems.push({
        Level: x + 1,
        ApprovalGroups: _tempApprovalGroups,
        Members: _tempApprovalGroupsMembers,
        FinancialsLowerLimit: this.configurationform.value.items[x].lowerLimit ? this.configurationform.value.items[x].lowerLimit : 0,
        FinancialsUpperLimit: this.configurationform.value.items[x].upperLimit ? this.configurationform.value.items[x].upperLimit : 0,
        SLAUnit: this.configurationform.value.items[x].slaUnit,
        SLAValue: this.configurationform.value.items[x].slaValue ? this.configurationform.value.items[x].slaValue : 0,
        LevelLabel: '',
      })

    }

    _approvalItems.forEach((element: any) => {
      let _levelLable = '';
      _groupLevels = _approvalItems.filter((s: any) => s.FinancialsLowerLimit == element.FinancialsLowerLimit)
      if (_groupLevels.length == 1) {
        element.LevelLabel = "Level 2 Endorsement"
      }
      if (_groupLevels.length > 1) {

        if (_groupLevels[_groupLevels.length - 1].Level == element.Level) {
          element.LevelLabel = "Level 2 Endorsement";
        }
        else {
          element.LevelLabel = "Level 1 Endorsement";
        }
      }
    });
    this.submitted = true;
    if (this.configurationform.invalid) {
      return;
    }

    else {
      //let category = this.categoryList.find(x => x.id == this.f.category.value)
      let category = this.f.IsCapex.value == 1 ? 'CAPEX' : this.f.IsCapex.value == 2 ? 'OPEX' : this.f.IsCapex.value == 3 ? 'Non Financial' : 'Other Financial'
      this.loader.show();
      let buUnitName = this.businessUnitList.find(item => item.id == this.f.buUnits.value)
      let params = {
        "id": this.activityID,
        "BusinessUnit": buUnitName.description != null ? buUnitName.displayName + '-' + buUnitName.description : buUnitName.displayName,
        "BusinessUnitGroupId": this.f.buUnits.value,
        "Title": this.f.title.value.trim(),
        "Description": this.f.description.value.trim(),
        "categoryID": parseInt(this.f.IsCapex.value),
        "Category": category,
        "IsCAPEX": this.f.IsCapex.value != 'Capex' ? false : true,
        "IsOPEX": this.f.IsCapex.value != 'Opex' ? false : true,
        "OtherFinancial": this.f.IsCapex.value != 'Other Financial' ? false : true,
        "CreatedUser": this.MsalauthService.instance.getAllAccounts()[0].username,
        "CreatedUserName": this.MsalauthService.instance.getAllAccounts()[0].name,
        "ProposerGroups": this.proposerGroups,
        //"Members":this.proposerGroupMemberList, 
        "ApprovalLevels": _approvalItems,

      }

      this.restService.postData(params, '/api/ActivityConfig/ActivityConfigInsert')
        .subscribe(data => {
          if (data.rcode == 1) {
            //console.log(data)
            this.router.navigate(['/settings'])
          } else {
            //this.errorMsg = data.rmsg;
          }
          this.loader.hide();

        },
          (err) => {
            this.loader.hide();
            console.log(err);
            if (err.status === 401 || err.status === 403 || err.status === 0) {
              localStorage.clear();
              this.router.navigate([''])
                .then(() => {
                  window.location.reload();
                });
            }
          });
    }
  }


  updateConfiguration() {
    let _approvalItems: any = [];
    let _groupLevels = []
    if(this.f.IsCapex.value=="3"){
      const arr=<FormArray>this.configurationform.get('items') as FormArray;
      arr.controls.forEach((element: any) => {
        element.controls.lowerLimit.setValue(0);
        element.controls.upperLimit.setValue(0);
            element.controls.lowerLimit.setErrors(null);
            element.controls.upperLimit.setErrors(null);
        });
    }

    for (let x = 0; x < this.configurationform.value.items.length; x++) {
      let _tempApprovalGroups = []
      let _tempApprovalGroupsMembers: any[] = [];
      for (let y = 0; y < this.ApprovalGroups.length; y++) {
        if (this.ApprovalGroups[y].Level == x + 1) {
          _tempApprovalGroups.push({
            "Group": this.ApprovalGroups[y].Group,
            "GroupID": this.ApprovalGroups[y].GroupID
          });
          this.ApprovalGroups[y].Members.forEach((element: string) => {
            _tempApprovalGroupsMembers.push(element);
          });



        }
      }

      _approvalItems.push({
        Level: x + 1, ApprovalGroups: _tempApprovalGroups,
        Members: _tempApprovalGroupsMembers,
        FinancialsLowerLimit: this.configurationform.value.items[x].lowerLimit ? this.configurationform.value.items[x].lowerLimit : 0,
        FinancialsUpperLimit: this.configurationform.value.items[x].upperLimit ? this.configurationform.value.items[x].upperLimit : 0,
        SLAUnit: this.configurationform.value.items[x].slaUnit,
        SLAValue: this.configurationform.value.items[x].slaValue ? this.configurationform.value.items[x].slaValue : 0,
        LevelLabel: this.configurationform.value.items[x].levelLabel,
      })


    }



    _approvalItems.forEach((element: any) => {
      let _levelLable = '';

      _groupLevels = _approvalItems.filter((s: any) => s.FinancialsLowerLimit == element.FinancialsLowerLimit)


      if (_groupLevels.length == 1) {
        element.LevelLabel = "Level 2 Endorsement"
      }
      if (_groupLevels.length > 1) {

        if (_groupLevels[_groupLevels.length - 1].Level == element.Level) {
          element.LevelLabel = "Level 2 Endorsement";
        }
        else {
          element.LevelLabel = "Level 1 Endorsement";
        }
      }

    });



    this.submitted = true;
    if (this.configurationform.invalid) {
      return;
    }
    else {
      let category = this.f.IsCapex.value == 1 ? 'CAPEX' : this.f.IsCapex.value == 2 ? 'OPEX' : this.f.IsCapex.value == 3 ? 'Non Financial' : 'Other Financial'
      this.loader.show();
      let buUnitName = this.businessUnitList.find(item => item.id == this.f.buUnits.value)
      let params = {
        "id": this.activityID,
        "Title": this.f.title.value,
        "Description": this.f.description.value.trim(),
        "categoryID": parseInt(this.f.IsCapex.value),
        "Category": category,
        "IsCAPEX": this.f.IsCapex.value != 'Capex' ? false : true,
        "IsOPEX": this.f.IsCapex.value != 'Opex' ? false : true,
        "OtherFinancial": this.f.IsCapex.value != 'Other Financial' ? false : true,
        //"Members":this.proposerGroupMemberList, 
        "ProposerGroups": this.proposerGroups,
        "ApprovalLevels": _approvalItems,
        "BusinessUnit": buUnitName.description != null ? buUnitName.displayName + '-' + buUnitName.description : buUnitName.displayName,
        "BusinessUnitGroupId": this.f.buUnits.value,
        CreatedDate: this.CreatedDate,
        CreatedUserName: this.CreatedUserName,
        CreatedUser: this.CreatedUser,
        lastVersion: this.lastVersion,
        parentActivityConfigID: this.parentActivityConfigID,
        version: this.version,
      }


      this.restService.postData(params, '/api/ActivityConfig/ActivityConfigUpdate')
        .subscribe(data => {
          if (data.rcode == 1) {
            //console.log(data)
            this.router.navigate(['/settings'])
          } else {
            //this.errorMsg = data.rmsg;
          }
          this.loader.hide();

        },
          (err) => {
            this.loader.hide();
            console.log(err);
            if (err.status === 401 || err.status === 403 || err.status === 0) {
              localStorage.clear();
              this.router.navigate([''])
                .then(() => {
                  window.location.reload();
                });
            }
          });
    }
  }


  getCategory() {
    this.loader.show();
    this.restService.postData(null, '/api/ActivityConfig/CategoryFetch')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            if (data.rObj.Category != null) {
              this.categoryList = data.rObj.Category
            }
            this.loader.hide();
          }
        },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.status === 401 || err.status === 403 || err.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        }
      );
  }


  //getConfiguration For Edit
  getActivityConfiguration() {
    this.loader.show();
    let params = { "ActivityConfigID": this.activityID }
    this.restService.postData(params, '/api/ActivityConfig/ActivityConfigFetch')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            if (data.rObj.ActivityConfig != null) {

              if (this.mode == 'View') {
                this.f.title.disable();
                this.f.buUnits.disable();
                this.f.description.disable();
                //this.f.category.disable();
                this.f.groups.disable();
                this.f.IsCapex.disable();
              }
              this.f.title.setValue(data.rObj.ActivityConfig.Title);
              this.f.description.setValue(data.rObj.ActivityConfig.Description);
              //this.f.category.setValue(data.rObj.ActivityConfig.categoryID);
              //   if (data.rObj.ActivityConfig.IsCAPEX == true) this.f.IsCapex.setValue('Capex')
              //   if (data.rObj.ActivityConfig.IsOPEX == true) this.f.IsCapex.setValue('Opex')
              //  if(data.rObj.ActivityConfig.OtherFinancial == true) this.f.IsCapex.setValue('OtherFinancial')
              this.CreatedDate = data.rObj.ActivityConfig.CreatedDate
              this.CreatedUser = data.rObj.ActivityConfig.CreatedUser != '' ? data.rObj.ActivityConfig.CreatedUser : data.rObj.ActivityConfig.CreatedUser
              this.lastVersion = data.rObj.ActivityConfig.LastVersion
              this.CreatedUserName = data.rObj.ActivityConfig.CreatedUserName
              this.parentActivityConfigID = data.rObj.ActivityConfig.ParentActivityConfigID;
              this.version = data.rObj.ActivityConfig.Version;
              this.f.IsCapex.setValue(data.rObj.ActivityConfig.CategoryID.toString());
              this.f.buUnits.setValue(data.rObj.ActivityConfig.BusinessUnitGroupId)
              let _editProposer: any[] = [];
              let _appLevelGroup: any = [];
              //if(this.mode != 'View') {
                this.getAllProposerRoles();
              //}
              //this.getApprovalGroups(data.rObj.ActivityConfig.businessUnitGroupId);

              //this.getEditGroupList(data.rObj.ActivityConfig.ProposerGroups)

              setTimeout(() => {
                for (let y = 0; y < data.rObj.ActivityConfig.ProposerGroups.length; y++) {
                  _editProposer.push(this.groupsList.find(x => x.id == data.rObj.ActivityConfig.ProposerGroups[y].GroupID));
                  this.proposerGroups.push({ Group: data.rObj.ActivityConfig.ProposerGroups[y].Group, 
                    GroupID: data.rObj.ActivityConfig.ProposerGroups[y].GroupID })
                }
                //this.configurationform(this.configurationform);

                this.editProposer = _editProposer;
                this.f.groups.setValue(this.editProposer);
                this.settings = {
                  singleSelection: false,
                  idField: 'id',
                  textField: 'fullName',
                  enableCheckAll: false,
                  selectAllText: 'Select All',
                  unSelectAllText: 'Unselect All',
                  allowSearchFilter: true,
                  limitSelection: -1,
                  clearSearchFilter: true,
                  maxHeight: 197,
                  // itemsShowLimit: 3,
                  searchPlaceholderText: 'Search',
                  noDataAvailablePlaceholderText: 'No data available',

                };
              if(this.mode != 'View' && this.mode != 'Edit') {
                for (let n = 0; n < this.editProposer.length; n++) {
                  this.onItemSelectPro(this.editProposer[n])
                }
              }
              }, 2000);


              setTimeout(() => {

                //Approval Levels
                //this.f.groups.setValue(this.f.groups.value)
                for (let q = 0; q < data.rObj.ActivityConfig.ApprovalLevels.length; q++) {
                  _appLevelGroup = []
                  for (let t = 0; t < data.rObj.ActivityConfig.ApprovalLevels[q].ApprovalGroups.length; t++) {
                    _appLevelGroup.push(this.ApprovalGroupsList.find(x => x.id == data.rObj.ActivityConfig.ApprovalLevels[q].ApprovalGroups[t].GroupID))
                    this.ApprovalGroups.push({ Group: data.rObj.ActivityConfig.ApprovalLevels[q].ApprovalGroups[t].Group, 
                      GroupID: data.rObj.ActivityConfig.ApprovalLevels[q].ApprovalGroups[t].GroupID, 
                      Level: data.rObj.ActivityConfig.ApprovalLevels[q].Level, 
                      Members: data.rObj.ActivityConfig.ApprovalLevels[q].Members });
                  }
                  if(this.mode != 'View' && this.mode != 'Edit') {
                    for (let r = 0; r < _appLevelGroup.length; r++) {
                      this.onItemSelectApp(_appLevelGroup[r], data.rObj.ActivityConfig.ApprovalLevels[q].Level)
                    }
                  }


                  /* for(let i = 0; i < this.ApprovalGroups.length; i++) {
                    var index = this.ApprovalGroupsList.findIndex(x => x.id == this.ApprovalGroups[i].GroupID); 
                    this.ApprovalGroupsList.splice(index,1);
                  } */
                  (<FormArray>this.configurationform.get('items')).push(this.fb.group({
                    approvalLevel: '',
                    lowerLimit: data.rObj.ActivityConfig.ApprovalLevels[q].FinancialsLowerLimit,
                    upperLimit: data.rObj.ActivityConfig.ApprovalLevels[q].FinancialsUpperLimit,
                    slaUnit: data.rObj.ActivityConfig.ApprovalLevels[q].SLAUnit,
                    slaValue: data.rObj.ActivityConfig.ApprovalLevels[q].SLAValue,
                    levelLabel: data.rObj.ActivityConfig.ApprovalLevels[q].LevelLabel,
                    Members: data.rObj.ActivityConfig.ApprovalLevels[q].ApprovalGroups
                  }));
                  const control1 = this.approvalLevelForm(this.configurationform);
                  control1[q].controls.approvalLevel.setValue(_appLevelGroup)

                  if (this.mode == 'View') {
                    control1[q].controls.lowerLimit.disable();
                    control1[q].controls.upperLimit.disable();
                    control1[q].controls.slaUnit.disable();
                    control1[q].controls.slaValue.disable();
                    //control1[q].controls.levelLabel.disable();
                  }
                }
                this.loader.hide();
              }, 2000)
            }
            else{
              this.loader.hide();
            }
          }
        },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.status === 401 || err.status === 403 || err.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        }
      );
  }

  validateLimit(index: number, e: any, limitType: string) {
    this.isValidLevel = false;
    if (index > 0 && this.f.IsCapex.value!="3") {
      let previousIndex = index - 1;
      this.isValidLevel = false;
      // let currentLowerlimit = this.configurationform.get('items') as FormArray;
      let itemFormarray = this.configurationform.get('items') as FormArray;
      itemFormarray.controls[index].get('lowerLimit')?.setErrors(null);
      itemFormarray.controls[index].get('upperLimit')?.setErrors(null);
      


      var map = this.configurationform.value.items.reduce((acc: { [x: string]: any; }, o: { lowerLimit: string | number; }) => (acc[o.lowerLimit] = (acc[o.lowerLimit] || 0) + 1, acc), {});
      var values: { name: string; count: any; }[] = [];
      Object.keys(map).forEach(key => {
        values.push({ 'name': key, 'count': map[key] })
      });

      if (values[0].count > 6) {
        itemFormarray.controls[index].get('lowerLimit')?.setErrors({ "exceededLevel": true })

        this.isValidLevel = true
      }

      let _previouslevel = this.configurationform.value.items[previousIndex];
      if (limitType == 'lowerLimit' && (parseFloat(e.target.value) != _previouslevel.lowerLimit && parseFloat(e.target.value) <= _previouslevel.upperLimit)) {
        //this.lowerLimitError=true
        itemFormarray.controls[index].get("upperLimit")?.enable()
        itemFormarray.controls[index].get('upperLimit')?.setValue('');
        //let setLowerLimitError = this.configurationform.get('items') as FormArray;
        itemFormarray.controls[index].get('lowerLimit')?.setErrors({ "lowerLimitError": true })
        this.isValidLevel = true;

      }

      else if (limitType == 'lowerLimit' && parseFloat(e.target.value) == _previouslevel.lowerLimit && values[0].count <= 6) {
        //_approvalItemsChange[index].FinancialsUpperLimit=_previouslevel.upperLimit
        itemFormarray.controls[index].get('upperLimit')?.setValue(_previouslevel?.upperLimit)
        //itemFormarray.controls[index].controls.upperLimit.setValue(_previouslevel.upperLimit)
        itemFormarray.value[index].upperLimit = _previouslevel.upperLimit

        // itemFormarray.controls[index].patchValue({

        //   disabled: true
        // });


        // if(itemFormarray.controls[index].get('upperLimit')?.value==_previouslevel?.upperLimit){
        //   //itemFormarray.at(index).get('upperLimit')?.disable({onlySelf: true})
        //   itemFormarray.controls[index].get('upperLimit')?.disable({onlySelf: true});

        // }
      }



      else if (limitType == 'upperLimit' && parseFloat(e.target.value) <= parseFloat(itemFormarray.controls[index].get('lowerLimit')?.value)) {
        let setUpperLimitError = this.configurationform.get('items') as FormArray;
        setUpperLimitError.controls[index].get('upperLimit')?.setErrors({ "upperLimitError": true })
        this.isValidLevel = true
      }

      else if (limitType == 'lowerLimit' && parseFloat(itemFormarray.controls[index].get('upperLimit')?.value) <= parseFloat(e.target.value)) {
        // let setUpperLimitError = this.configurationform.get('items') as FormArray;
        itemFormarray.controls[index].get('upperLimit')?.setErrors({ "upperLimitError": true })
        this.isValidLevel = true
      }

      else if (limitType == 'upperLimit' && (itemFormarray.controls[index].get('lowerLimit')?.value!=_previouslevel.lowerLimit && parseFloat(itemFormarray.controls[index].get('lowerLimit')?.value) <=parseFloat(_previouslevel.upperLimit) )) {
        //let setlowerLimitError = this.configurationform.get('items') as FormArray;
        itemFormarray.controls[index].get('lowerLimit')?.setErrors({ "lowerLimitError": true })
        this.isValidLevel = true
      }
      else if (limitType == 'upperLimit' && (itemFormarray.controls[index].get('lowerLimit')?.value==_previouslevel.lowerLimit && parseFloat(itemFormarray.controls[index].get('upperLimit')?.value) !=parseFloat(_previouslevel.upperLimit) )) {
        //let setlowerLimitError = this.configurationform.get('items') as FormArray;
        itemFormarray.controls[index].get('upperLimit')?.setErrors({ "previousupperLimitError": true })
        this.isValidLevel = true
      }

else if(itemFormarray.controls[index].get('lowerLimit')?.value== _previouslevel.lowerLimit && itemFormarray.controls[index].get('upperLimit')?.value==_previouslevel.upperLimit){
  itemFormarray.controls[index].get('lowerLimit')?.setErrors(null);
  itemFormarray.controls[index].get('upperLimit')?.setErrors(null);
}

     


      if (!this.isValidLevel) {
        this.isValidLevel = false
      }



    }






  }


  cancel() {
    this.ngOnInit();
  }


}
