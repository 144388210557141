import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  //transform(value: any, args?: any): any {

  //   if(!value)return null;
  //   if(!args)return value;

  //   args = args.toLowerCase().trim();

  //   return value.filter(function(item: any){


  //      return JSON.stringify(item).toLowerCase().includes(args);

  //   });
  // }

  // transform(list: any, value: any, key: any): any {
  //   value.forEach((name:any, index: string | number) => {
  //     if (name) {
  //       list = list.filter((item:any) => {
  //        if(item[key[index]]!=undefined)
  //         return (item[key[index]]
  //           .toString()
  //           .toLowerCase()
  //           .indexOf(name.toString().toLowerCase()) !== -1)
  //           else return null;
  //       });
        
        
  //     }
  //   });
  //   return list;
  // }

  transform(items: any[], keyword: any, properties: string[]): any[] {
    if (!items) return [];
    if (!keyword) return items;
    return items.filter(item => {
      var itemFound: Boolean=false;
      for (let i = 0; i < properties.length; i++) {
        if (item[properties[i]].toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
          itemFound = true;
          break;
        }
      }
      return itemFound;
    });

  }

  
}