<div class="col-sm-12 col-md-12 p-0">
    <div class="text-right">
        <span class="close-align pointer" (click)="closeDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
    </div>
    <div class="text-center font-weight-bold header mb-2 h3">
         <span *ngIf="typeId ==1002" class = "text-success">Approve - ({{this.dataObj.id}})</span> 
         <span *ngIf="typeId ==1003" class = "text-danger">Reject - ({{this.dataObj.id}})</span>
         <span *ngIf="typeId ==1004" class = "text-warning">Hold - ({{this.dataObj.id}})</span>
         <span *ngIf="[1005,1008,1011].includes(typeId)" class = "text-info">Send Back - ({{this.dataObj.id}})</span>
    </div>
    <div class="col-sm-12 col-md-12 p-0">
        <div class="card-body p-0">
            <form [formGroup]="ApprovalForm">
                <div class="col-sm-12 col-md-12 mb-3 row" *ngIf="typeId==1004">
                    <label class="col-sm-12 col-md-2">Untill Date</label>
                    <div class="col-sm-12 col-md-6 display-inline">
                        <input type="text" class="form-control"  [matDatepicker]="picker" [min]="f.untillDate.value" placeholder="Choose a date"
                            formControlName="untillDate" data-date-format='YYYY-MM-DD'>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 mb-3 row" *ngIf="[1005,1008,1011].includes(typeId)">
                    <label class="col-sm-12 col-md-2">Send Back To<span class="text-danger">*</span></label>
                    <div class="col-sm-12 col-md-10">
                        <select class="form-select form-control"  formControlName="sendBackTo" >
                            <option *ngFor="let sendBack of sendBackTo" [value]="sendBack.value">{{sendBack.label}}</option>
                          </select>
                    </div>
                </div>


                <div class="col-sm-12 col-md-12 my-3 row">
                    <label class="col-sm-12 col-md-2" for="doc_upload">Documents</label>
                    <div class="col-sm-12 col-md-5" name="doc_upload">
                        <div class="custom-dropzone" ngx-dropzone [accept]="'.pdf, .docx, .doc, .xlsx, .xls, .pptx, .ppt, .jpeg, .jpg, .png, .gif'"
                            (change)="onSelectFile($event)">
                            <ngx-dropzone-label>
                                <div>
                                    <div class="dropZoneContent">
                                        <img height="50px" src="/assets/images/drop-down-icon.svg" alt="">
                                        <div class="dragHint">Drag and drop your files</div>
                                    </div>
                                    <!-- <button type="button" class="btn btn-Action">Upload</button> -->
                                </div>
                            </ngx-dropzone-label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-5 header" *ngIf="transactionDocument.length > 0">
                        <div class="document-header h4 header">Documents Attached</div>
                        <div *ngFor="let doc of transactionDocument" class="col-sm-12 col-md-12">
                            <span>{{doc.FileName}}</span>
                            <i class="fa fa-times btn btn-default ml-3" style="font-size: 20px;" title="Remove Document" (click)="removeDocument(doc)"></i>
                        </div>
                    </div>
                </div>


           

                <div class="col-sm-12 col-md-12 mb-3 row">
                    <label class="col-sm-12 col-md-2">Comments<span class="text-danger">*</span></label>
                    <div class="col-sm-12 col-md-10">
                        <textarea cols="6" rows="5" class="form-control" name="comments" formControlName="comments"
                            [ngClass]="{ 'is-invalid': submitted && f.comments.errors }"
                            placeholder="Enter Comments"></textarea>
                        <div *ngIf="submitted && f.comments.errors" class="invalid-feedback text-danger"
                            [ngClass]="{ 'is-invalid': submitted && f.comments.errors }">
                            <div *ngIf="f.comments.errors.required">Comment is required</div>
                        </div>
                    </div>
                </div>





                <div class="text-center my-3">
                    <!-- <button mat-raised-button color="warn" type="submit" class="button col-md-2 m-2" (click)="back()">Back</button> -->
                    <button type="submit" class="btn btn-Action col-md-2 m-2"
                        (click)="submitApproveAwait()">SUBMIT</button>
                </div>
            </form>
        </div>
    </div>