

<section class="bgImage p-3">
  <div class="">
    <div class="text-center text-light">
      <h2>MY APPROVALS</h2>
    </div>

    <div class="form-row text-light text-right ">
      <div class="form-group col-md-3">
        <input type="text" class="form-control" [(ngModel)]="searchInput" placeholder="Search Text...">
      </div>
    </div>
    <div class="table-responsive-md">
      <table class="table tabDesign table-hover table-striped  mt-2 bg-light "
      id="myapprovals">
      <tr class="col-sm-12 col-md-12">
        <th class="mx-1 tabhead">Transaction ID</th>
        <th class="mx-1 tabhead">Initiator</th>
        <th class="mx-1 tabhead">Initiated Date Time</th>
        <th class="mx-1 tabhead">Workflow Configuration</th>
        <th class="mx-1 tabhead">Nature of Transaction</th>
        <th class="mx-1 tabhead">Business Unit</th>
        <th class="mx-1 tabhead">Category</th>
        <th class="mx-1 tabhead">Estimated Cost</th>
        <th class="mx-1 tabhead">Status</th>
        <!-- <th  class="mx-1 tabhead">History</th> -->
        <th colspan="4" class="mx-1 tabhead">Action</th>

      </tr>
      <tbody class="tabBody">
        <tr
          *ngFor="let app of myApprovalList | search:searchInput:['id', 'ActivityConfigTitle','Title','BusinessUnit','Category'] ;let i=index">
          <td class=" mx-1 tabData pointer"><a (click)="viewDetails(app)" title="Click here to view details"><u
                class="text-primary">{{app.id}}</u></a></td>
          <td class=" mx-1 tabData pointer"><a>{{app.CreatedUser}}</a></td>
          <td class=" mx-1 tabData text-center">{{authservice.formatAMPM(app.CreatedDate) }}</td>
          <td class=" mx-1 tabData">{{app.ActivityConfigTitle}}</td>
          <td class=" mx-1 tabData textBreak">{{app.Title}}</td>
          <td class=" mx-1 tabData ">
            {{app.BusinessUnit}}
          </td>
          <td class=" mx-1 tabData ">
            {{app.Category}}
          </td>
          <td class=" mx-1 tabData text-right">
            <span *ngIf="app.financeAmount!=0">${{app.FinanceAmount | thousandSeparator}}</span>
            <div class="text-center" *ngIf="app.financeAmount==0">&ndash;</div>
          </td>
          <td class=" mx-1 tabData text-center" [ngClass]="{
                'AwaitingDecision': app.StatusID === 1001,
                'accepted': app.StatusID === 1002,
                  'rejected': app.StatusID === 1003,
                  'onHold': app.StatusID === 1004,
                  'sentBack': app.StatusID === 1005,
                  'cancelled': app.StatusID === 1006,
                  'SentBackToInitiator': app.StatusID ===1008
                }">
            {{app.Status}}
          </td>
          <!-- <td class=" mx-1 tabData text-center"><i class="fa fa-info btn btn-Action" (click)="viewDetails(app)"></i></td> -->
          <td class=" mx-1 tabData text-center">
            <div>
              <span *ngIf="app.StatusID!=1002 && app.StatusID!=1003 && app.StatusID!=1006"
              class="icon-25 icon-approve_green mr-1" title="Approve" (click)="approveLevel(app,1002)"></span>
            <span *ngIf="app.StatusID==1002 || app.StatusID==1003 || app.StatusID==1006"
              class="icon-25 icon-approve_green mr-1 pointer-ban" title="Approve not allowed"></span>
            </div>
          </td>
          <td class=" mx-1 tabData text-center">
            <div>
              <span *ngIf="app.StatusID!=1002 && app.StatusID!=1003 && app.StatusID!=1006"
              class="icon-25 icon-reject_red mr-1" title="Reject" (click)="approveLevel(app,1003)"></span>
            <span *ngIf="app.StatusID==1002 || app.StatusID==1003 || app.StatusID==1006"
              class="icon-25 icon-reject_red mr-1 pointer-ban" title="Reject not allowed"></span>
            </div>
          </td>
          <td class=" mx-1 tabData text-center">
            <div>
              <span *ngIf="app.StatusID!=1002 && app.StatusID!=1003 &&  app.StatusID!=1006"
              class="icon-25 icon-hold_orange mr-1" title="On Hold" (click)="approveLevel(app,1004)"></span>
            <span *ngIf="app.StatusID ==1002 || app.StatusID ==1003 ||  app.StatusID==1006 "
              class="icon-25 icon-hold_orange mr-1 pointer-ban" title="On Hold not allowed"></span>
            </div>
          </td>
          <td class=" mx-1 tabData text-center">
            <div class=" mx-1 text-center">

              <span
                *ngIf="!app.isSendBackToInitiator && app.isCurrentLevelSendback"
                class="icon-25 icon-sendback_black mr-1" title="Send back to Previous level"
                (click)="approveLevel(app,1005)"></span>
              <span
                *ngIf="app.isSendBackToInitiator || !app.isCurrentLevelSendback"
                class="icon-25 icon-sendback_black mr-1" title="Send back to Initiator"
                (click)="approveLevel(app,1008)"></span>
            </div>
          </td>
        </tr>
        <tr *ngIf="myApprovalList.length==0">
          <td class=" mx-1 tabData" colspan="10">no record found</td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</section>
