import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  isLoadingOnRoute: Subject<boolean> = this.loaderService.isLoadingOnRoute;


  constructor(private loaderService: LoaderService) { }

  ngOnInit(): void {
  }

}
