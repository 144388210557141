import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { AlertDialogComponent } from '../Shared/alert-dialog/alert-dialog.component';
import { YesNoAlertDialogComponent } from '../Shared/yes-no-alert-dialog/yes-no-alert-dialog.component';
import { TransactionDetailComponent } from '../transaction-detail/transaction-detail.component';
import { DateFilterPipe } from '../pipes/dateFormat.pipe';
import { AuthService } from 'src/app/core/auth.service';
import { SendBackInitiatorEditComponent } from 'src/app/send-back-initiator-edit/send-back-initiator-edit.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  currentPage: number = 1;
  row_count: number = 0;
  page_count: number = 10;
  totalCount: number=0;
  resultPerpage=new FormControl(10);
  TransactionPageFilter!: FormGroup;
  transactions : Array<any> = new Array<any>();
  transactionTemp: Array<any> = new Array<any>();
  // searchInput:string='';
  // buFilter: string = '';
  categories: Array<any> = [];
  // categoryFilter: string = '';
  // StatusIDFilter: string ='';
  Status:Array<any> =[];
  // fromDate: string = '';
  // toDate: string = '';
  changeText!: boolean;
  businessUnits: Array<any> = [];
  configActivityList: Array<any> = new Array<any>();
  
  //@ViewChild('blPagination', { static: true })paginationComp!: PaginationComponent;
  constructor(
    private formBuilder: FormBuilder,
    private restService: RestService,
    public MsalauthService: MsalService,
    private router: Router,
    public dialog: MatDialog, 
    private loader: LoaderService,
    private datePipe: DateFilterPipe,
    public authService: AuthService,) { }

  ngOnInit(): void {
    this.TransactionPageFilter = this.formBuilder.group({
      searchInput: [''],
      fromDate: [''],
      toDate: [''],
      StatusIDFilter: [''],
      buFilter: [''],
      categoryFilter: ['']
    });
   // setInterval(() => {
     //this.getConfigList();
     // this.getTransactions();
    //  }, 5000);
   this.getAllBusinessUnits()
    this.getTransactions();
    this.categories = [
      {category: 'All', categoryID: 0},
      {category: 'CAPEX', categoryID: 1},
      {category: 'OPEX', categoryID: 2},
      {category: 'Non Financial', categoryID: 3},
      {category: 'Other Financial', categoryID:4}
    ]
    
    this.Status =[
      {status: 'All',statusID: 0},
      {status: 'Awaiting Approval',statusID:1001},
      {status: 'Approved',statusID:1002},
      {status: 'Rejected',statusID:1003},
      {status: 'On Hold',statusID:1004},
      {status: 'Sent Back',statusID:1005},
      {status: 'Cancelled',statusID:1006},
      {status: 'Not Applicable',statusID:1007},
      {status: 'Sent Back To Initiator',statusID:1008}
     
   ]
   this.TransactionPageFilter.valueChanges.subscribe(data=>{
    this.row_count= 0;
    this.currentPage=1;
    this.getTransactions();
  });
  this.resultPerpage.valueChanges.subscribe(value=>{
    this.row_count= 0;
    this.page_count=parseFloat(this.resultPerpage.value);
    this.currentPage=1;
    this.getTransactions();
  });
  }
  // OnPageChange(event: any) {
  //   this.getTransactions();
  // }

  //Method to get config list
  getConfigList() {
    let configparams={
      SearchText: '',
      PageNo:0,
      PageSize:1000
    }
    this.restService.postData(configparams ,'ActivityConfig/ActivityConfigFetchList')
    .subscribe(
      (data) => {
        if(data.rcode==1) {
          if(data.rObj.ActivityConfig!=null){
            this.configActivityList=data.rObj.ActivityConfig
          }
         } 
      },
      (err) => {
        console.log(err);
      }
    );
    this.getTransactions();
  }

  //Method to get list of transactions
  getTransactions() {
    // let rowRange = this.paginationComp.GetRowRange();
    let endDate =new Date(this.TransactionPageFilter.get("toDate")?.value);
    if(this.TransactionPageFilter.get("toDate")?.value != '') {
      let ed : Date= new Date(this.TransactionPageFilter.get("toDate")?.value);
     // console.log(ed)
      endDate.setDate(ed.getDate() + 1)
    }
    let newResult:any = [];
    // if(this.searchInput.trim()!=''){
    //   rowRange.firstResult=0;
    //  }
    let params =  { 
      SearchText:this.TransactionPageFilter.get("searchInput")?.value?this.TransactionPageFilter.get("searchInput")?.value.trim():"",
      UserEmail: this.MsalauthService.instance.getAllAccounts()[0].username,
      PageNo:this.row_count,
      PageSize:this.page_count,
      BusinessUnit: this.TransactionPageFilter.get("buFilter")?.value?this.TransactionPageFilter.get("buFilter")?.value:"",
      CategoryID: this.TransactionPageFilter.get("categoryFilter")?.value ? parseFloat(this.TransactionPageFilter.get("categoryFilter")?.value):0,
      StatusID:this.TransactionPageFilter.get("StatusIDFilter")?.value ? parseInt(this.TransactionPageFilter.get("StatusIDFilter")?.value):0,
      StartTime: this.TransactionPageFilter.get("fromDate")?.value ?this.datePipe.transform(this.TransactionPageFilter.get("fromDate")?.value,"yyyy-MM-dd'T'HH:mm:ss'.135'"):"",
      EndTime: this.TransactionPageFilter.get("toDate")?.value == "" && this.TransactionPageFilter.get("fromDate")?.value  == "" ? '' :  this.TransactionPageFilter.get("toDate")?.value == "" && this.TransactionPageFilter.get("fromDate")?.value != ""? this.datePipe.transform(new Date(),"yyyy-MM-dd'T'HH:mm:ss'.135'") : this.datePipe.transform(endDate,"yyyy-MM-dd'T'HH:mm:ss'.135'"),
    };
   
    this.loader.show();
    this.restService.postData(params, '/api/Transaction/TransactionFetchByMe').subscribe((res) => {
      if(res.rcode == 1) {
        this.totalCount=res.rObj.Count.Count;
        this.transactions = res.rObj.Transaction;
        // this.showContainerSearchResultTab(res.rObj.Count.Count);  
      } else {
        console.log(res);
      }
      this.loader.hide();

    }, (err) => {
      this.loader.hide();
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        localStorage.clear();
        this.router.navigate([''])
        .then(() => {
          window.location.reload();
        });
      }
    });
    /* setTimeout(() => {
      this.transactions.forEach(transaction => {
        let id = this.configActivityList.find(item => item.id == transaction.activityConfigID);
        //console.log(id)
        if(id) {
          transaction.workflowTitle = id.Title
          newResult.push(transaction);
        }
      });
      //console.log(newResult)
    }, 2000); */
    //this.transactions = newResult;
    //this.transactionTemp = newResult;
  }

  //Method to call the API for every pagination change

  // showContainerSearchResultTab(data:any){
  //   if(this.searchInput.trim()!=''){
  //     this.paginationComp.SetPage(1)
  //    }
  //     this.paginationComp.SetTotalRecords(data);
  //     this.paginationComp.CalculatePaginationPages();
    
  // }
  //Method to open transaction details pop-up to view transaction details
  viewDetails(transaction: any) : void {
    let data = {
      transaction : transaction
    }
    const dialogRef = this.dialog.open(TransactionDetailComponent, {
      width: '60vw',
      height: '70vh',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe();
  }

  //Method to delete transaction
  deleteTransaction(transaction:any): void {
    let params =  { 
      "TransactionID": transaction.id
    };
    this.loader.show();
    this.restService.postData(params, '/api/Transaction/TransactionDeleteFetch').subscribe((res) => {
      this.loader.hide();
      if(res.rcode == 1) {
        if(res.rObj.IsDeletable) {
          const dialogRef = this.dialog.open(YesNoAlertDialogComponent, {
            width: '40vw',
            height: '30vh',
            disableClose: true,
            data: "Are you sure to delete the transaction - " + transaction.id + " ?"
          });
          dialogRef.afterClosed().subscribe((result) => {
            if(result) {
              this.loader.show();
              this.restService.postData(params, '/api/Transaction/TransactionDelete').subscribe((res) => {
                this.loader.hide();
                if(res.rcode == 1) {
                  this.getTransactions();
                } else {
                  console.log(res);
                }
              }, (err) => {
                this.loader.hide();
                console.log(err);
                if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
                  localStorage.clear();
                  this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
                }
              })
            }
          });
        } else {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '40vw',
            height: '30vh',
            disableClose: true,
           
            data:{data: transaction.id + " - Transaction is non-deletable" ,  type:"WARNING"}
          });
          dialogRef.afterClosed().subscribe();
        }
      }
    }, (err) => {
      this.loader.hide();
      console.log(err);
    })
  }

  getAllBusinessUnits() {
    this.authService.getBusinessUnits()
      .subscribe((data: any) => {
        let _temp=[];
        for(var j=0; j<data.value.length;j++){
          if(data.value[j].displayName!='BU-L1-JCG'){
            _temp.push(data.value[j]);
          }
        }
        this.businessUnits=_temp
      },
        (err) => {
          this.loader.hide();
          console.log(err);
          if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
            localStorage.clear();
            this.router.navigate([''])
            .then(() => {
              window.location.reload();
            });
          }
        })
  }

  // onToDate(event: any) {
  //   this.toDate = event;
  //   this.getTransactions();
  // }

  // onFromDate(event: any) {
  //   this.fromDate = event;
  //   this.getTransactions();
  // }

  clearFilter(){
    this.TransactionPageFilter.patchValue({
      searchInput: "",
      fromDate: "",
      toDate: "",
      StatusIDFilter:"",
      buFilter:"",
      categoryFilter:""
    });
    this.page_count=10;
    this.resultPerpage.patchValue(10);
  }
 
  edit(transaction:any){
    const dialogRef = this.dialog.open(SendBackInitiatorEditComponent, {
      width: '80vw',
      height: '90vh',
      disableClose: true,
      data:transaction
        });
        dialogRef.afterClosed().subscribe(result => {
          this.getTransactions()
        });
      }

      openDocument(transaction:any): void {
        if(transaction.DocumentURL != null) {
          window.open(transaction.DocumentURL,'_blank');
        }
      }
    //Method to cancel transaction
  cancelTransaction(transaction:any): void {
    let params =  { 
      "TransactionID": transaction.id
    };
    const dialogRef = this.dialog.open(YesNoAlertDialogComponent, {
      width: '40vw',
      height: '30vh',
      disableClose: true,
      data: "Are you sure to cancel the transaction - " + transaction.id + " ?"
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        this.loader.show();
        this.restService.postData(params, '/api/Transaction/TransactionCancel').subscribe((res) => {
          this.loader.hide();
          if(res.rcode == 1) {
            this.getTransactions();
          } else {
            console.log(res);
          }
        }, (err) => {
          this.loader.hide();
          console.log(err);
          if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
            localStorage.clear();
            this.router.navigate([''])
            .then(() => {
              window.location.reload();
            });
          }
        })
      }
    });
  }
  onPageChange(page: number) {
    this.row_count = this.page_count * (page - 1);
    this.getTransactions();
    window.scrollTo(0, 0);
  }
}
