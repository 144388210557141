import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {LoaderService} from '../service/loader.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private loader: LoaderService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.loader.show();
    return this.authService.isAuthenticated().pipe(map(auth => {
      this.loader.hide();
      if (!auth) {
        this.authService.authenticated = false;
        this.router.navigate(['']);
        return false;
      }
      this.authService.authenticated = true;
      return true;
    })
    );
  }


  
}
